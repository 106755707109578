import React from 'react';

export function IconRightArrow({ size, color }) {
  return (
    <svg
      style={{
        width: `${size}px`,
        height: `${size}px`,
        position: 'relative',
      }}
    >
      <title>IconRightArrow</title>
      <path
        id='primary'
        d='M17.41,12.71,9.12,21,6.29,18.17,12.46,12,6.29,5.83,9.11,3l8.3,8.29A1,1,0,0,1,17.41,12.71Z'
        style={{
          fill: 'none',
          stroke: color,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: 1.5,
        }}
      />
    </svg>
  );
}
