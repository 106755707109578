import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import placeholder from '../../../../assets/placeholder.webp';
import classes from './Card.module.scss';

export function Card({
  currentOuterCategory,
  category,
  subCategory,
  hideSmallDropDownContainer,
  makeMainContentScrollable,
}) {
  const { t } = useTranslation();

  const subCategoryPath = subCategory ? subCategory.title.match(/\w/g).join('') : 'allgoods';
  const link = currentOuterCategory
    ? `/outercategory/${currentOuterCategory}/${category}/${subCategoryPath}`
    : `/catalog/${category}/${subCategoryPath}`;

  return (
    <Link
      to={link}
      itemProp='url'
      className={classes.link_wrapper}
      key={subCategory.title}
      onClick={() => {
        hideSmallDropDownContainer((prev) => !prev);
        makeMainContentScrollable();
      }}
    >
      <div itemscope itemtype='https://schema.org/Product' className={classes.subcategorycard_container}>
        <div className={classes.image}>
          <img
            className={classes.subCategoryImage}
            src={subCategory.previewImage || placeholder}
            alt='subCategoryImage'
            itemProp='img'
          />
        </div>
        <div className={classes.title}>
          <span itemProp='name' className={classes.title_span}>
            {subCategory._id === 'allgoods'
              ? t('static:allGoodsOfCatalog')
              : t(`subcategories:${subCategory._id}:title`)}
          </span>
        </div>
      </div>
    </Link>
  );
}
