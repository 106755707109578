import React, { useEffect, useState, useRef } from 'react';
import debounce from 'lodash/debounce';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector, useDispatch } from 'react-redux';

import { changeCategoryList } from '../../../../services/Categories/changeCategoryList';
import { AdminPanelButtons } from '../../AdminPanelButtons/AdminPanelButtons';
import { getCategoriesListAC } from '../../../../store/Goods/GoodsAC';
import { getReorderedList } from '../../../../helpers/getReorderedList';
import { SubCategoriesDropDown } from '../../SubCategoriesDropDown/SubCategoriesDropDown';
import { hasSubCategories } from '../../../../helpers/hasSubcategories';

import classes from './NoOuterCategoryContainer.module.scss';

export function NoOuterCategoriesContainer({
  setIsHoverCatalog,
  setCatalogButtonStyle,
  hideDropDownMenu,
  showAddSubCategoryForm,
  showAskFullDeletionForm,
  showChangeCategorySectionForm,
  setCategoryTarget,
  setCurrentOuterCategory,
}) {
  const { categoriesList } = useSelector((state) => state.GoodsReducer);
  const { catalogs } = useSelector((state) => state.CatalogReducer);
  const { Role, acessToken } = useSelector((state) => state.UserReducer);

  const [subCategoriesDropDown, showSubCategoriesDropDown] = useState(false);
  const [categoryForSubCategory, setCategoryForSubCategory] = useState('');
  const [noOuterCategoriesList, setNoOuterCategoriesList] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const noOuterCategoryObj = categoriesList.find(
      (outerCategoryObj) => outerCategoryObj.outerCategory === 'noOuterCategory',
    );
    noOuterCategoryObj && setNoOuterCategoriesList(noOuterCategoryObj.categories);
  }, [categoriesList]);

  async function handleOnDragEnd(e, changeOrder) {
    const destination = e.destination.index;
    const source = e.source.index;

    // dispatch(setCategoriesListAC(newOrderArr));

    const newOrderArr = getReorderedList(destination, source, noOuterCategoriesList);

    await changeOrder({
      outerCategory: 'noOuterCategory',
      newCategoryList: newOrderArr,
      token: acessToken,
    });
    dispatch(getCategoriesListAC());
  }

  const timeOutId = useRef();
  const debounceShowDropDown = debounce(() => {
    showSubCategoriesDropDown(true);
  }, 500);

  const debounceHideDropDown = debounce(() => {
    showSubCategoriesDropDown(false);
  }, 500);

  function debounceDropDownShowCustom(category) {
    timeOutId.current = setTimeout(() => {
      setCategoryForSubCategory(category);
      showSubCategoriesDropDown(true);
    }, 300);
  }
  return (
    <>
      {subCategoriesDropDown && ( // subCategoriesDropDown
        <SubCategoriesDropDown
          debounceShowDropDown={debounceShowDropDown}
          categoryForSubCategory={categoryForSubCategory}
          debounceHideDropDown={debounceHideDropDown}
          hideDropDownMenu={hideDropDownMenu}
        />
      )}
      {Role === 'ADMIN' ? (
        <DragDropContext
          onDragEnd={(e) => {
            handleOnDragEnd(e, changeCategoryList);
          }}
        >
          <Droppable droppableId='NoOuterCategoryDroppable' style={{ padding: '0' }}>
            {(provided) => (
              <div
                className={classes.linkSectionWrapper}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <div className={classes.noOuterCategory}>
                  {noOuterCategoriesList.map((category, index) => (
                    <Draggable key={category} draggableId={category} index={index}>
                      {(provided) => (
                        <div
                          className={classes.link_wrapper_admin_panel}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          onMouseEnter={(e) => {
                            if (hasSubCategories(category, catalogs)) {
                              debounceDropDownShowCustom(category);
                              debounceHideDropDown.cancel();
                            }
                          }}
                          onMouseLeave={(e) => {
                            clearTimeout(timeOutId.current);
                            debounceHideDropDown();
                          }}
                        >
                          {hasSubCategories(category, catalogs) ? (
                            <div className={classes.link_wrapper}>
                              <Link
                                key={category}
                                to={`catalog/${category}`}
                                className={classes.link_text}
                                onClick={() => {
                                  setIsHoverCatalog(false);
                                  setCatalogButtonStyle('catalog_button');
                                }}
                                onMouseEnter={() => {
                                  debounceDropDownShowCustom(category);
                                  debounceHideDropDown.cancel();
                                }}
                                onMouseLeave={() => {
                                  clearTimeout(timeOutId.current);
                                  debounceHideDropDown();
                                }}
                              >
                                {t(`category:${category}`)}
                              </Link>
                              <AdminPanelButtons
                                showChangeCategorySectionForm={showChangeCategorySectionForm}
                                setCategoryTarget={setCategoryTarget}
                                showAddSubCategoryForm={showAddSubCategoryForm}
                                showAskFullDeletionForm={showAskFullDeletionForm}
                                category={category}
                                setCurrentOuterCategory={setCurrentOuterCategory}
                                currentOuterCategory='noOuterCategory'
                                hasSubCategories
                              />
                            </div>
                          ) : (
                            <div className={classes.link_wrapper}>
                              <Link
                                key={category}
                                to={`goods/${category}`}
                                className={classes.link_text}
                                onClick={() => {
                                  setIsHoverCatalog(false);
                                  setCatalogButtonStyle('catalog_button');
                                }}
                              >
                                {t(`category:${category}`)}
                              </Link>

                              <AdminPanelButtons
                                showChangeCategorySectionForm={showChangeCategorySectionForm}
                                setCategoryTarget={setCategoryTarget}
                                showAddSubCategoryForm={showAddSubCategoryForm}
                                showAskFullDeletionForm={showAskFullDeletionForm}
                                category={category}
                                setCurrentOuterCategory={setCurrentOuterCategory}
                                currentOuterCategory='noOuterCategory'
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <div itemscope itemtype='https://schema.org/Product' className={classes.noOuterCategory}>
          {noOuterCategoriesList.map((category) => (
            <div
              className={classes.link_wrapper_container}
              onMouseEnter={(e) => {
                if (hasSubCategories(category, catalogs)) {
                  debounceDropDownShowCustom(category);
                  debounceHideDropDown.cancel();
                }
              }}
              onMouseLeave={(e) => {
                clearTimeout(timeOutId.current);
                debounceHideDropDown();
              }}
              key={category}
            >
              {hasSubCategories(category, catalogs) ? (
                <Link
                  key={category}
                  to={`catalog/${category}`}
                  className={classes.link_text}
                  itemProp='url'
                  onClick={() => {
                    setIsHoverCatalog(false);
                    setCatalogButtonStyle('catalog_button');
                  }}
                  onMouseEnter={() => {
                    debounceDropDownShowCustom(category);
                    debounceHideDropDown.cancel();
                  }}
                  onMouseLeave={() => {
                    clearTimeout(timeOutId.current);
                    debounceHideDropDown();
                  }}
                >
                  <span itemProp='name'>{t(`category:${category}`)}</span>
                </Link>
              ) : (
                <Link
                  key={category}
                  to={`goods/${category}`}
                  className={classes.link_text}
                  itemProp='url'
                  onClick={() => {
                    setIsHoverCatalog(false);
                    setCatalogButtonStyle('catalog_button');
                  }}
                >
                  <span itemProp='name'>{t(`category:${category}`)}</span>
                </Link>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
}
