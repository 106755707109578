import React from 'react';

export function IconLeftArrow({ size, color }) {
  return (
    <svg
      style={{
        width: `${size}px`,
        height: `${size}px`,
        position: 'relative',
      }}
    >
      <title>IconLeftArrow</title>
      <path
        d='M6.59,11.29,14.88,3l2.83,2.83L11.54,12l6.17,6.17L14.89,21l-8.3-8.29A1,1,0,0,1,6.59,11.29Z'
        style={{
          fill: 'none',
          stroke: color,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeWidth: 1.5,
        }}
      />
    </svg>
  );
}
