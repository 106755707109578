import { SET_IS_ERROR, SET_ERROR_OBJECT } from './ErrorAT';

const initialState = {
  isError: false,
  errorObj: {
    errorCode: '',
    errorMessage: '',
    errorDescription: '',
  },
};

export const ErrorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_ERROR:
      return {
        ...state,
        isError: action.payload,
      };

    case SET_ERROR_OBJECT:
      return {
        ...state,
        errorObj: action.payload,
      };

    default:
      return state;
  }
};
