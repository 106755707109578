import axios from 'axios';

export async function logoutRequest() {
  return axios
    .post('/auth/logout', {}, { withCredentials: true })
    .then(() => {
      localStorage.removeItem('isLogged');
      window.location.reload();
    })
    .catch((error) => console.log('Catched error in logoutRequest', error));
}
