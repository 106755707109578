import { axiosPrivate } from '../../api/axiosPrivate';

export async function synhronizeGoodsByCategory(token) {
  return axiosPrivate.get(
    '/api/synhronizegoodsbycategory',

    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}
