export const CHANGE_ISVERIFIED_STATE = 'CHANGE_ISVERIFIED_STATE';
export const CHANGE_ROLE_STATE = 'CHANGE_ROLE_STATE';
export const LOGIN = 'LOGIN';
export const SET_ISLOGGED = 'SET_ISLOGGED';
export const SET_ID = 'SET_ID';
export const SET_NAME = 'SET_NAME';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_PHONE = 'SET_PHONE';
export const SET_USER_SHOPPING_CARDS = 'SET_USER_SHOPPING_CARDS';
export const SET_USER_SHOPPING_CARD = 'SET_USER_SHOPPING_CARD';
export const ADD_ACCESS_TOKEN = 'ADD_ACCESS_TOKEN';
export const GET_USER_DETAILS_REQUEST = 'GET_USER_DETAILS_REQUEST';
