import { addGoodToOrderedShoppingCard } from '../services/ShoppingCard/addGoodToOrderedShoppingCard';
import { getAllActiveShoppingCardsAC } from '../store/ShoppingCards/ShoppingCardsAC';
import { GetUserDetailsRequest } from '../store/User/UserAC';
import { addToShoppingCard } from './LocalStorage/addToShoppingCard';

export function handleBuyButton(
  e,
  isButtonActive,
  idToAddGoods,
  goodId,
  category,
  acessToken,
  Role,
  setStateToRerenderBuyButton,
  setShoppingCardPreview,
  dispatchFunction,
) {
  if (isButtonActive) {
    if (idToAddGoods) {
      addGoodToOrderedShoppingCard({
        goodId,
        shoppingCardId: idToAddGoods,
        category,
        token: acessToken,
      }).then(() => {
        if (Role === 'ADMIN') {
          dispatchFunction(getAllActiveShoppingCardsAC(acessToken));
        } else {
          acessToken && dispatchFunction(GetUserDetailsRequest(acessToken));
        }

        setStateToRerenderBuyButton((prev) => !prev);
      });
    } else {
      addToShoppingCard(goodId);
      setStateToRerenderBuyButton((prev) => !prev);
    }
  } else {
    if (idToAddGoods) return;
    setShoppingCardPreview(true);
  }
}
