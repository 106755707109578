// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Q6zys2W7zgAgD7Xevra1{text-decoration:none;color:#000}.Q6zys2W7zgAgD7Xevra1 .S7LVTUYtU0GxPEKI2qSc{display:flex;flex-direction:column;font-size:12px;width:100px}.Q6zys2W7zgAgD7Xevra1 .S7LVTUYtU0GxPEKI2qSc .Lb07fZ7hOFiOTPtblhyf{text-align:center}.Q6zys2W7zgAgD7Xevra1 .S7LVTUYtU0GxPEKI2qSc .Lb07fZ7hOFiOTPtblhyf .aanzf9gDuQ_7UzVNW5LR{width:85px;height:85px;object-fit:scale-down}.Q6zys2W7zgAgD7Xevra1 .S7LVTUYtU0GxPEKI2qSc .j0PSHDvslaKyQ9CVyV5J{width:100%;font-size:12px;font-weight:bold;padding-top:10px;text-align:center}.Q6zys2W7zgAgD7Xevra1 .S7LVTUYtU0GxPEKI2qSc .j0PSHDvslaKyQ9CVyV5J span{width:100%}`, "",{"version":3,"sources":["webpack://./src/components/NavBarPanel/SmallSubCategoriesDropDown/Card/Card.module.scss"],"names":[],"mappings":"AAAA,sBACE,oBAAA,CACA,UAAA,CAEA,4CACE,YAAA,CACA,qBAAA,CACA,cAAA,CACA,WAAA,CAEA,kEACE,iBAAA,CAEA,wFACE,UAAA,CACA,WAAA,CACA,qBAAA,CAIJ,kEACE,UAAA,CACA,cAAA,CACA,gBAAA,CACA,gBAAA,CACA,iBAAA,CAEA,uEACE,UAAA","sourcesContent":[".link_wrapper {\n  text-decoration: none;\n  color: black;\n\n  .subcategorycard_container {\n    display: flex;\n    flex-direction: column;\n    font-size: 12px;\n    width: 100px;\n\n    .image {\n      text-align: center;\n\n      .subCategoryImage {\n        width: 85px;\n        height: 85px;\n        object-fit: scale-down;\n      }\n    }\n\n    .title {\n      width: 100%;\n      font-size: 12px;\n      font-weight: bold;\n      padding-top: 10px;\n      text-align: center;\n\n      span {\n        width: 100%;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link_wrapper": `Q6zys2W7zgAgD7Xevra1`,
	"subcategorycard_container": `S7LVTUYtU0GxPEKI2qSc`,
	"image": `Lb07fZ7hOFiOTPtblhyf`,
	"subCategoryImage": `aanzf9gDuQ_7UzVNW5LR`,
	"title": `j0PSHDvslaKyQ9CVyV5J`
};
export default ___CSS_LOADER_EXPORT___;
