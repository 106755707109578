import { axiosPrivate } from '../../api/axiosPrivate';

export async function blurMainImages(token) {
  try {
    return axiosPrivate.post('/api/blurmainimages', {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (error) {
    console.log(error, 'error in addMainImage function');
  }
}
