// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QWZkkJctSMcjdAItvprk{display:flex;border-radius:4px;overflow:hidden;flex-direction:column;position:absolute;background-color:#c5bcbc;width:110px;top:125px;z-index:1000}.QWZkkJctSMcjdAItvprk a{vertical-align:middle;text-align:center;text-decoration:none;color:#000;background-color:#c5bcbc}.QWZkkJctSMcjdAItvprk a:hover{background-color:#fff}`, "",{"version":3,"sources":["webpack://./src/components/DropDownLogout/DropDownLogout.module.scss"],"names":[],"mappings":"AAwBA,sBAvBE,YAAA,CACA,iBAAA,CACA,eAAA,CACA,qBAAA,CACA,iBAAA,CACA,wBAAA,CACA,WAAA,CACA,SAAA,CACA,YAAA,CAEA,wBACE,qBAAA,CACA,iBAAA,CACA,oBAAA,CACA,UAAA,CACA,wBAAA,CAGF,8BACE,qBAAA","sourcesContent":["@mixin DropDownLogout {\n  display: flex;\n  border-radius: 4px;\n  overflow: hidden;\n  flex-direction: column;\n  position: absolute;\n  background-color: rgb(197, 188, 188);\n  width: 110px;\n  top: 125px;\n  z-index: 1000;\n\n  a {\n    vertical-align: middle;\n    text-align: center;\n    text-decoration: none;\n    color: rgb(0, 0, 0);\n    background-color: rgb(197, 188, 188);\n  }\n\n  a:hover {\n    background-color: rgb(255, 255, 255);\n  }\n}\n\n.DropDownLogout {\n  @include DropDownLogout;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DropDownLogout": `QWZkkJctSMcjdAItvprk`
};
export default ___CSS_LOADER_EXPORT___;
