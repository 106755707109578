import React from 'react';
import { v4 as uuidv4 } from 'uuid';

export function IconViber({ size }) {
  const uniqueId = uuidv4();

  return (
    <svg id={uniqueId} viewBox='0 0 512 512' style={{ width: size, height: size }}>
      <title>Viber</title>

      <path
        style={{ fill: '#665CAC' }}
        d='M307.313,8.018H204.686c-94.465,0-171.044,76.579-171.044,171.044v76.97
c0,70.137,42.22,130.402,102.627,156.799v91.216l76.97-76.97h94.074c94.465,0,171.044-76.58,171.044-171.044v-76.97
C478.357,84.596,401.779,8.018,307.313,8.018z'
      />
      <path
        style={{ fill: '#523494' }}
        d='M170.477,469.838v-57.007c-60.406-26.395-102.627-86.662-102.627-156.799v-76.97
c0-94.466,76.58-171.044,171.044-171.044h-34.209c-94.465,0-171.044,76.579-171.044,171.044v76.97
c0,70.137,42.22,130.402,102.627,156.799v91.216L170.477,469.838z'
      />
      <path
        style={{ fill: '#FFFFFF' }}
        d='M369.018,278.13l-60.474-12.095l-28.689,28.69c-48.535-20.568-74.192-46.225-96.758-96.758
l28.691-28.691l-12.095-60.474l-41.653-7.69c-9.112,2.071-17.773,6.641-24.868,13.737c-13.802,13.802-15.719,32.972-12.885,50.989
c6.411,40.755,26.253,95.276,61.016,130.175l0,0c0.084,0.084,0.165,0.171,0.249,0.255c0.084,0.084,0.171,0.165,0.255,0.249l0,0
c34.899,34.763,89.421,54.604,130.175,61.016c18.017,2.834,37.187,0.917,50.989-12.885c7.096-7.096,11.666-15.755,13.737-24.868
L369.018,278.13z'
      />
      <path
        style={{ fill: '#E5E5E5' }}
        d='M328.109,341.408c-40.755-6.412-95.276-26.253-130.175-61.016l0,0
c-0.084-0.084-0.171-0.165-0.256-0.249c-0.084-0.084-0.165-0.171-0.249-0.256l0,0c-34.763-34.899-54.604-89.421-61.016-130.175
c-2.272-14.441-1.484-29.62,6.022-42.184c-3.29,2.04-6.409,4.465-9.264,7.322c-13.802,13.802-15.719,32.972-12.885,50.989
c6.411,40.755,26.253,95.276,61.016,130.175l0,0c0.084,0.084,0.165,0.171,0.249,0.254c0.084,0.084,0.171,0.165,0.255,0.249l0,0
c34.899,34.763,89.421,54.604,130.175,61.016c18.017,2.834,37.187,0.917,50.989-12.885c2.856-2.855,5.283-5.974,7.322-9.264
C357.728,342.892,342.55,343.68,328.109,341.408z'
      />
      <path
        d='M435.192,53.723c-3.099-3.162-8.177-3.215-11.337-0.112c-3.162,3.099-3.214,8.175-0.112,11.337
c30.048,30.654,46.597,71.181,46.597,114.114v76.97c0,89.893-73.133,163.027-163.027,163.027h-94.074
c-2.126,0-4.165,0.845-5.67,2.348l-63.282,63.283v-71.86c0-3.186-1.888-6.071-4.807-7.346
C80.056,379.518,41.66,320.855,41.66,256.032v-76.97c0-89.893,73.133-163.027,163.027-163.027h102.627
c32.038,0,63.044,9.286,89.665,26.85c3.696,2.44,8.669,1.419,11.108-2.276c2.438-3.697,1.42-8.669-2.276-11.108
C376.558,10.202,342.5,0,307.313,0H204.686C105.952,0,25.624,80.328,25.624,179.062v76.97c0,69.431,40.108,132.43,102.627,161.96
v86.055c0,6.977,8.735,10.619,13.688,5.67l74.621-74.622h90.754c98.734,0,179.062-80.328,179.062-179.062v-76.97
C486.375,131.906,468.198,87.392,435.192,53.723z'
      />
      <path
        d='M313.228,349.615c-38.326-6.03-91.49-24.637-125.752-58.764l-0.494-0.493c-15.761-15.824-29.81-37.65-40.625-63.119
c-1.732-4.076-6.438-5.975-10.514-4.246c-4.076,1.731-5.977,6.438-4.246,10.514c11.609,27.334,26.833,50.906,44.055,68.2
l0.496,0.493c36.992,36.846,93.778,56.835,134.587,63.256c5.122,0.805,10.054,1.206,14.783,1.206
c17.591,0,32.319-5.541,43.122-16.342c7.916-7.917,13.41-17.863,15.885-28.761c0.242-1.062,0.264-2.16,0.066-3.231l-7.69-41.653
c-0.595-3.226-3.096-5.764-6.312-6.407l-60.473-12.094c-2.628-0.524-5.345,0.297-7.242,2.193l-24.829,24.83
c-41.849-18.68-64.974-41.852-85.344-85.494l24.754-24.753c1.894-1.895,2.717-4.613,2.193-7.242l-12.094-60.474
c-0.644-3.217-3.181-5.717-6.407-6.312l-41.653-7.688c-1.07-0.2-2.17-0.175-3.231,0.066c-10.9,2.476-20.846,7.969-28.762,15.886
c-13.704,13.705-18.939,33.729-15.135,57.904c1.732,11.006,4.306,22.409,7.651,33.892c1.24,4.253,5.697,6.689,9.941,5.456
c4.252-1.239,6.694-5.689,5.456-9.941c-3.156-10.832-5.58-21.565-7.206-31.901c-3.016-19.172,0.561-34,10.634-44.073
c5.401-5.402,12.094-9.256,19.438-11.209l34.616,6.391l10.188,50.94l-25.656,25.655c-2.349,2.35-3.006,5.904-1.652,8.938
c23.323,52.231,50.684,79.571,100.95,100.87c3.011,1.274,6.489,0.597,8.798-1.713l25.654-25.656l50.939,10.188l6.392,34.616
c-1.953,7.343-5.808,14.036-11.208,19.438C347.229,349.055,332.398,352.633,313.228,349.615z'
      />
      <path
        d='M273.104,145.388c-4.429,0-8.018,3.589-8.018,8.018s3.589,8.018,8.018,8.018c19.158,0,34.743,15.585,34.743,34.743
c0,4.369,3.649,8.018,8.018,8.018c4.369,0,8.018-3.649,8.018-8.018C323.883,168.168,301.103,145.388,273.104,145.388z'
      />
      <path
        d='M348.724,212.689c4.343,0.469,8.369-2.739,8.849-7.09c0.343-3.114,0.517-6.289,0.517-9.433
c0-46.863-38.125-84.988-84.988-84.988c-3.144,0-6.318,0.174-9.432,0.517c-4.401,0.486-7.575,4.448-7.09,8.849
c0.486,4.4,4.445,7.563,8.849,7.09c2.533-0.28,5.113-0.422,7.672-0.422c38.02,0,68.952,30.932,68.952,68.952
c0,2.56-0.142,5.141-0.422,7.673C341.149,208.241,344.323,212.203,348.724,212.689z'
      />
      <path
        d='M389.111,223.667c2.117-8.96,3.19-18.211,3.19-27.501c0-65.725-53.472-119.197-119.197-119.197
c-3.044,0-6.124,0.117-9.158,0.346c-4.415,0.335-7.724,4.185-7.388,8.601c0.336,4.416,4.167,7.717,8.601,7.388
c2.631-0.2,5.305-0.3,7.946-0.3c56.883,0,103.161,46.278,103.161,103.161c0,8.049-0.929,16.06-2.76,23.813
c-0.993,4.2,1.636,8.532,5.81,9.608C383.605,230.694,388.093,227.973,389.111,223.667z'
      />
    </svg>
  );
}
