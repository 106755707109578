import axios from 'axios';

export async function getShoppingCardById({ shoppingCardId }) {
  return axios
    .post('/api/getshoppingcardbyid', { shoppingCardId })
    .then((result) => {
      if (!result.data) {
        return;
      }
      return result.data;
    })
    .catch((error) => console.log(error, 'Error in getShoppingCardById'));
}
