import React from 'react';

import { IconTelegram } from '../Icons/IconTelegram';
import { IconInstagram } from '../Icons/IconInstagram';
import { IconViber } from '../Icons/IconViber';
import { IconFacebook } from '../Icons/IconFacebook';

import classes from './SoshialMediaIconsContainer.module.scss';

export function SoshialMediaIconsContainerPureSVG() {
  return (
    <div className={classes.sochial_media_links_container}>
      <div className={classes.media_link_container}>
        <a
          target='_blank'
          href='https://t.me/LarisaSalmanova'
          className={classes.sochial_media_link}
          rel='noreferrer'
        >
          <IconTelegram size='30px' />
        </a>
      </div>
      <div className={classes.media_link_container}>
        <a
          target='_blank'
          href='viber://chat?number=%2B380957045546'
          className={classes.sochial_media_link}
          rel='noreferrer'
        >
          <IconViber size='30px' />
        </a>
      </div>
      <div className={classes.media_link_container}>
        <a
          target='_blank'
          href='https://www.instagram.com/larisa__lara13/'
          className={classes.sochial_media_link}
          rel='noreferrer'
        >
          <IconInstagram size='30px' />
        </a>
      </div>
      <div className={classes.media_link_container}>
        <a
          target='_blank'
          href='https://m.me/larisa.zlyden'
          className={classes.sochial_media_link}
          rel='noreferrer'
        >
          <IconFacebook size='30px' />
        </a>
      </div>
    </div>
  );
}
