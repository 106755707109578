import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CatalogCardPlaceholder } from '../CatalogCardsContainer/CatalogCardPlaceholder/CatalogCardPlaceholder';
import { CatalogCard } from '../CatalogCardsContainer/CatalogCard/CatalogCard';

import classes from './OuterCatalogCardsContainer.module.scss';

export function OuterCatalogCardsContainer() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, []);

  const { categoriesList } = useSelector((state) => state.GoodsReducer);
  const { catalogs } = useSelector((state) => state.CatalogReducer);
  const { t } = useTranslation();

  const [outerCatalogs, setOuterCatalos] = useState([]);
  const [noOuterCatalogs, setNoOuterCatalogs] = useState({});
  const [noOuterCategoryCaltalogs, setNoOuterCategoryCatalogs] = useState([]);
  const [isOuterCatalogsLoaded, setIsOuterCatalogsLoaded] = useState(false);
  const [isNoOuterCategoryCatalogsLoaded, setIsNoOuterCatalogsLoaded] = useState(false);

  useEffect(() => {
    const filteredOuterCatalogs = categoriesList.filter(
      (outerCatalogObj) => outerCatalogObj.outerCategory !== 'noOuterCategory',
    );

    const filteredNoOuterCatalogs = categoriesList.find(
      (outerCatalogObj) => outerCatalogObj.outerCategory === 'noOuterCategory',
    );
    filteredOuterCatalogs && setOuterCatalos(filteredOuterCatalogs);
    filteredNoOuterCatalogs && setNoOuterCatalogs(filteredNoOuterCatalogs);
  }, [categoriesList]);

  useEffect(() => {
    const noOuterCategoryCatalos = [];

    noOuterCatalogs &&
      noOuterCatalogs?.categories &&
      noOuterCatalogs.categories.forEach((category) => {
        const localCatalog = catalogs.find((catalog) => catalog.category === category);
        if (localCatalog) noOuterCategoryCatalos.push(localCatalog);
      });

    noOuterCategoryCatalos.length && setNoOuterCategoryCatalogs(noOuterCategoryCatalos);
  }, [noOuterCatalogs, catalogs]);

  useEffect(() => {
    outerCatalogs && outerCatalogs.length && setIsOuterCatalogsLoaded(true);
  }, [outerCatalogs]);

  useEffect(() => {
    noOuterCategoryCaltalogs && noOuterCategoryCaltalogs.length && setIsNoOuterCatalogsLoaded(true);
  }, [noOuterCategoryCaltalogs]);

  return (
    <>
      <div className={classes.catalogTitle}>{t('static:catalog')}</div>
      <div className={classes.OuterCatalogCardsContainer}>
        {isOuterCatalogsLoaded
          ? outerCatalogs.map((outerCatalog) => <CatalogCard catalog={outerCatalog} />)
          : [1, 2, 3, 4].map((_, idx) => <CatalogCardPlaceholder idx={idx} />)}
        {isNoOuterCategoryCatalogsLoaded
          ? noOuterCategoryCaltalogs.map((noOuterCatalog) => <CatalogCard catalog={noOuterCatalog} />)
          : [1, 2, 3, 4].map((_, idx) => <CatalogCardPlaceholder idx={idx} />)}
      </div>
    </>
  );
}
