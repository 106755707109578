import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classes from './ErrorPage.module.scss';

export default function ErrorPage(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={classes.errorPageContainer}>
      <div className={classes.ooops}>
        <span>{t('static:oops')}</span>
      </div>
      {props?.error?.message && (
        <div className={classes.errorMessage}>
          <span>
            {t('static:error')}:{t(`static:${props.error.message}`)}
          </span>
        </div>
      )}
      {props.resetErrorBoundary && (
        <button
          type='button'
          className={classes.linkToMainPage}
          onClick={() => {
            navigate('/');
            props.resetErrorBoundary();
          }}
        >
          {t('static:goToMainPage')}
        </button>
      )}
    </div>
  );
}
