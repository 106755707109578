import axios from 'axios';

export function addGoodToOrderedShoppingCard({ goodId, shoppingCardId, token }) {
  return axios.post(
    '/api/addgoodtoshoppingcard',
    {
      goodId,
      shoppingCardId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}
