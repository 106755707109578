import { axiosPrivate } from '../../api/axiosPrivate';

export async function addMainImage(image, token) {
  try {
    return axiosPrivate.post(
      '/api/addmainimage',
      { image },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
  } catch (error) {
    console.log(error, 'error in addMainImage function');
  }
}
