import React, { lazy, Suspense, useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ProtectedRoute } from './ProtectedRoute/ProtectedRoute';
import { NavbarPanel } from '../NavBarPanel/NavbarPanel';
import { MainPage } from '../MainPage/MainPage';

const GoodsPage = lazy(() =>
  import('../GoodsPage/GoodsPage').then((module) => ({
    default: module.GoodsPage,
  })),
);
const CatalogPage = lazy(() =>
  import('../CatalogPage/CatalogPage').then((module) => ({
    default: module.CatalogPage,
  })),
);
const GoodPageFull = lazy(() =>
  import('../GoodsPage/GoodsPageFull/GoodPageFull').then((module) => ({
    default: module.GoodPageFull,
  })),
);
const Login = lazy(() =>
  import('../Auth/Login/Login').then((module) => ({
    default: module.Login,
  })),
);
const SignUp = lazy(() =>
  import('../Auth/SignUp/SignUp').then((module) => ({
    default: module.SignUp,
  })),
);
const UserPage = lazy(() =>
  import('../UserPage/UserPage').then((module) => ({
    default: module.UserPage,
  })),
);
const MakeOrderPage = lazy(() =>
  import('../MakeOrderPage/MakeOrderPage').then((module) => ({
    default: module.MakeOrderPage,
  })),
);
const RegistrationConfirm = lazy(() =>
  import('../Auth/RegistrationConfirm/RegistrationConfirm').then((module) => ({
    default: module.RegistrationConfirm,
  })),
);
const NotFoundPage = lazy(() =>
  import('../NotFoundPage/NotFoundPage').then((module) => ({
    default: module.NotFoundPage,
  })),
);
const CheckEmailMessage = lazy(() =>
  import('../Auth/CheckEmailMessage/CheckEmailMessage').then((module) => ({
    default: module.CheckEmailMessage,
  })),
);
const OrdersManager = lazy(() =>
  import('../OrdersManager/OrdersManager').then((module) => ({
    default: module.OrdersManager,
  })),
);
const ChangePasswordPage = lazy(() =>
  import('../Auth/ChangePasswordPage/ChangePasswordPage').then((module) => ({
    default: module.ChangePasswordPage,
  })),
);
const SmallSearchInput = lazy(() =>
  import('../Search/SearchPage/SmallSearchInput').then((module) => ({
    default: module.SmallSearchInput,
  })),
);
const UnregisteredUserPage = lazy(() =>
  import('../UnregisteredUserPage/UnregisteredUserPage').then((module) => ({
    default: module.UnregisteredUserPage,
  })),
);
const PaymentAndDelivery = lazy(() =>
  import('../PaymentAndDelivery/PaymentAndDelivery').then((module) => ({
    default: module.PaymentAndDelivery,
  })),
);
const ContactsPage = lazy(() =>
  import('../ContactsPage/ContactsPage').then((module) => ({ default: module.ContactsPage })),
);
const AboutUsPage = lazy(() =>
  import('../AboutUsPage/AboutUsPage').then((module) => ({ default: module.AboutUsPage })),
);
const OuterCategoriesPage = lazy(() =>
  import('../OuterCategoriesPage/OuterCategoriesPage').then((module) => ({
    default: module.OuterCategoriesPage,
  })),
);
const PrivacyPolicyPage = lazy(() =>
  import('../PrivacyPolicyPage/PrivacyPolicyPage').then((module) => ({
    default: module.PrivacyPolicyPage,
  })),
);
const PublicOfferPage = lazy(() =>
  import('../PublicOfferPage/PublicOfferPage').then((module) => ({
    default: module.PublicOfferPage,
  })),
);

import { getAllGoods } from '../../helpers/getAllGoods';
import { LoadingPage } from '../LoadingPage/LoadingPage';

import './MainContent.scss';

export function MainContent() {
  const { t } = useTranslation();
  const location = useLocation();
  const pathname = location.pathname.split('/').slice(1);

  const { Role, acessToken } = useSelector((state) => state.UserReducer);
  const { goodsArrays } = useSelector((state) => state.GoodsReducer);

  const [currentOuterCategory, setCurrentOuterCategory] = useState('');
  const [categoryTarget, setCategoryTarget] = useState('');
  const [authTableState, setAuthTableState] = useState(false);
  const [signUpPanelState, setSignUpPanel] = useState(false);
  const [shoppingCardPreview, setShoppingCardPreview] = useState(false);
  const [formAddCatalogPageShown, setAddCatalogPageForm] = useState(false);
  const [formAddSubCategoryShown, setFormAddSubCategory] = useState(false);
  const [isFormChangeOuterCategoryShown, setFormChangeOuterCategory] = useState(false);
  const [currentSubCategoryId, setCurrentSubCategoryId] = useState('');
  const [stateToRerenderBuyButton, setStateToRerenderBuyButton] = useState(false);
  const [goodsInShoppingCardQuantity, setGoodsInShoppingCardQuantity] = useState('');
  const [stateForRerender, rerenderComponent] = useState(false);
  const [ordersQuantity, setOrdersQuantity] = useState('');
  // search states
  const [searchDropDownShown, setSearchDropDownShown] = useState(false);
  const [searchSmallDropDownShown, setSearchSmallDropDownShown] = useState(false);
  const [isSmallSearchInputShown, setSmallSearchInput] = useState(false);
  const [searchedGoods, setSearchedGoods] = useState([]);

  useEffect(() => {
    const shoppingCard = JSON.parse(localStorage.getItem('shoppingCard'));
    Array.isArray(shoppingCard) && shoppingCard.length
      ? setGoodsInShoppingCardQuantity(shoppingCard.length)
      : setGoodsInShoppingCardQuantity('');
  }, [stateToRerenderBuyButton]);

  function handleSearchChange(searchRequest, setDropDownElementCallBack) {
    if (searchRequest === '') {
      setDropDownElementCallBack(false);
      setSearchedGoods([]);
      return;
    }
    if (searchRequest.length < 2) return;

    let searchRequestArr = searchRequest.split(' ').filter((item) => item !== '');

    const allGoods = getAllGoods(goodsArrays);

    const searchedGoods = allGoods.filter((good) => {
      for (let i = 0; i < searchRequestArr.length; i++) {
        let searchedPart = new RegExp(`${searchRequestArr[i]}`, 'gi');
        if (!t(`good_translation:${good._id}:title`).match(searchedPart)) {
          return false;
        }
      }
      return true;
    });

    searchedGoods && setDropDownElementCallBack(true);
    setSearchedGoods(searchedGoods);
  }

  function showSmallSearchInput() {
    setSmallSearchInput(true);
    document.getElementById('main_content').classList.toggle('pop_up_opened');
  }

  function hideSmallSearchInput() {
    setSmallSearchInput(false);
    document.getElementById('main_content').classList.toggle('pop_up_opened');
  }

  function makeMainContentUnscrollable() {
    document.getElementById('main_content').classList.add('main_content_unscrollable');
  }

  function makeMainContentScrollable() {
    document.getElementById('main_content').classList.remove('main_content_unscrollable');
  }

  function showAddCatalogForm() {
    setAddCatalogPageForm((prev) => !prev);
  }

  function showAddSubCategoryForm() {
    setFormAddSubCategory((prev) => !prev);
  }

  return (
    <div
      id='main_content'
      className='main_content'
      onClick={(e) => {
        if (searchDropDownShown === false) return;
        if (e.target.id === 'search' || e.target.parentElement.parentElement.id === 'searchDropDown') {
          return;
        }
        setSearchDropDownShown(false);
      }}
    >
      {isSmallSearchInputShown && (
        <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
          <SmallSearchInput
            goods={searchedGoods}
            searchSmallDropDownShown={searchSmallDropDownShown}
            hideSmallSearchInput={hideSmallSearchInput}
            setSearchSmallDropDownShown={setSearchSmallDropDownShown}
            handleSearchChange={handleSearchChange}
          />
        </Suspense>
      )}

      <Routes>
        <Route
          path='ordersmanager'
          element={
            <ProtectedRoute isAllowed={Role === 'ADMIN'}>
              <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
                <OrdersManager />
              </Suspense>
            </ProtectedRoute>
          }
        />
        <Route
          element={
            <NavbarPanel
              stateForRerender={stateForRerender}
              setOrdersQuantity={setOrdersQuantity}
              ordersQuantity={ordersQuantity}
              goodsInShoppingCardQuantity={goodsInShoppingCardQuantity}
              makeMainContentUnscrollable={makeMainContentUnscrollable}
              makeMainContentScrollable={makeMainContentScrollable}
              authTableState={authTableState}
              setAuthTableState={setAuthTableState}
              stateToRerenderBuyButton={stateToRerenderBuyButton}
              setStateToRerenderBuyButton={setStateToRerenderBuyButton}
              shoppingCardPreview={shoppingCardPreview}
              setShoppingCardPreview={setShoppingCardPreview}
              formAddCatalogPageShown={formAddCatalogPageShown}
              showAddCatalogForm={showAddCatalogForm}
              showAddSubCategoryForm={showAddSubCategoryForm}
              formAddSubCategoryShown={formAddSubCategoryShown}
              currentSubCategoryId={currentSubCategoryId}
              searchedGoods={searchedGoods}
              setSearchedGoods={setSearchedGoods}
              searchDropDownShown={searchDropDownShown}
              setSearchDropDownShown={setSearchDropDownShown}
              showSmallSearchInput={showSmallSearchInput}
              handleSearchChange={handleSearchChange}
              categoryTarget={categoryTarget}
              setCategoryTarget={setCategoryTarget}
              isFormChangeOuterCategoryShown={isFormChangeOuterCategoryShown}
              setFormChangeOuterCategory={setFormChangeOuterCategory}
              setCurrentOuterCategory={setCurrentOuterCategory}
              currentOuterCategory={currentOuterCategory}
            />
          }
        >
          <Route
            path='/'
            element={
              <MainPage
                shoppingCardPreview={shoppingCardPreview}
                setShoppingCardPreview={setShoppingCardPreview}
                stateToRerenderBuyButton={stateToRerenderBuyButton}
                setStateToRerenderBuyButton={setStateToRerenderBuyButton}
                makeMainContentUnscrollable={makeMainContentUnscrollable}
              />
            }
          />
          <Route
            path=':goodId'
            element={
              <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
                <GoodPageFull
                  pathname={pathname}
                  makeMainContentUnscrollable={makeMainContentUnscrollable}
                  setAuthTableState={setAuthTableState}
                  shoppingCardPreview={shoppingCardPreview}
                  setShoppingCardPreview={setShoppingCardPreview}
                  setSignUpPanel={setSignUpPanel}
                  signUpPanelState={signUpPanelState}
                  stateToRerenderBuyButton={stateToRerenderBuyButton}
                  setStateToRerenderBuyButton={setStateToRerenderBuyButton}
                />
              </Suspense>
            }
          />

          <Route path='checkemailmessage' element={<CheckEmailMessage />} />
          <Route path='confirmpasswordchange'>
            <Route
              path={`${pathname[1]}`}
              element={
                <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
                  <ChangePasswordPage recoveryPasswordToken={pathname[1]} />
                </Suspense>
              }
            />
          </Route>
          <Route path='confirmregistration'>
            <Route
              path={`${pathname[1]}`}
              element={
                <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
                  <RegistrationConfirm registrationToken={pathname[1]} />
                </Suspense>
              }
            />
          </Route>

          <Route path='outercategory'>
            <Route
              path=':outercategory'
              element={
                <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
                  <OuterCategoriesPage
                    setFormChangeOuterCategory={setFormChangeOuterCategory}
                    setCurrentOuterCategory={setCurrentOuterCategory}
                  />
                </Suspense>
              }
            />
            <Route
              path=':outercategory/:category'
              element={
                <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
                  <CatalogPage
                    showAddCatalogForm={showAddCatalogForm}
                    showAddSubCategoryForm={showAddSubCategoryForm}
                    setCategoryTarget={setCategoryTarget}
                  />
                </Suspense>
              }
            />
            <Route
              path=':outercategory/:category/:subCategory'
              element={
                <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
                  <GoodsPage
                    shoppingCardPreview={shoppingCardPreview}
                    setShoppingCardPreview={setShoppingCardPreview}
                    setCurrentSubCategoryId={setCurrentSubCategoryId}
                    showAddSubCategoryForm={showAddSubCategoryForm}
                    setCategoryTarget={setCategoryTarget}
                    categoryTarget={categoryTarget}
                    stateToRerenderBuyButton={stateToRerenderBuyButton}
                    setStateToRerenderBuyButton={setStateToRerenderBuyButton}
                    makeMainContentUnscrollable={makeMainContentUnscrollable}
                  />
                </Suspense>
              }
            />

            <Route
              path=':outercategory/:category/:subCategory/:goodId'
              element={
                <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
                  <GoodPageFull
                    pathname={pathname}
                    makeMainContentUnscrollable={makeMainContentUnscrollable}
                    setAuthTableState={setAuthTableState}
                    shoppingCardPreview={shoppingCardPreview}
                    setShoppingCardPreview={setShoppingCardPreview}
                    setSignUpPanel={setSignUpPanel}
                    signUpPanelState={signUpPanelState}
                    stateToRerenderBuyButton={stateToRerenderBuyButton}
                    setStateToRerenderBuyButton={setStateToRerenderBuyButton}
                  />
                </Suspense>
              }
            />
          </Route>

          <Route path='catalog'>
            <Route
              path=':category'
              element={
                <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
                  <CatalogPage
                    showAddCatalogForm={showAddCatalogForm}
                    showAddSubCategoryForm={showAddSubCategoryForm}
                    setCategoryTarget={setCategoryTarget}
                  />
                </Suspense>
              }
            />

            <Route
              path=':category/:subCategory'
              element={
                <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
                  <GoodsPage
                    shoppingCardPreview={shoppingCardPreview}
                    setShoppingCardPreview={setShoppingCardPreview}
                    setCurrentSubCategoryId={setCurrentSubCategoryId}
                    showAddSubCategoryForm={showAddSubCategoryForm}
                    setCategoryTarget={setCategoryTarget}
                    categoryTarget={categoryTarget}
                    stateToRerenderBuyButton={stateToRerenderBuyButton}
                    setStateToRerenderBuyButton={setStateToRerenderBuyButton}
                    makeMainContentUnscrollable={makeMainContentUnscrollable}
                  />
                </Suspense>
              }
            />

            <Route
              path=':category/:subCategory/:goodId'
              element={
                <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
                  <GoodPageFull
                    pathname={pathname}
                    makeMainContentUnscrollable={makeMainContentUnscrollable}
                    setAuthTableState={setAuthTableState}
                    shoppingCardPreview={shoppingCardPreview}
                    setShoppingCardPreview={setShoppingCardPreview}
                    setSignUpPanel={setSignUpPanel}
                    signUpPanelState={signUpPanelState}
                    stateToRerenderBuyButton={stateToRerenderBuyButton}
                    setStateToRerenderBuyButton={setStateToRerenderBuyButton}
                  />
                </Suspense>
              }
            />
          </Route>

          <Route path='goods'>
            <Route
              path='search'
              element={
                <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
                  <GoodsPage
                    shoppingCardPreview={shoppingCardPreview}
                    searchedGoods={searchedGoods}
                    setShoppingCardPreview={() => {
                      setShoppingCardPreview((prev) => !prev);
                    }}
                    showAddCatalogForm={showAddCatalogForm}
                    stateToRerenderBuyButton={stateToRerenderBuyButton}
                    setStateToRerenderBuyButton={setStateToRerenderBuyButton}
                    makeMainContentUnscrollable={makeMainContentUnscrollable}
                  />
                </Suspense>
              }
            />

            <Route
              path=':category'
              element={
                <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
                  <GoodsPage
                    shoppingCardPreview={shoppingCardPreview}
                    setShoppingCardPreview={() => {
                      setShoppingCardPreview((prev) => !prev);
                    }}
                    showAddCatalogForm={showAddCatalogForm}
                    setCategoryTarget={setCategoryTarget}
                    categoryTarget={categoryTarget}
                    stateToRerenderBuyButton={stateToRerenderBuyButton}
                    setStateToRerenderBuyButton={setStateToRerenderBuyButton}
                    makeMainContentUnscrollable={makeMainContentUnscrollable}
                  />
                </Suspense>
              }
            />

            <Route
              path=':category/:goodId'
              element={
                <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
                  <GoodPageFull
                    pathname={pathname}
                    makeMainContentUnscrollable={makeMainContentUnscrollable}
                    setAuthTableState={setAuthTableState}
                    shoppingCardPreview={shoppingCardPreview}
                    setShoppingCardPreview={() => {
                      setShoppingCardPreview((prev) => !prev);
                    }}
                    setSignUpPanel={setSignUpPanel}
                    signUpPanelState={signUpPanelState}
                    stateToRerenderBuyButton={stateToRerenderBuyButton}
                    setStateToRerenderBuyButton={setStateToRerenderBuyButton}
                  />
                </Suspense>
              }
            />
          </Route>

          <Route
            path='makeorderpage'
            element={
              <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
                <MakeOrderPage
                  rerenderComponent={rerenderComponent}
                  setStateToRerenderBuyButton={setStateToRerenderBuyButton}
                  setAuthTableState={setAuthTableState}
                  makeMainContentUnscrollable={makeMainContentUnscrollable}
                  setShoppingCardPreview={setShoppingCardPreview}
                  setSignUpPanel={setSignUpPanel}
                  setGoodsInShoppingCardQuantity={setGoodsInShoppingCardQuantity}
                  signUpPanelState={signUpPanelState}
                />
              </Suspense>
            }
          />
          <Route path='login' element={<Login />} />
          <Route path='signup' element={<SignUp />} />
          <Route
            path='userpage'
            element={
              <ProtectedRoute isAllowed={acessToken}>
                <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
                  <UserPage
                    makeMainContentScrollable={makeMainContentScrollable}
                    setShoppingCardPreview={setShoppingCardPreview}
                  />
                </Suspense>
              </ProtectedRoute>
            }
          />
          <Route
            path='unregistereduserpage'
            element={
              <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
                <UnregisteredUserPage
                  makeMainContentScrollable={makeMainContentScrollable}
                  rerenderComponent={rerenderComponent}
                  stateToRerenderBuyButton={stateToRerenderBuyButton}
                />
              </Suspense>
            }
          />

          <Route
            path='contacts'
            element={
              <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
                <ContactsPage />
              </Suspense>
            }
          />
          <Route
            path='paymentanddelivery'
            element={
              <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
                <PaymentAndDelivery />
              </Suspense>
            }
          />
          <Route
            path='aboutuspage'
            element={
              <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
                <AboutUsPage />
              </Suspense>
            }
          />
          <Route
            path='privacypolicy'
            element={
              <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
                <PrivacyPolicyPage />
              </Suspense>
            }
          />
          <Route
            path='publicoffer'
            element={
              <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
                <PublicOfferPage />
              </Suspense>
            }
          />
        </Route>
        <Route
          path='*'
          element={
            <Suspense fallback={<LoadingPage styleName='spinnerContainer' />}>
              <NotFoundPage />
            </Suspense>
          }
        />
      </Routes>
    </div>
  );
}
