import { put, call, takeLatest } from 'redux-saga/effects';

import { signUpRequest } from '../../services/signUpRequest';
import { setSignedAC } from './SignUpAC';
import { SIGN_UP_STATE } from './SignUpAT';

export function* signUpWorker({ payload }) {
  try {
    yield call(signUpRequest, payload);
    yield put(setSignedAC());
  } catch (error) {
    console.log(error, 'error in signUpWorker');
  }
}

export function* signUpWatcher() {
  yield takeLatest(SIGN_UP_STATE, signUpWorker);
}
