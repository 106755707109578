import { axiosPrivate } from '../../api/axiosPrivate';

export async function removeSubCategory({ subCategoryId, catalogId, acessToken }) {
  axiosPrivate.post(
    '/api/removesubcategory',
    {
      subCategoryId,
      catalogId,
    },
    {
      headers: { Authorization: `Bearer ${acessToken}` },
    },
  );
}
