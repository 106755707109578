// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cQGXzjRZpXJpp6cHmYXE{margin-bottom:20px;height:115px;width:250px;text-decoration:none}.cxTjc7CmpgMSZjlbFQ2K{display:flex;margin-top:10px;width:100%;height:115px}.cxTjc7CmpgMSZjlbFQ2K .PF8dHEydt6zR5DHFUs7k{display:flex}.cxTjc7CmpgMSZjlbFQ2K .PF8dHEydt6zR5DHFUs7k .bnr2ewJ0iuW6En4bmi5f{display:inline-block;text-decoration:none;padding-left:10px;align-self:center;font-size:16px;font-weight:bold;color:#000}.tuOnHYt8hh38N3LLYStx .YKjp0LPVoB6ZPm4jHG8b{width:85px;height:100px;object-fit:cover}`, "",{"version":3,"sources":["webpack://./src/components/NavBarPanel/SubCategoriesDropDown/SmallSubCategoryCard/SmallSubCategoryCard.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,YAAA,CACA,WAAA,CACA,oBAAA,CAGF,sBACE,YAAA,CACA,eAAA,CACA,UAAA,CACA,YAAA,CAEA,4CACE,YAAA,CAEA,kEACE,oBAAA,CACA,oBAAA,CACA,iBAAA,CACA,iBAAA,CACA,cAAA,CACA,gBAAA,CACA,UAAA,CAKJ,4CACE,UAAA,CACA,YAAA,CACA,gBAAA","sourcesContent":[".link_wrapper {\n  margin-bottom: 20px;\n  height: 115px;\n  width: 250px;\n  text-decoration: none;\n}\n\n.subcategorycard_container {\n  display: flex;\n  margin-top: 10px;\n  width: 100%;\n  height: 115px;\n\n  .title {\n    display: flex;\n\n    .title_span {\n      display: inline-block;\n      text-decoration: none;\n      padding-left: 10px;\n      align-self: center;\n      font-size: 16px;\n      font-weight: bold;\n      color: black;\n    }\n  }\n}\n.image {\n  .subCategoryImage {\n    width: 85px;\n    height: 100px;\n    object-fit: cover;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link_wrapper": `cQGXzjRZpXJpp6cHmYXE`,
	"subcategorycard_container": `cxTjc7CmpgMSZjlbFQ2K`,
	"title": `PF8dHEydt6zR5DHFUs7k`,
	"title_span": `bnr2ewJ0iuW6En4bmi5f`,
	"image": `tuOnHYt8hh38N3LLYStx`,
	"subCategoryImage": `YKjp0LPVoB6ZPm4jHG8b`
};
export default ___CSS_LOADER_EXPORT___;
