import React from 'react';
import { Link } from 'react-router-dom';

import { IconRefresh } from '../../Icons/IconRefresh';
import { IconCloseButton } from '../../Icons/IconCloseButton';

import classes from './AdminButtonsOuterCategory.module.scss';

export function AdminPanelButtonsOuterCategory({
  setDeletingOuterCategoryStatus,
  showAskFullDeletionForm,
  setFormChangeOuterCategory,
  setCurrentOuterCategory,
  outerCategory,
}) {
  return (
    <div className={classes.admin_buttons}>
      <Link
        className={classes.change_category_button}
        onClick={(e) => {
          setFormChangeOuterCategory(true);
          setCurrentOuterCategory(outerCategory);
        }}
      >
        <IconRefresh size={20} />
      </Link>

      <button
        className={classes.closeButton}
        id={outerCategory}
        onClick={(e) => {
          showAskFullDeletionForm();
          setCurrentOuterCategory(outerCategory);
          setDeletingOuterCategoryStatus(true);
        }}
      >
        <IconCloseButton size={15} />
      </button>
    </div>
  );
}
