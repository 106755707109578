import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setCategoriesListAC, setGoodsArraysStoreAC } from './store/Goods/GoodsAC';
import { GetUserDetailsRequest } from './store/User/UserAC';
import { setMainPageImageAC } from './store/Assets/AssetsAC';
import { setCatalogsAC } from './store/Catalog/CatalogAC';
import { MainContent } from './components/MainContent/MainContent';
import { getAssetsOnInitialLoading } from './services/getAssetsOnInitialLoading';

function App() {
  const dispatch = useDispatch();
  const { acessToken } = useSelector((state) => state.UserReducer);

  async function getAllAssets() {
    const { data } = await getAssetsOnInitialLoading();
    const { mainImageArr, categoriesList, catalogs, goods } = data;
    mainImageArr && dispatch(setMainPageImageAC(mainImageArr));
    categoriesList && dispatch(setCategoriesListAC(categoriesList.outerCategories));
    catalogs && dispatch(setCatalogsAC(catalogs));
    goods && dispatch(setGoodsArraysStoreAC(goods));
  }

  useEffect(() => {
    getAllAssets();
  }, []);

  useEffect(() => {
    const isLogged = localStorage.getItem('isLogged');
    isLogged && dispatch(GetUserDetailsRequest(acessToken));
  }, [acessToken]);

  return <MainContent />;
}

export default App;
