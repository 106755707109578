import axios from 'axios';

export function fetchAccessToken() {
  return axios
    .post(
      '/auth/getaccesstoken',
      {},
      {
        withCredentials: true, // need to send cookies to server to get access token
        accessControlAllowCredentials: true,
      },
    )
    .then((response) => response)
    .catch((err) => console.log(err, 'error in fetchin access token'));
}
