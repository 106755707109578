import { GET_CATALOGS, SET_CATALOGS, SET_ONE_CATALOG } from './CatalogAT';

export const getCatalogsAC = (payload) => ({
  type: GET_CATALOGS,
  payload,
});

export const setCatalogsAC = (payload) => ({
  type: SET_CATALOGS,
  payload,
});

export const setOneCatalogAC = (payload) => ({
  type: SET_ONE_CATALOG,
  payload,
});
