import React from 'react';

export function IconOrderBag({ size }) {
  return (
    <svg
      id='iconOrdersBag'
      strokeWidth='1'
      stroke='black'
      style={{ fill: 'none', width: size, height: size }}
      viewBox='0 0 24 24'
    >
      <title>OrderBag</title>
      <path
        d='M9 9H6.84713C6.35829 9 5.9411 9.35341 5.86073 9.8356L4.19407 19.8356C4.09248 20.4451 4.56252 21 5.18046 21H18.8195C19.4375 21 19.9075 20.4451 19.8059 19.8356L18.1393 9.8356C18.0589 9.35341 17.6417 9 17.1529 9H15M9 9H15M9 9C8.66667 7.66667 8 3 12 3C16 3 15.3333 7.66667 15 9'
        stroke='#000000'
        strokeLinejoin='round'
      />
    </svg>
  );
}
