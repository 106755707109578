import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import { ReactErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import { store } from './store/store';
import App from './App';
import './i18n';
import './style.scss';

const root = createRoot(document.getElementById('root'));

// TODO REMOVE STRICT MODE BEFORE PRODUCTION;
root.render(
  <Provider store={store}>
    {/* <StrictMode> */}
    <HelmetProvider>
      <BrowserRouter>
        <ReactErrorBoundary>
          <App />
        </ReactErrorBoundary>
      </BrowserRouter>
    </HelmetProvider>
    {/* </StrictMode> */}
  </Provider>,
);
