import React from 'react';

export function IconRefresh({ size }) {
  return (
    <svg
      id='iconRefresh'
      viewBox='0 0 512 512'
      style={{ fill: 'none', width: `${size}px`, height: `${size}px` }}
    >
      <title>refresh</title>
      <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Combined-Shape' fill='#000000' transform='translate(64.000000, 64.000000)'>
          <path d='M340.195444,210.526102 L382.537159,215.817985 C370.807686,310.617565 289.973536,384 192,384 C131.683829,384 77.8636454,356.187368 42.6646339,312.687287 L42.6666667,384 L1.42108547e-14,384 L4.26325641e-14,234.666667 L149.333333,234.666667 L149.333333,277.333333 L69.4463164,277.351889 C96.4332257,316.029088 141.261551,341.333333 192,341.333333 C268.201293,341.333333 331.072074,284.258623 340.195444,210.526102 Z M384,1.42108547e-14 L384,149.333333 L234.666667,149.333333 L234.666667,106.666667 L314.564847,106.664112 C287.579138,67.9778918 242.745446,42.6666667 192,42.6666667 C115.798362,42.6666667 52.9273575,99.7418924 43.8044325,173.474898 L1.46271768,168.183015 C13.1917463,73.382951 94.0261199,1.42108547e-14 192,1.42108547e-14 C252.316171,1.42108547e-14 306.136355,27.8126321 341.335366,71.3127128 L341.333333,1.42108547e-14 L384,1.42108547e-14 Z' />
        </g>
      </g>
    </svg>
  );
}
