import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SearchGoodCards } from './SearchGoodCards/SearchGoodCards';
import { IconSmallArrowRight } from '../Icons/IconSmallArrowRight';

import classes from './SearchDropDown.module.scss';

export function SearchDropDown({
  hideSmallSearchInput,
  setSearchSmallDropDownShown,
  goods,
  styleClassName,
  nothingFoundButtonStyle,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return goods.length ? (
    <div id='searchDropDown' className={classes[styleClassName]}>
      <SearchGoodCards goods={goods.slice(0, 8)} hideSmallSearchInput={hideSmallSearchInput} />

      <button
        type='button'
        key='index'
        className={classes.search_results_button}
        onClick={() => {
          goods.length ? navigate('/goods/search') : navigate('');
          hideSmallSearchInput && hideSmallSearchInput();
          setSearchSmallDropDownShown && setSearchSmallDropDownShown(false);
        }}
      >
        {t('static:allGoods')}
        <IconSmallArrowRight size={20} />
      </button>
    </div>
  ) : (
    <button type='button' className={classes[nothingFoundButtonStyle]}>
      {t('static:nothingFound')}
    </button>
  );
}
