import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Alert } from '../../Alert/Alert';
import { setIsErrorAC, setErrorObjAC } from '../../../store/Error/ErrorAC';
import { useWindowDimensions } from '../../../helpers/useWindowDimension';
import { emailCallBackRequest } from '../../../services/Notificator/emailCallBackRequest';
import { IconCloseButton } from '../../Icons/IconCloseButton';

import classes from './FormAskForCallBack.module.scss';

export function FormAskForCallBack({ showCallBackForm, makeMainContentScrollable }) {
  const dispatch = useDispatch();
  const { isError, errorObj } = useSelector((state) => state.ErrorReducer);
  const { width } = useWindowDimensions();
  const { t } = useTranslation();

  const [isEmailSent, setIsEmailSent] = useState(false);
  const [clientPhone, setPhoneValue] = useState('');
  const [clientName, setNameValue] = useState('');

  useEffect(
    () => () => {
      dispatch(setIsErrorAC(false));
      dispatch(setErrorObjAC({ errorMessage: '' }));
    },
    [],
  );

  async function handleSubmitForm(e) {
    if (!clientPhone) {
      dispatch(setIsErrorAC(true));
      const message = t('static:enterPhone');
      dispatch(setErrorObjAC({ errorMessage: message }));
      return;
    }
    dispatch(setIsErrorAC(false));
    dispatch(setErrorObjAC({ errorMessage: '' }));

    await emailCallBackRequest({ clientName, clientPhone })
      .then((res) => {
        if (res.status >= 200 && res.status <= 300) {
          setIsEmailSent(true);
        }
      })
      .catch((err) => {
        const message = t('static:errorSorryReoload');
        dispatch(setIsErrorAC(true));
        dispatch(setErrorObjAC({ errorMessage: message }));
      });
  }

  return (
    <>
      <div
        className={classes.background}
        onMouseDown={() => {
          showCallBackForm((prev) => !prev);
          width > 799 && makeMainContentScrollable();
        }}
      />
      <div className={classes.form_ask_for_callback_container}>
        {isError && <Alert variant='danger'>{errorObj.errorMessage}</Alert>}
        {isEmailSent && <Alert variant='success'>{t('static:weWillCallYouBack')}</Alert>}
        <button
          className={classes.closeButton}
          onClick={async () => {
            showCallBackForm((prev) => !prev);
            width > 799 && makeMainContentScrollable();
          }}
        >
          <IconCloseButton size={20} />
        </button>

        <div className={classes.title}>{t('static:callback')}</div>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await handleSubmitForm(e);
          }}
        >
          <div controlId='name' className={classes.inputContainer}>
            <label>{t('static:name')}</label>
            <input
              className={classes.input}
              type='text'
              onChange={(e) => {
                if (e.target.value.match(/[< >]/g)) return;
                setNameValue(e.target.value);
              }}
              value={clientName}
              placeholder={t('static:enterName')}
            />
          </div>
          <div controlId='phone' className={classes.inputContainer}>
            <label>{t('static:phone')}</label>
            <input
              className={classes.input}
              type='text'
              placeholder={t('static:enterPhone')}
              value={clientPhone}
              onChange={(e) => {
                if (e.target.value.match(/[a-zA-Z]/g)) return;
                setPhoneValue(e.target.value);
              }}
            />
          </div>
          <div className={classes.buttonsContainer}>
            <button className={classes.askForCallBackButton} type='submit'>
              {t('static:confirm')}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
