export function addToShoppingCard(goodId) {
  let shoppingCards = localStorage.getItem('shoppingCard');
  if (shoppingCards) {
    shoppingCards = JSON.parse(shoppingCards);

    for (let i = 0; i < shoppingCards.length; i++) {
      if (shoppingCards[i].goodId === goodId) return;
    }

    shoppingCards.push({ goodId, quantity: 1 });
  } else {
    shoppingCards = [{ goodId, quantity: 1 }];
  }
  localStorage.setItem('shoppingCard', JSON.stringify(shoppingCards));
}
