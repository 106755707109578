import { GET_MAIN_PAGE_IMAGE, SET_MAIN_PAGE_IMAGE } from './AssetsAT';

const initialState = {
  mainPageImage: '',
};

export const AssetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MAIN_PAGE_IMAGE:
      return state;
    case SET_MAIN_PAGE_IMAGE:
      return {
        ...state,
        mainPageImage: action.payload,
      };

    default:
      return state;
  }
};
