import React from 'react';
import { SmallSubCategoryCard } from '../SmallSubCategoryCard/SmallSubCategoryCard';
import classes from './SubCategoriesCards.module.scss';

export function SmallSubCategoriesCards({
  setCatalogButtonStyle,
  category,
  thisSubCategories,
  debounceHideDropDown,
  hideDropDownMenu,
}) {
  return (
    thisSubCategories &&
    thisSubCategories.map((subCategory, index) => (
      <SmallSubCategoryCard
        setCatalogButtonStyle={setCatalogButtonStyle}
        subCategory={subCategory}
        category={category}
        title={subCategory.title}
        debounceHideDropDown={debounceHideDropDown}
        hideDropDownMenu={hideDropDownMenu}
        key={index}
      />
    ))
  );
}
