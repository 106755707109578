export function handleLeftClickCircled(translateCaruselLeft, itemContainerRef, initialQuantity) {
  if (translateCaruselLeft / -100 + initialQuantity >= itemContainerRef.current.length) return;

  for (let i = 0; i < itemContainerRef.current.length; i++) {
    if (!itemContainerRef.current[i].current) return;
    itemContainerRef.current[i].current.removeAttribute('style');
    itemContainerRef.current[i].current.style.transform = 'translateX(-100%)';
  }
}

export function handleLeftClick(
  translateCaruselLeft,
  setTranslateCaruselLeft,
  itemContainerRef,
  initialQuantity,
) {
  if (translateCaruselLeft / -100 + initialQuantity >= itemContainerRef.current.length) return;
  const translateCaruselLeftChanged = translateCaruselLeft - 100;
  setTranslateCaruselLeft(translateCaruselLeftChanged);

  for (let i = 0; i < itemContainerRef.current.length; i++) {
    itemContainerRef.current[i].current.removeAttribute('style');
    itemContainerRef.current[i].current.style.transform = `translateX(${translateCaruselLeftChanged}%)`;
  }
}

export function handleRightClick(translateCaruselLeft, setTranslateCaruselLeft, itemContainerRef) {
  if (translateCaruselLeft === 0) return;
  if (itemContainerRef && itemContainerRef?.current.length) {
    const translateCaruselLeftChanged = translateCaruselLeft + 100;
    setTranslateCaruselLeft(translateCaruselLeftChanged);

    for (let i = 0; i < itemContainerRef.current.length; i++) {
      itemContainerRef.current[i].current.removeAttribute('style');
      itemContainerRef.current[i].current.style.transform = `translateX(${translateCaruselLeftChanged}%)`;
    }
  }
}
