import { SET_IS_ERROR, SET_ERROR_OBJECT } from './ErrorAT';

export const setIsErrorAC = (payload) => ({
  type: SET_IS_ERROR,
  payload,
});

export const setErrorObjAC = (payload) => ({
  type: SET_ERROR_OBJECT,
  payload,
});
