import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SmallSubCategoriesCards } from './SmallSubCategoriesCards/SmallSubCategoriesCards';
import classes from './SubCategoriesDropDown.module.scss';

export function SubCategoriesDropDown({
  setCatalogButtonStyle,
  debounceShowDropDown,
  categoryForSubCategory,
  debounceHideDropDown,
  hideDropDownMenu,
}) {
  const { catalogs } = useSelector((state) => state.CatalogReducer);
  const [thisCatalog, setThisCatalog] = useState();
  const [thisSubCategories, setThisSubCategories] = useState();

  const allGoodsObj = {
    _id: 'allgoods',
    title: 'allgoods',
    previewImage: thisCatalog && thisCatalog.previewImage,
  };

  useEffect(() => {
    const catalog = catalogs.find((catalog) => catalog.category === categoryForSubCategory);
    catalog && setThisCatalog(catalog);
  }, [catalogs, categoryForSubCategory]);

  useEffect(() => {
    const subCategories = (thisCatalog && thisCatalog.subCategories) || null;
    subCategories && setThisSubCategories([...subCategories, allGoodsObj]);
  }, [thisCatalog]);

  return (
    <div
      id='subCategoryDropDown'
      className={classes.subcategories_dropdown_container}
      onMouseEnter={(e) => {
        debounceHideDropDown.cancel();
        debounceShowDropDown();
      }}
      onMouseLeave={(e) => {
        debounceHideDropDown();
      }}
    >
      <SmallSubCategoriesCards
        setCatalogButtonStyle={setCatalogButtonStyle}
        thisSubCategories={thisSubCategories}
        categoryForSubCategory={categoryForSubCategory}
        category={categoryForSubCategory}
        debounceHideDropDown={debounceHideDropDown}
        hideDropDownMenu={hideDropDownMenu}
      />
    </div>
  );
}
