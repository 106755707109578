export const GET_USERS_SHOPPINGCARDS = 'GET_USERS_SHOPPINGCARDS';
export const GET_SHOPPINGCARD_BY_ID = 'GET_SHOPPINGCARD_BY_ID';
export const SET_SHOPPINGCARD_BY_ID = 'SET_SHOPPINGCARD_BY_ID';
export const SET_SHOPPINGCARD_STATUS = 'SET_SHOPPINGCARD_STATUS';
export const SET_SHOPPINGCARD_QUANTITY = 'SET_SHOPPINGCARD_QUANTITY';
export const FETCH_USERS_SHOPPINGCARDS = 'FETCH_USERS_SHOPPINGCARDS';
export const SET_USER_SHOPPINGCARD_STORE = 'SET_USER_SHOPPINGCARD_STORE';
export const GET_ALL_INACTIVE_SHOPPINGCARDS = 'GET_ALL_SHOPPINGCARDS';
export const GET_ALL_ACTIVE_SHOPPINGCARDS = 'GET_ALL_ACTIVE_SHOPPINGCARDS';
export const SET_ALL_INACTIVE_SHOPPINGCARDS_STORE = 'SET_ALL_SHOPPINGCARDS_STORE';
export const SET_ALL_ACTIVE_SHOPPINGCARDS_STORE = 'SET_ALL_ACTIVE_SHOPPINGCARDS_STORE';
export const SET_ID_TO_ADD_GOODS = 'SET_ID_TO_ADD_GOODS';
