import {
  SET_CATEGORIES_LIST,
  GET_CATEGORIES_LIST,
  SET_GOODS_ARRAYS_STORE,
  GET_GOODS_ARRAYS,
} from './GoodsAT';

const initialState = {
  categoriesList: [],
  goodsArrays: [],
};

export const GoodsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIES_LIST:
      return state;
    case SET_CATEGORIES_LIST:
      return {
        ...state,
        categoriesList: action.payload,
      };

    case GET_GOODS_ARRAYS:
      return state;
    case SET_GOODS_ARRAYS_STORE:
      return {
        ...state,
        goodsArrays: action.payload,
      };
    default:
      return state;
  }
};
