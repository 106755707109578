import { GET_MAIN_PAGE_IMAGE, SET_MAIN_PAGE_IMAGE } from './AssetsAT';

export const getMainPageImageAC = (payload) => ({
  type: GET_MAIN_PAGE_IMAGE,
  payload,
});

export const setMainPageImageAC = (payload) => ({
  type: SET_MAIN_PAGE_IMAGE,
  payload,
});
