import React from 'react';

import telegram from '../../assets/telegram.png';
import instagram from '../../assets/instagram.png';
import facebook from '../../assets/facebook.png';
import viber from '../../assets/viber.png';

import classes from './SoshialMediaIconsContainer.module.scss';

export function SoshialMediaIconsContainer() {
  return (
    <div className={classes.sochial_media_links_container}>
      <div className={classes.media_link_container}>
        <a
          target='_blank'
          href='https://t.me/LarisaSalmanova'
          className={classes.sochial_media_link}
          rel='noreferrer'
        >
          {/* <IconTelegram /> */}
          <img src={telegram} alt='' style={{ width: '30px', height: '30px' }} />
        </a>
      </div>
      <div className={classes.media_link_container}>
        <a
          target='_blank'
          href='viber://chat?number=%2B380957045546'
          className={classes.sochial_media_link}
          rel='noreferrer'
        >
          {/* <IconViber /> */}
          <img src={viber} alt='' style={{ width: '30px', height: '30px' }} />
        </a>
      </div>
      <div className={classes.media_link_container}>
        <a
          target='_blank'
          href='https://www.instagram.com/larisa__lara13/'
          className={classes.sochial_media_link}
          rel='noreferrer'
        >
          {/* <IconInstagram /> */}
          <img src={instagram} alt='' style={{ width: '30px', height: '30px' }} />
        </a>
      </div>
      <div className={classes.media_link_container}>
        <a
          target='_blank'
          href='https://m.me/larisa.zlyden'
          className={classes.sochial_media_link}
          rel='noreferrer'
        >
          {/* <IconFacebook /> */}
          <img src={facebook} alt='' style={{ width: '30px', height: '30px' }} />
        </a>
      </div>
    </div>
  );
}
