// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.m4bfePMKac9i3bmt1paO{position:absolute;display:flex;flex-direction:row;flex-wrap:wrap;justify-content:flex-start;align-content:flex-start;gap:2px 1vw;background-color:#b0e0f1;width:550px;min-height:100vh;left:100%;padding-left:20px;padding-top:10px;z-index:1000}`, "",{"version":3,"sources":["webpack://./src/components/NavBarPanel/SubCategoriesDropDown/SubCategoriesDropDown.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,cAAA,CACA,0BAAA,CACA,wBAAA,CACA,WAAA,CACA,wBAAA,CACA,WAAA,CACA,gBAAA,CACA,SAAA,CACA,iBAAA,CACA,gBAAA,CACA,YAAA","sourcesContent":[".subcategories_dropdown_container {\n  position: absolute;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n  align-content: flex-start;\n  gap: 2px 1vw;\n  background-color: #b0e0f1;\n  width: 550px;\n  min-height: 100vh;\n  left: 100%;\n  padding-left: 20px;\n  padding-top: 10px;\n  z-index: 1000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subcategories_dropdown_container": `m4bfePMKac9i3bmt1paO`
};
export default ___CSS_LOADER_EXPORT___;
