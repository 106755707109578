import React from 'react';

import classes from './CatalogCardPlaceholder.module.scss';

export function CatalogCardPlaceholder(idx) {
  return (
    <div className={classes.catalogCardPlaceholder} key={idx}>
      <div className={classes.imagePlaceholder} />
      <div className={classes.titlePlaceholder} />
    </div>
  );
}
