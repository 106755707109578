// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GzANfttL3EJET2ikbY4S{display:flex}.GzANfttL3EJET2ikbY4S a{color:#fff;font-size:18px;text-align:left;padding-left:10px;padding-top:10px;padding-bottom:10px;text-decoration:none;width:180px}.GzANfttL3EJET2ikbY4S button{width:60px}.GzANfttL3EJET2ikbY4S:hover{background-color:#c2ebfa}.GzANfttL3EJET2ikbY4S:hover a{color:#ee763c}.bxbX7Xi2KpPzHGHbImb4{border:none;background:none;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/NavBarPanel/DropDownPanel/DropDownOuterPanel/OuterCategoryContainer/OuterCategoryContainer.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CAEA,wBACE,UAAA,CACA,cAAA,CACA,eAAA,CACA,iBAAA,CACA,gBAAA,CACA,mBAAA,CACA,oBAAA,CACA,WAAA,CAGF,6BACE,UAAA,CAIJ,4BACE,wBAAA,CACA,8BACE,aAAA,CAIJ,sBACE,WAAA,CACA,eAAA,CACA,cAAA","sourcesContent":[".outerCategory {\n  display: flex;\n\n  a {\n    color: white;\n    font-size: 18px;\n    text-align: left;\n    padding-left: 10px;\n    padding-top: 10px;\n    padding-bottom: 10px;\n    text-decoration: none;\n    width: 180px;\n  }\n\n  button {\n    width: 60px;\n  }\n}\n\n.outerCategory:hover {\n  background-color: #c2ebfa;\n  a {\n    color: #ee763c;\n  }\n}\n\n.arrowRightButton {\n  border: none;\n  background: none;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerCategory": `GzANfttL3EJET2ikbY4S`,
	"arrowRightButton": `bxbX7Xi2KpPzHGHbImb4`
};
export default ___CSS_LOADER_EXPORT___;
