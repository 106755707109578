import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useGetGoodLink } from '../../../helpers/useGetGoodLink';

import classes from './SearchGoodCard.module.scss';

export function SearchGoodCard({ good, hideSmallSearchInput }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [price, setPrice] = useState();

  let link = useGetGoodLink(good, 'search');

  useEffect(() => {
    if (good && good.discount && good.discount.amount) {
      setPrice(() => Math.floor(good.price - (good.price * good.discount.amount) / 100));
    } else {
      setPrice(good.price);
    }
  }, [good]);

  return (
    <div
      className={classes.container}
      onClick={() => {
        navigate(link);
        hideSmallSearchInput && hideSmallSearchInput();
      }}
    >
      <div className={classes.imageSection}>
        <img src={good.mainImage} alt='' />
      </div>
      <div className={classes.titleAndPriceSection}>
        <div className={classes.title}>{good && good.title}</div>
        <div className={classes.price}>
          {price} {t('static:uah')}
        </div>
      </div>
    </div>
  );
}
