// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HL61hhnycLHPmHF1aiHl{display:flex;flex-direction:row;position:relative;justify-content:space-between;right:0;background-color:#a36262}.HL61hhnycLHPmHF1aiHl .Yxbn4LEGzHMSRqLrg5KT{padding:0;margin:0px 3px 0px 0px}.HL61hhnycLHPmHF1aiHl .OyvDA_dHbOyHRE0MoF1X{padding:0;margin:0}.HL61hhnycLHPmHF1aiHl .sp6kca7nu2cO_5naVjM7{border:none;background:none;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/NavBarPanel/AdminPanelButtons/AdminPanelButtons.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,6BAAA,CACA,OAAA,CACA,wBAAA,CAEA,4CACE,SAAA,CACA,sBAAA,CAGF,4CACE,SAAA,CACA,QAAA,CAGF,4CACE,WAAA,CACA,eAAA,CACA,cAAA","sourcesContent":[".admin_buttons {\n  display: flex;\n  flex-direction: row;\n  position: relative;\n  justify-content: space-between;\n  right: 0;\n  background-color: rgb(163, 98, 98);\n\n  .change_category_button {\n    padding: 0;\n    margin: 0px 3px 0px 0px;\n  }\n\n  .add_catalog_button {\n    padding: 0;\n    margin: 0;\n  }\n\n  .closeButton {\n    border: none;\n    background: none;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"admin_buttons": `HL61hhnycLHPmHF1aiHl`,
	"change_category_button": `Yxbn4LEGzHMSRqLrg5KT`,
	"add_catalog_button": `OyvDA_dHbOyHRE0MoF1X`,
	"closeButton": `sp6kca7nu2cO_5naVjM7`
};
export default ___CSS_LOADER_EXPORT___;
