import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import { SignUpReducer } from './SignUp/SignUpReducer';
import { UserReducer } from './User/UserReducer';
import { GoodsReducer } from './Goods/GoodsReducer';
import { AssetsReducer } from './Assets/AssetsReducer';
import { CatalogReducer } from './Catalog/CatalogReducer';
import { ShoppingCardsReducer } from './ShoppingCards/ShoppingCardsReducer';
import { ErrorReducer } from './Error/ErrorReducer';
import { rootSaga } from './rootSaga';

const devtoolsState = process.env.NODE_ENV === 'development';
const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    SignUpReducer,
    UserReducer,
    GoodsReducer,
    ShoppingCardsReducer,
    AssetsReducer,
    CatalogReducer,
    ErrorReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
  devTools: devtoolsState,
});

sagaMiddleware.run(rootSaga);
