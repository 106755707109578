// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pTCCGLzUaSSmvLCvQQQj{display:flex;justify-content:center}.pTCCGLzUaSSmvLCvQQQj .qAkhKITRR02y8pQxj0hA{padding-top:3px;padding-left:10px}.pTCCGLzUaSSmvLCvQQQj .rqqxZkt0tKxxheczRkC9{color:#000}.pTCCGLzUaSSmvLCvQQQj .rqqxZkt0tKxxheczRkC9:hover{color:#4f4f4f}`, "",{"version":3,"sources":["webpack://./src/components/SoshialMediaIconsContainer/SoshialMediaIconsContainer.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CAEA,4CACE,eAAA,CACA,iBAAA,CAGF,4CACE,UAAA,CAGF,kDACE,aAAA","sourcesContent":[".sochial_media_links_container {\n  display: flex;\n  justify-content: center;\n\n  .media_link_container {\n    padding-top: 3px;\n    padding-left: 10px;\n  }\n\n  .sochial_media_link {\n    color: black;\n  }\n\n  .sochial_media_link:hover {\n    color: rgb(79, 79, 79);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sochial_media_links_container": `pTCCGLzUaSSmvLCvQQQj`,
	"media_link_container": `qAkhKITRR02y8pQxj0hA`,
	"sochial_media_link": `rqqxZkt0tKxxheczRkC9`
};
export default ___CSS_LOADER_EXPORT___;
