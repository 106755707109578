import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { useTranslation } from 'react-i18next';

import { changeGoodQuantityInShoppingCard } from '../../../helpers/LocalStorage/changeGoodQuantityInShoppingCard';
import { ShoppingCardGoods } from '../ShoppingCardGoods/ShoppingCardGoods';

import classes from './ShoppingCardsContainer.module.scss';

export function ShoppingCardsContainer({
  setShoppingCardPreview,
  makeMainContentScrollable,
  setTotalPrice,
  setIsPossibleToMakeOrder,
  customStyleShoppingCard,
  setStateToRerenderBuyButton,
  setGoodsQuantityMakeOrder,
  orderStatus,
}) {
  const { t } = useTranslation();

  const { goodsArrays } = useSelector((state) => state.GoodsReducer);

  const [goodsForShoppingCards, setGoodsForShoppingCards] = useState([]);
  const [goodsQuantity, setGoodsQuantity] = useState([]);
  const [goodsIdsArr, setGoodsIdsArr] = useState([]);
  const [pageRerender, setPageRerender] = useState(false);

  useEffect(() => {
    const shoppingCard = localStorage.getItem('shoppingCard');
    const shoppingCardParsed = shoppingCard && JSON.parse(shoppingCard);
    setGoodsQuantity(shoppingCardParsed);
    setGoodsQuantityMakeOrder && setGoodsQuantityMakeOrder(shoppingCardParsed);
    const goodsIdsArrTemp = [];
    shoppingCardParsed &&
      shoppingCardParsed.forEach((good) => {
        goodsIdsArrTemp.push(good.goodId);
      });

    setGoodsIdsArr(goodsIdsArrTemp);
  }, [pageRerender]);

  useEffect(() => {
    setTotalPrice(0);

    const goodsForShoppingCardsArr = goodsArrays.slice().reduce((prevRes, currentGoodObj) => {
      currentGoodObj.goods.forEach((good) => {
        if (goodsIdsArr.includes(good._id)) {
          const goodCopy = cloneDeep(good);

          goodsQuantity.forEach((goodQuantityObj) => {
            if (goodQuantityObj.goodId === good._id) {
              goodCopy.quantityToSell = goodQuantityObj.quantity;
              let goodPrice;
              if (goodCopy.discount && goodCopy.discount.hasDiscount && goodCopy.discount.amount) {
                goodPrice = Math.floor(goodCopy.price - (goodCopy.price * goodCopy.discount.amount) / 100);
              } else {
                goodPrice = goodCopy.price;
              }

              setTotalPrice((prev) => {
                let result = prev;
                result += goodCopy.quantityToSell * goodPrice;
                return result;
              });
            }
          });
          prevRes.push(goodCopy);
        }
      });
      return prevRes;
    }, []);
    setGoodsForShoppingCards(goodsForShoppingCardsArr);
  }, [goodsArrays, goodsIdsArr, goodsQuantity]);

  useEffect(() => {
    if (goodsForShoppingCards && !goodsForShoppingCards.length) {
      setIsPossibleToMakeOrder(false);
    }
  }, [goodsForShoppingCards]);

  async function handleChangeQuantity({ goodId, inputValue, sign }) {
    if (!sign) {
      changeGoodQuantityInShoppingCard(goodId, inputValue, setPageRerender);
    }

    if (sign === 'plus') {
      const newQuantity = inputValue + 1;
      changeGoodQuantityInShoppingCard(goodId, newQuantity, setPageRerender);
    }

    if (sign === 'minus') {
      const newQuantity = inputValue - 1;
      changeGoodQuantityInShoppingCard(goodId, newQuantity, setPageRerender);
    }
  }

  return (
    <div className={classes[customStyleShoppingCard]}>
      {goodsForShoppingCards.length ? (
        <ShoppingCardGoods
          setShoppingCardPreview={setShoppingCardPreview}
          makeMainContentScrollable={makeMainContentScrollable}
          goodsForShoppingCards={goodsForShoppingCards}
          setIsPossibleToMakeOrder={setIsPossibleToMakeOrder}
          setPageRerender={setPageRerender}
          handleChangeQuantity={handleChangeQuantity}
          orderStatus={orderStatus}
          isOrdered={false}
          customStyleShoppingCard={customStyleShoppingCard}
          setStateToRerenderBuyButton={setStateToRerenderBuyButton}
        />
      ) : (
        <div className={classes.noGoodsMessage}>
          <span>{t('static:noGoodsInShoppingCard')}</span>
        </div>
      )}
    </div>
  );
}
