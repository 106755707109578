import React, { useState, useRef, createRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { handleLeftClick, handleRightClick, handleLeftClickCircled } from '../../helpers/handleCaruselClick';
import { IconLeftArrow } from '../Icons/IconLeftArrow';
import { ImageComponent } from '../ImageComponent/ImageComponent';
import { IconRightArrow } from '../Icons/IconRightArrow';
import { deleteMainImage } from '../../services/MainPageImg/deleteImage';
import { getMainPageImageAC } from '../../store/Assets/AssetsAC';

import classes from './Carusel.module.scss';

export function Carusel({ images, width }) {
  const dispatch = useDispatch();
  const { Role } = useSelector((state) => state.UserReducer);

  const [isCaruselStopped, stopCarusel] = useState(false);
  const [imagesOrder, setImagesOrder] = useState(images);
  const [translateCaruselLeft, setTranslateCaruselLeft] = useState(0);
  const [cardsShown, setCardsShown] = useState(1);
  const [caruselCooldown, setCaruselCooldown] = useState(false);
  const itemContainerRef = useRef([]);
  const caruselTimerId = useRef(null);
  const caruselOrderResetTimerId = useRef(null);

  addEventListener('transitionend', () => {
    setCaruselCooldown(false);
  });

  addEventListener('transitionstart', () => {
    setCaruselCooldown(true);
  });

  itemContainerRef.current = new Array(images.length)
    .fill(null)
    .map((_, i) => itemContainerRef.current[i] ?? createRef());

  useEffect(() => {
    if (isCaruselStopped) return;

    caruselTimerId.current = setTimeout(() => {
      handleLeftClickCircled(translateCaruselLeft, itemContainerRef, cardsShown);

      caruselOrderResetTimerId.current = setTimeout(() => {
        setImagesOrder((prevOrder) => {
          const res = [];
          res.push(...prevOrder.slice(1));
          res.push(prevOrder[0]);
          return res;
        });

        for (let i = 0; i < itemContainerRef.current.length; i++) {
          if (!itemContainerRef.current[i].current) return;
          itemContainerRef.current[i].current.removeAttribute('style');
          itemContainerRef.current[i].current.style.transition = '0s';
          itemContainerRef.current[i].current.style.transform = `translateX(${0}%)`;
        }
      }, [1000]);
    }, [5000]);
  }, [imagesOrder, isCaruselStopped]);

  return (
    <div className={classes.carusel}>
      <button
        type='button'
        className={classes.buttonLeft}
        onClick={() => {
          stopCarusel(true);
          if (caruselCooldown) return;
          clearTimeout(caruselTimerId.current);
          clearTimeout(caruselOrderResetTimerId.current);
          handleLeftClick(translateCaruselLeft, setTranslateCaruselLeft, itemContainerRef, cardsShown);
        }}
      >
        <IconLeftArrow size={30} color='white' />
      </button>

      <div className={classes.sliesWrapper}>
        {imagesOrder &&
          imagesOrder.map((image, idx) => (
            <div className={classes.slideWrapper} ref={itemContainerRef.current[idx]} key={image._id}>
              {Role === 'ADMIN' && (
                <button
                  type='button'
                  className={classes.deleteImageButton}
                  onClick={async () => {
                    // eslint-disable-next-line no-restricted-globals
                    confirm('Удалить картинку?') && (await deleteMainImage(image._id));
                    dispatch(getMainPageImageAC());
                  }}
                >
                  Delete image
                </button>
              )}

              <ImageComponent
                src={image.mainImage}
                alt='wooolama poster'
                width={`${width}px`}
                height={`${width / 2}px`}
                customStyleImg='mainPageCaruselImage'
                blurHash={image.mainImageBlured}
              />
            </div>
          ))}
      </div>
      {translateCaruselLeft <= -100 && (
        <button
          type='button'
          className={classes.buttonRight}
          onClick={() => {
            stopCarusel(true);
            if (caruselCooldown) return;
            handleRightClick(translateCaruselLeft, setTranslateCaruselLeft, itemContainerRef, cardsShown);
          }}
        >
          <IconRightArrow size={30} color='white' />
        </button>
      )}
    </div>
  );
}
