// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.S_JiCKyTCk1_Ah0qwGDM{max-width:650px;background-color:#a9cfa5;padding-left:5px;padding-right:5px}.S_JiCKyTCk1_Ah0qwGDM .maySkuym4I8BCGkS_vMN{font-weight:bolder;width:fit-content;margin-left:auto;margin-right:45px}.S_JiCKyTCk1_Ah0qwGDM .BMPth5EFo9CA6ZnX4yfH{margin-left:10vw;margin-right:10vw;margin-top:30px;font-size:18px}@media screen and (min-width: 1700px){.S_JiCKyTCk1_Ah0qwGDM{max-width:800px}}@media screen and (max-width: 1080px){.S_JiCKyTCk1_Ah0qwGDM{margin-left:auto;margin-right:auto}}.BMPth5EFo9CA6ZnX4yfH{margin-left:3vw;font-size:18px}`, "",{"version":3,"sources":["webpack://./src/components/ShoppingCard/ShoppingCardsContainer/ShoppingCardsContainer.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,wBAAA,CACA,gBAAA,CACA,iBAAA,CAEA,4CACE,kBAAA,CACA,iBAAA,CACA,gBAAA,CACA,iBAAA,CAGF,4CACE,gBAAA,CACA,iBAAA,CACA,eAAA,CACA,cAAA,CAGF,sCApBF,sBAqBI,eAAA,CAAA,CAGF,sCAxBF,sBAyBI,gBAAA,CACA,iBAAA,CAAA,CAIJ,sBACE,eAAA,CACA,cAAA","sourcesContent":[".shoppingCardMakeOrderPage {\n  max-width: 650px;\n  background-color: rgb(169, 207, 165);\n  padding-left: 5px;\n  padding-right: 5px;\n\n  .orderTotalPrice {\n    font-weight: bolder;\n    width: fit-content;\n    margin-left: auto;\n    margin-right: 45px;\n  }\n\n  .noGoodsMessage {\n    margin-left: 10vw;\n    margin-right: 10vw;\n    margin-top: 30px;\n    font-size: 18px;\n  }\n\n  @media screen and (min-width: 1700px) {\n    max-width: 800px;\n  }\n\n  @media screen and (max-width: 1080px) {\n    margin-left: auto;\n    margin-right: auto;\n  }\n}\n\n.noGoodsMessage {\n  margin-left: 3vw;\n  font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shoppingCardMakeOrderPage": `S_JiCKyTCk1_Ah0qwGDM`,
	"orderTotalPrice": `maySkuym4I8BCGkS_vMN`,
	"noGoodsMessage": `BMPth5EFo9CA6ZnX4yfH`
};
export default ___CSS_LOADER_EXPORT___;
