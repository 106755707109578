import {
  GET_USERS_SHOPPINGCARDS,
  SET_ALL_INACTIVE_SHOPPINGCARDS_STORE,
  GET_SHOPPINGCARD_BY_ID,
  SET_SHOPPINGCARD_BY_ID,
  SET_SHOPPINGCARD_STATUS,
  SET_SHOPPINGCARD_QUANTITY,
  SET_ID_TO_ADD_GOODS,
  SET_ALL_ACTIVE_SHOPPINGCARDS_STORE,
  GET_ALL_INACTIVE_SHOPPINGCARDS,
  GET_ALL_ACTIVE_SHOPPINGCARDS,
} from './ShoppingCardsAT';

const initialState = {
  idToAddGoods: '',
  allActiveShoppingCards: [],
  allInactiveShoppingCards: [],
};

export const ShoppingCardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_SHOPPINGCARDS:
      return state;

    case GET_ALL_INACTIVE_SHOPPINGCARDS:
      return state;

    case GET_ALL_ACTIVE_SHOPPINGCARDS:
      return state;

    case SET_ID_TO_ADD_GOODS:
      return {
        ...state,
        idToAddGoods: action.payload,
      };

    case SET_ALL_ACTIVE_SHOPPINGCARDS_STORE:
      return {
        ...state,
        allActiveShoppingCards: action.payload,
      };

    case SET_ALL_INACTIVE_SHOPPINGCARDS_STORE:
      return {
        ...state,
        allInactiveShoppingCards: action.payload,
      };

    case GET_SHOPPINGCARD_BY_ID:
      return state;

    case SET_SHOPPINGCARD_BY_ID:
      return {
        ...state,
        allActiveShoppingCards: state.allActiveShoppingCards.map((shoppingCard) => {
          if (shoppingCard._id === action.payload._id) {
            return action.payload;
          }
          return shoppingCard;
        }),
      };

    case SET_SHOPPINGCARD_STATUS:
      return {
        ...state,
        allActiveShoppingCards:
          state.allActiveShoppingCards &&
          [...state.allActiveShoppingCards, ...state.allInactiveShoppingCards].map((shoppingCard) => {
            if (shoppingCard._id === action.payload._id) {
              // shoppingCard.status = action.payload.status;
              return action.payload;
            }
            return shoppingCard;
          }),
      };

    case SET_SHOPPINGCARD_QUANTITY:
      return {
        ...state,
        allActiveShoppingCards:
          state.allActiveShoppingCards &&
          state.allActiveShoppingCards.map((shoppingCard) => {
            if (shoppingCard._id === action.payload._id) {
              shoppingCard.goodsQuantity = action.payload.goodsQuantity;
              return shoppingCard;
            }
            return shoppingCard;
          }),
      };

    default:
      return state;
  }
};
