// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fNZ0comLQjoYANFkc_Na{display:flex;margin:5px;cursor:pointer;background-color:#fcfcfc;border-bottom:1px solid #999898}.fNZ0comLQjoYANFkc_Na .oc55JrABWdE3qn5It6sM .ulekEeJqRAO0NmZb1pOm{font:1.2em Georgia,serif bolder}.fNZ0comLQjoYANFkc_Na .oc55JrABWdE3qn5It6sM .rjKGFkAEH432TWoZGIEI{font:1.1em bolder}.fNZ0comLQjoYANFkc_Na .la3iUFqBacjA9AcRV69S{width:60px;height:70px}.fNZ0comLQjoYANFkc_Na .la3iUFqBacjA9AcRV69S img{width:inherit;height:inherit}`, "",{"version":3,"sources":["webpack://./src/components/Search/SearchGoodCard/SearchGoodCard.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,UAAA,CACA,cAAA,CACA,wBAAA,CACA,+BAAA,CAGE,kEACE,+BAAA,CAEF,kEACE,iBAAA,CAIJ,4CACE,UAAA,CACA,WAAA,CACA,gDACE,aAAA,CACA,cAAA","sourcesContent":[".container {\n  display: flex;\n  margin: 5px;\n  cursor: pointer;\n  background-color: rgb(252, 252, 252);\n  border-bottom: 1px solid rgb(153, 152, 152);\n\n  .titleAndPriceSection {\n    .title {\n      font: 1.2em Georgia, serif bolder;\n    }\n    .price {\n      font: 1.1em bolder;\n    }\n  }\n\n  .imageSection {\n    width: 60px;\n    height: 70px;\n    img {\n      width: inherit;\n      height: inherit;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `fNZ0comLQjoYANFkc_Na`,
	"titleAndPriceSection": `oc55JrABWdE3qn5It6sM`,
	"title": `ulekEeJqRAO0NmZb1pOm`,
	"price": `rjKGFkAEH432TWoZGIEI`,
	"imageSection": `la3iUFqBacjA9AcRV69S`
};
export default ___CSS_LOADER_EXPORT___;
