import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useWindowDimensions } from '../../helpers/useWindowDimension';

import { Login } from './Login/Login';
import { SignUp } from './SignUp/SignUp';
import { ForgotPassword } from './ForgotPassword/ForgotPassword';

import classes from './Auth.module.scss';

export function Auth({ setAuthTableState, signUpPanelState, makeMainContentScrollable }) {
  const location = useLocation();
  const { width } = useWindowDimensions();

  const { pathname } = location;
  const { t } = useTranslation();

  useEffect(() => {
    localStorage.setItem('lastVisitedBeforeAuth', pathname);
  }, [pathname]);

  const [forgotPasswordPage, setForgetPasswordPage] = useState(false);
  const [switchToLogin, setSwitchToLogin] = useState(true);
  const [switchToSingUp, setSwitchToSingUp] = useState(false);
  const switchLoginButtonOn = classes.switchLoginOn;
  const switchLoginButtonOff = classes.switchLoginOff;

  useState(() => {
    if (signUpPanelState) {
      setSwitchToLogin(false);
      setSwitchToSingUp(true);
    }
  });

  function loginButtonSwitcher() {
    setSwitchToLogin(!switchToLogin);
    setSwitchToSingUp(!switchToSingUp);
  }

  return (
    <>
      <div
        className={classes.loginTabBackground}
        onMouseDown={(e) => {
          setAuthTableState((prev) => !prev);
          width > 799 && makeMainContentScrollable();
        }}
      />
      {forgotPasswordPage ? (
        <ForgotPassword setAuthTableState={setAuthTableState} setForgetPasswordPage={setForgetPasswordPage} />
      ) : (
        <div
          className={classes.loginAndSignUpContainer}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={classes.switchButtons}>
            <button
              type='button'
              onClick={() => {
                if (switchToLogin) return;
                loginButtonSwitcher();
              }}
              className={switchToLogin ? switchLoginButtonOn : switchLoginButtonOff}
            >
              {t('static:Login')}
            </button>
            <button
              type='button'
              onClick={() => {
                if (switchToSingUp) return;
                loginButtonSwitcher();
              }}
              className={switchToLogin ? switchLoginButtonOff : switchLoginButtonOn}
            >
              {t('static:Signup')}
            </button>
          </div>
          {switchToLogin ? (
            <Login
              setForgetPasswordPage={setForgetPasswordPage}
              setAuthTableState={setAuthTableState}
              makeMainContentScrollable={makeMainContentScrollable}
            />
          ) : (
            <SignUp
              setAuthTableState={setAuthTableState}
              makeMainContentScrollable={makeMainContentScrollable}
            />
          )}
        </div>
      )}
    </>
  );
}
