import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Alert } from '../../Alert/Alert';
import { useWindowDimensions } from '../../../helpers/useWindowDimension';
import { setIsErrorAC, setErrorObjAC } from '../../../store/Error/ErrorAC';
import { login } from '../../../store/User/UserAC';
import { IconCloseButton } from '../../Icons/IconCloseButton';

import classes from './Login.module.scss';

export function Login({ setForgetPasswordPage, setAuthTableState, makeMainContentScrollable }) {
  const { isError, errorObj } = useSelector((state) => state.ErrorReducer);
  const { isLogged } = useSelector((state) => state.UserReducer);

  const { width } = useWindowDimensions();

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const emailRef = useRef();
  const passwordRef = useRef();

  const { errorMessage } = errorObj;
  const [passwordShow, setPasswordShown] = useState('password');
  const [loading, setLoading] = useState(false);

  useEffect(
    () => () => {
      dispatch(setIsErrorAC(false));
      dispatch(setErrorObjAC({ errorMessage: '' }));
    },
    [],
  );

  useEffect(() => {
    if (isLogged) {
      setAuthTableState((prev) => !prev);
    }
  }, [isLogged, isError]);

  async function handleSubmit(e) {
    e.preventDefault();
    const user = {
      username: emailRef.current.value,
      email: emailRef.current.value,
      password: passwordRef.current.value,
    };

    try {
      setLoading(true);
      dispatch(login(user));
      makeMainContentScrollable();
    } catch (error) {
      console.log('error in catch', error);
    }
    setLoading(false);
  }

  return (
    <div className={classes.cardbody}>
      <button
        className={classes.closeButton}
        onClick={() => {
          setAuthTableState((prev) => !prev);
          width > 799 && makeMainContentScrollable();
        }}
      >
        <IconCloseButton size={20} />
      </button>

      <h2 className='text-center mb-4'>{t('static:Login')}</h2>
      {errorMessage && <Alert variant='danger_small'>{t(`static:${errorMessage}`)}</Alert>}
      <form onSubmit={handleSubmit} className={classes.loginForm}>
        <div className={classes.inputContainer}>
          <label>{t('static:EmailOrUserName')} </label>
          <br />
          <input className={classes.emailInput} type='text' ref={emailRef} required /> <br />
        </div>

        <div className={classes.inputContainer}>
          <label> {t('static:Password')} </label>
          <br />
          <input className={classes.passwordInput} type={passwordShow} ref={passwordRef} required />
          <input
            type='checkbox'
            id='checkboxShowPassword'
            name='showpassword'
            className={classes.showPasswordCheckBox}
            onChange={() => {
              setPasswordShown((prev) => (prev === 'password' ? 'text' : 'password'));
            }}
          />
          <label style={{ fontSize: '14px', marginLeft: '5px' }} for='showpassword'>
            {t('static:ShowPassword')}
          </label>
        </div>

        <button disabled={loading} className={classes.loginButton} type='submit'>
          {t('static:LogIn')}
        </button>
      </form>
      <div className='w-100 text-center mt-2'>
        <button
          type='button'
          className={classes.forgotPasswordButton}
          onClick={() => {
            setForgetPasswordPage(true);
          }}
        >
          {t('static:ForgotPassword')}
        </button>
      </div>
    </div>
  );
}
