import { all } from 'redux-saga/effects';
import { signUpWatcher } from './SignUp/SignUpSaga';
import { loginWatcher } from './User/UserSaga';
import { goodsWatcher } from './Goods/GoodsSaga';
import { shoppingCardsWatcher } from './ShoppingCards/ShoppingCardsSaga';
import { AssetsWatcher } from './Assets/AssetsSaga';
import { CatalogsWatcher } from './Catalog/CatalogSaga';

export function* rootSaga() {
  yield all([
    signUpWatcher(),
    loginWatcher(),
    goodsWatcher(),
    shoppingCardsWatcher(),
    AssetsWatcher(),
    CatalogsWatcher(),
  ]);
}
