import React from 'react';

export function IconCloseButton({ size }) {
  return (
    <svg height={size} viewBox='0 0 20 20' width={size} xmlns='http://www.w3.org/2000/svg'>
      <path
        d='m3.21878052 2.15447998 6.77800941 6.77296995 6.70589147-6.70562452c.4571239-.40725021.9312575-.164254 1.119218.04076309s.2952881.68858337-.0437248 1.03473006l-6.7073847 6.70413134 6.7073847 6.7012315c.2983025.2502605.2983025.740678.058831 1.0138713-.2394714.2731934-.6794433.4318543-1.095459.1078797l-6.74475667-6.7489825-6.75318336 6.751668c-.29525757.2648468-.78311404.2110901-1.02482605-.052475-.24171202-.263565-.33345032-.7197265-.02436583-1.0416067l6.72837524-6.7315863-6.70096452-6.70413134c-.24453049-.2708293-.32992554-.76467162 0-1.07549315.32992553-.31082154.82184524-.26744919.99695511-.06734543z'
        fill='#555'
      />
    </svg>
  );
}
