import React from 'react';

export function IconSearch({ size }) {
  return (
    <svg
      id='iconSearch'
      viewBox='0 0 64 64'
      style={{ fill: 'none', width: `${size}px`, height: `${size}px` }}
      stroke='#000000'
    >
      <title>IconSearch</title>
      <circle cx='28' cy='28' r='20' />
      <line x1='56' y1='56' x2='42.14' y2='42.14' />
    </svg>
  );
}
