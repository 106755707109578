import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import placeholder from '../../../../assets/placeholder.webp';

import classes from './SmallSubCategoryCard.module.scss';

export function SmallSubCategoryCard({
  setCatalogButtonStyle,
  subCategory,
  category,
  debounceHideDropDown,
  hideDropDownMenu,
}) {
  const { t } = useTranslation();
  const subCategoryPath = subCategory ? subCategory?.title.split(' ').join('') : 'allgoods';

  return (
    <div className={classes.link_wrapper}>
      <Link
        to={`/catalog/${category}/${subCategoryPath}`}
        className={classes.link_wrapper}
        onClick={(e) => {
          debounceHideDropDown();
          setCatalogButtonStyle('catalog_button');
          hideDropDownMenu(e);
        }}
      >
        <div className={classes.subcategorycard_container}>
          <div className={classes.image}>
            <img
              className={classes.subCategoryImage}
              src={subCategory.previewImage || placeholder}
              alt='subCategoryPreview'
            />
          </div>
          <div className={classes.title}>
            <span className={classes.title_span}>
              {subCategory._id === 'allgoods'
                ? t('static:allGoodsOfCatalog')
                : t(`subcategories:${subCategory._id}:title`)}
            </span>
          </div>
        </div>
      </Link>
    </div>
  );
}
