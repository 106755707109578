import { put, call, takeEvery } from 'redux-saga/effects';
import { GET_CATALOGS } from './CatalogAT';
import { setCatalogsAC } from './CatalogAC';
import { fetchCatalogs } from '../../services/Catalog/fetchCatalogs';

export function* CatalogSagaWorker({ payload }) {
  try {
    const catalogs = yield call(fetchCatalogs);
    yield put(setCatalogsAC(catalogs.data));
  } catch (error) {
    console.log(error, 'error in CatalogSagaWorker');
  }
}

export function* CatalogsWatcher() {
  yield takeEvery(GET_CATALOGS, CatalogSagaWorker);
}
