import React from 'react';

export function IconBackArrow({ size }) {
  return (
    <svg id='iconBackArrow' viewBox='0 0 48 48' style={{ width: `${size}px`, height: `${size}px` }}>
      <title>BackArrow</title>
      <g id='Layer_2' data-name='Layer 2'>
        <g id='invisible_box' data-name='invisible box'>
          <rect width='48' height='48' fill='none' />
        </g>
        <g id='Q3_icons' data-name='Q3 icons'>
          <path d='M16.8,14.2v7.4l3.6.3c7.4.7,13.1,3.7,16.7,7.5C33,28,27.8,27.3,21,27l-4.2-.2v7.1L7,24.1l9.8-9.9M19.7,6a.9.9,0,0,0-.8.4L2.4,23.1a1.5,1.5,0,0,0,0,2L18.9,41.6a.9.9,0,0,0,.8.4,1.2,1.2,0,0,0,1.1-1.3V31c15.7.7,21.1,3.8,23.5,9.2.4.8.8,1.1,1.1,1.1s.6-.4.6-1c-.2-10.5-10-20.9-25.2-22.4V7.3A1.2,1.2,0,0,0,19.7,6Z' />
        </g>
      </g>
    </svg>
  );
}
