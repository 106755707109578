import React from 'react';

import classes from './LoadingPage.module.scss';

export function LoadingPage({ setIsLoading, styleName }) {
  return (
    <div
      className={classes[styleName]}
      onClick={() => {
        setIsLoading(false);
      }}
    >
      <div id='loading-bar-spinner' className={classes.spinner}>
        <div className={classes.spinnerIcon}></div>
      </div>
    </div>
  );
}
