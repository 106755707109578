import { GET_CATALOGS, SET_CATALOGS, SET_ONE_CATALOG } from './CatalogAT';

const initialState = {
  catalogs: [],
};

export const CatalogReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATALOGS:
      return state;
    case SET_CATALOGS:
      return { ...state, catalogs: action.payload };
    case SET_ONE_CATALOG:
      return {
        ...state,
        catalogs: state.catalogs.map((catalog) => {
          if (catalog._id === action.payload._id) return action.payload;
          return catalog;
        }),
      };
    default:
      return state;
  }
};
