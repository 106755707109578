import React from 'react';

export function IconBurger() {
  return (
    <svg id='iconBurger' viewBox='0 0 24 24' style={{ fill: 'none', width: '30px', height: '30px' }}>
      <title>IconBurger</title>
      <path d='M5 12H20' stroke='#000000' strokeWidth='2' strokeLinecap='round' />
      <path d='M5 17H20' stroke='#000000' strokeWidth='2' strokeLinecap='round' />
      <path d='M5 7H20' stroke='#000000' strokeWidth='2' strokeLinecap='round' />
    </svg>
  );
}
