import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Alert } from '../Alert/Alert';
import { uploadImage } from '../../services/UploadImage/uploadImage';
import { addMainImage } from '../../services/MainPageImg/addMainImage';
import { getMainPageImageAC } from '../../store/Assets/AssetsAC';

import classes from './ImageMainPage.module.scss';

export default function FormToAddMainImage({ setFormToAddImage }) {
  const dispatch = useDispatch();
  const { acessToken } = useSelector((state) => state.UserReducer);
  const [error, setError] = useState('');
  const imageRef = useRef();

  async function handleAddMainImg({ imageFile }) {
    const image = await uploadImage({
      image: imageFile,
      containerName: 'babyapp',
      token: acessToken,
    }).catch((err) => {
      setError(() => `${err.message} Image was not added. Reload the page or call administrator`);
    });

    await addMainImage(image, acessToken);
    dispatch(getMainPageImageAC());

    setFormToAddImage((prev) => !prev);
  }

  return (
    <form
      className={classes.formToAddImage}
      enctype='multipart/form-data'
      onSubmit={(e) => {
        e.preventDefault();
        imageRef.current.files[0] && handleAddMainImg({ imageFile: imageRef.current.files[0] });
      }}
    >
      {error && (
        <Alert key='danger' variant='danger_small'>
          {error}
        </Alert>
      )}
      <div controlId='formFile'>
        <label for='chosePage'>Выбрать картинку для главной страницы</label>
        <input name='chosePage' type='file' placeholder='Выбрать картинку' ref={imageRef} />
      </div>
      <div className={classes.buttonsContainer}>
        <button className={classes.confirmButton} type='submit'>
          Подтвердить
        </button>
        <button
          className={classes.cancelButton}
          type='button'
          onClick={() => setFormToAddImage((prev) => !prev)}
        >
          Отменить
        </button>
      </div>
    </form>
  );
}
