import { axiosPrivate } from '../../api/axiosPrivate';

export async function uploadImage({ image, containerName, token }) {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };
  const imageObj = await axiosPrivate.post('/api/uploadimage', { image, containerName }, config);

  return imageObj ? imageObj.data : '';
}
