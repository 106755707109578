// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oh1biAWnuOry7wnd62YV{display:flex;flex-direction:row;position:relative;justify-content:space-between;right:0;background-color:#789ed6}.oh1biAWnuOry7wnd62YV .okEIPCMrSExBxOig1j9M{padding:0;margin:0px 3px 0px 0px;cursor:pointer}.oh1biAWnuOry7wnd62YV .FF97ZlJ1r9V2eAL2zuvH{padding:0;margin:0;cursor:pointer}.oh1biAWnuOry7wnd62YV .eoYvETDJUK0FSKm3P44A{border:none;background:none;cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/components/NavBarPanel/AdminButtonsOuterCategory/AdminButtonsOuterCategory.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,6BAAA,CACA,OAAA,CACA,wBAAA,CAEA,4CACE,SAAA,CACA,sBAAA,CACA,cAAA,CAGF,4CACE,SAAA,CACA,QAAA,CACA,cAAA,CAGF,4CACE,WAAA,CACA,eAAA,CACA,cAAA","sourcesContent":[".admin_buttons {\n  display: flex;\n  flex-direction: row;\n  position: relative;\n  justify-content: space-between;\n  right: 0;\n  background-color: rgb(120, 158, 214);\n\n  .change_category_button {\n    padding: 0;\n    margin: 0px 3px 0px 0px;\n    cursor: pointer;\n  }\n\n  .add_catalog_button {\n    padding: 0;\n    margin: 0;\n    cursor: pointer;\n  }\n\n  .closeButton{\n    border:none;\n    background: none;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"admin_buttons": `oh1biAWnuOry7wnd62YV`,
	"change_category_button": `okEIPCMrSExBxOig1j9M`,
	"add_catalog_button": `FF97ZlJ1r9V2eAL2zuvH`,
	"closeButton": `eoYvETDJUK0FSKm3P44A`
};
export default ___CSS_LOADER_EXPORT___;
