import React from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from '../ErrorPage/ErrorPage';

export function ReactErrorBoundary(props) {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onError={(error, errorInfo) => {
        console.error(error);
      }}
      onReset={() => {
        window.location.reload();
      }}
    >
      {props.children}
    </ErrorBoundary>
  );
}
