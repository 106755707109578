import debounce from 'lodash/debounce';
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { changeCategoryList } from '../../../services/Categories/changeCategoryList';
import { synhronizeGoodsByCategory } from '../../../services/Goods/synhronizeGoodsByCategory';
import { getCategoriesListAC } from '../../../store/Goods/GoodsAC';
import { SubCategoriesDropDown } from '../SubCategoriesDropDown/SubCategoriesDropDown';
import { AdminPanelButtons } from '../AdminPanelButtons/AdminPanelButtons';
import { hasSubCategories } from '../../../helpers/hasSubcategories';
import { getReorderedList } from '../../../helpers/getReorderedList';
import { IconLeftArrow } from '../../Icons/IconLeftArrow';

import classes from './DropDownPanel.module.scss';

export function DropDownPanel({
  setCatalogButtonStyle,
  setIsHoverCatalog,
  showChangeCategorySectionForm,
  showFormAddCategory,
  showAskFullDeletionForm,
  setCategoryTarget,
  showAddSubCategoryForm,
  showDropDownPanel,
  setCurrentOuterCategory,
  hideDropDownMenu,
  currentOuterCategory,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { Role, acessToken } = useSelector((state) => state.UserReducer);
  const { categoriesList: outerCategoriesList } = useSelector((state) => state.GoodsReducer);
  const { catalogs } = useSelector((state) => state.CatalogReducer);

  const [subCategoriesDropDown, showSubCategoriesDropDown] = useState(false);
  const [categoryForSubCategory, setCategoryForSubCategory] = useState('');
  const [categoriesList, setCategoriesList] = useState([]);
  const [arrowColor, setArrowColor] = useState('rgb(231, 121, 121)');

  useEffect(() => {
    const outerCategory = outerCategoriesList.find(
      (outerCategoryObj) => outerCategoryObj.outerCategory === currentOuterCategory,
    );

    outerCategory && setCategoriesList(outerCategory.categories);
  }, [currentOuterCategory, outerCategoriesList]);

  async function handleOnDragEnd(e, changeOrder) {
    const destination = e.destination.index;
    const source = e.source.index;

    // dispatch(setCategoriesListAC(newOrderArr));

    const newOrderArr = getReorderedList(destination, source, categoriesList);

    await changeOrder({
      outerCategory: currentOuterCategory,
      newCategoryList: newOrderArr,
      token: acessToken,
    });
    dispatch(getCategoriesListAC());
  }

  const timeOutId = useRef();
  const debounceShowDropDown = debounce(() => {
    showSubCategoriesDropDown(true);
  }, 500);

  const debounceHideDropDown = debounce(() => {
    showSubCategoriesDropDown(false);
  }, 500);

  function debounceDropDownShowCustom(category) {
    timeOutId.current = setTimeout(() => {
      setCategoryForSubCategory(category);
      showSubCategoriesDropDown(true);
    }, 300);
  }

  return (
    <nav
      onMouseEnter={() => {
        setIsHoverCatalog(true);
        setCatalogButtonStyle('catalog_button_partly_hovered');
      }}
      onMouseLeave={() => {
        setIsHoverCatalog(false);
        setCatalogButtonStyle('catalog_button');
      }}
      className={classes.dropdown}
    >
      <button
        type='button'
        className={classes.backButton}
        onClick={() => {
          showDropDownPanel(false);
          setCurrentOuterCategory('');
        }}
        onMouseEnter={() => setArrowColor('#ee763c')}
        onMouseLeave={() => setArrowColor('white')}
      >
        <IconLeftArrow size={25} color={arrowColor} />
      </button>

      {subCategoriesDropDown && ( // subCategoriesDropDown
        <SubCategoriesDropDown
          setCatalogButtonStyle={setCatalogButtonStyle}
          debounceShowDropDown={debounceShowDropDown}
          categoryForSubCategory={categoryForSubCategory}
          debounceHideDropDown={debounceHideDropDown}
          hideDropDownMenu={hideDropDownMenu}
        />
      )}
      <DragDropContext
        onDragEnd={(e) => {
          handleOnDragEnd(e, changeCategoryList);
        }}
      >
        <Droppable droppableId='goodsListDroppable' style={{ padding: '0' }}>
          {(provided) => (
            <div className={classes.linkSectionWrapper} {...provided.droppableProps} ref={provided.innerRef}>
              {Role === 'ADMIN'
                ? categoriesList.map((category, index) => (
                    <Draggable key={category} draggableId={category} index={index}>
                      {(provided) => (
                        <div
                          className={classes.link_wrapper_admin_panel}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          onMouseEnter={(e) => {
                            if (hasSubCategories(category, catalogs)) {
                              debounceDropDownShowCustom(category);
                              debounceHideDropDown.cancel();
                            }
                          }}
                          onMouseLeave={(e) => {
                            clearTimeout(timeOutId.current);
                            debounceHideDropDown();
                          }}
                        >
                          {hasSubCategories(category, catalogs) ? (
                            <>
                              <Link
                                key={category}
                                to={`outercategory/${currentOuterCategory}/${category}`}
                                className={classes.link_text}
                              >
                                {t(`category:${category}`)}
                              </Link>

                              {Role === 'ADMIN' && (
                                <AdminPanelButtons
                                  showChangeCategorySectionForm={showChangeCategorySectionForm}
                                  setCategoryTarget={setCategoryTarget}
                                  setCurrentOuterCategory={setCurrentOuterCategory}
                                  showAddSubCategoryForm={showAddSubCategoryForm}
                                  showAskFullDeletionForm={showAskFullDeletionForm}
                                  category={category}
                                  currentOuterCategory={currentOuterCategory}
                                  hasSubCategories
                                />
                              )}
                            </>
                          ) : (
                            <>
                              <Link
                                key={category}
                                to={`outercategory/${currentOuterCategory}/${category}/allgoods`}
                                className={classes.link_text}
                              >
                                {t(`category:${category}`)}
                              </Link>

                              {Role === 'ADMIN' && (
                                <AdminPanelButtons
                                  showChangeCategorySectionForm={showChangeCategorySectionForm}
                                  setCategoryTarget={setCategoryTarget}
                                  showAddSubCategoryForm={showAddSubCategoryForm}
                                  setCurrentOuterCategory={setCurrentOuterCategory}
                                  showAskFullDeletionForm={showAskFullDeletionForm}
                                  currentOuterCategory={currentOuterCategory}
                                  category={category}
                                />
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))
                : categoriesList &&
                  categoriesList.map((category) =>
                    hasSubCategories(category, catalogs) ? (
                      <div
                        className={classes.link_wrapper}
                        itemscope
                        itemtype='https://schema.org/Product'
                        onMouseEnter={() => {
                          debounceDropDownShowCustom(category);
                          debounceHideDropDown.cancel();
                        }}
                        onMouseLeave={() => {
                          clearTimeout(timeOutId.current);
                          debounceHideDropDown();
                        }}
                        key={category}
                      >
                        <Link
                          key={category}
                          itemProp='url'
                          to={`outercategory/${currentOuterCategory}/${category}`}
                          className={classes.link_text}
                          onClick={() => {
                            setIsHoverCatalog(false);
                            setCatalogButtonStyle('catalog_button');
                          }}
                        >
                          <span itemProp='name'>{t(`category:${category}`)}</span>
                        </Link>
                      </div>
                    ) : (
                      <div
                        itemscope
                        itemtype='https://schema.org/Product'
                        className={classes.link_wrapper}
                        key={category}
                      >
                        <Link
                          key={category}
                          itemProp='url'
                          to={`outercategory/${currentOuterCategory}/${category}/allgoods`}
                          className={classes.link_text}
                          onClick={() => {
                            setIsHoverCatalog(false);
                            setCatalogButtonStyle('catalog_button');
                          }}
                        >
                          <span itemProp='name'>{t(`category:${category}`)}</span>
                        </Link>
                      </div>
                    ),
                  )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {Role === 'ADMIN' && (
        <>
          <button
            className={classes.addCategoryButton}
            onClick={() => {
              showFormAddCategory();
            }}
          >
            Add category
          </button>

          <button
            className={classes.synhronizeGoodsButton}
            onClick={() => {
              synhronizeGoodsByCategory(acessToken);
            }}
          >
            Synhronize goods
          </button>
        </>
      )}
    </nav>
  );
}
