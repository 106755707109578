import { put, call, takeEvery } from 'redux-saga/effects';
import { setMainPageImageAC } from './AssetsAC';
import { fetchMainPageImage } from '../../services/MainPageImg/fetchMainPageImage';
import { GET_MAIN_PAGE_IMAGE } from './AssetsAT';

export function* getMainPageImageWorker({ payload }) {
  try {
    const imageLink = yield call(fetchMainPageImage);
    yield put(setMainPageImageAC(imageLink.data));
  } catch (error) {
    console.log(error, 'error in getMainPageImage saga fun');
  }
}

export function* AssetsWatcher() {
  yield takeEvery(GET_MAIN_PAGE_IMAGE, getMainPageImageWorker);
}
