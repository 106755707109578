import { put, call, takeEvery } from 'redux-saga/effects';
import { setCategoriesListAC, setGoodsArraysStoreAC } from './GoodsAC';
import { GET_CATEGORIES_LIST, GET_GOODS_ARRAYS } from './GoodsAT';
import { fetchGoodsList } from '../../services/Categories/fetchGoodsList';
import { fetchGoodsArrays } from '../../services/fetchGoodsArrays';

export function* getGoodsListWorker() {
  try {
    const goodsList = yield call(fetchGoodsList);
    yield put(setCategoriesListAC(goodsList.data.outerCategories));
  } catch (error) {
    console.log(error, 'error in getGoodsList Worker');
  }
}

export function* getGoodsArrays() {
  try {
    const goodsArrays = yield call(fetchGoodsArrays);
    yield put(setGoodsArraysStoreAC(goodsArrays.data));
  } catch (error) {
    console.log(error, 'error in getGoodsArrays Worker');
  }
}

export function* goodsWatcher() {
  yield takeEvery(GET_CATEGORIES_LIST, getGoodsListWorker);
  yield takeEvery(GET_GOODS_ARRAYS, getGoodsArrays);
}
