import React from 'react';

export function IconBasketCancel({ size, color }) {
  return (
    <svg
      version='1.0'
      id='iconBasketCancel'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 64 64'
      xmlSpace='preserve'
      style={{ width: `${size}`, height: `${size}` }}
    >
      <title>IconBasketCancel</title>
      <polygon
        fill='none'
        stroke='#cb3b34'
        strokeWidth='2'
        strokeMiterlimit='10'
        points='1,25 12,59 52,59 63,25 '
      />
      <line
        fill='none'
        stroke='#cb3b34'
        strokeWidth='2'
        strokeMiterlimit='10'
        x1='14'
        y1='25'
        x2='22'
        y2='5'
      />
      <line
        fill='none'
        stroke='#cb3b34'
        strokeWidth='2'
        strokeMiterlimit='10'
        x1='50'
        y1='25'
        x2='42'
        y2='5'
      />
      <line
        fill='none'
        stroke='#cb3b34'
        strokeWidth='2'
        strokeMiterlimit='10'
        x1='39'
        y1='50'
        x2='25'
        y2='36'
      />
      <line
        fill='none'
        stroke='#cb3b34'
        strokeWidth='2'
        strokeMiterlimit='10'
        x1='25'
        y1='50'
        x2='39'
        y2='36'
      />
    </svg>
  );
}
