import React from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { logoutRequest } from '../../services/Auth/logout/logout';

import classes from './DropDownLogout.module.scss';

export function DropDownLogout({ showDropDownLogout, hideDropDownLogout }) {
  const navigate = useNavigate();

  return (
    <div
      className={classes.DropDownLogout}
      onMouseEnter={showDropDownLogout}
      onMouseLeave={hideDropDownLogout}
    >
      <Link to='/userpage'>
        <span> Кабинет</span>
      </Link>
      <Link to='/userpage'>
        <span>Корзина</span>
      </Link>
      <Link
        onClick={() => {
          logoutRequest();
          navigate('/');
        }}
      >
        Logout
      </Link>
    </div>
  );
}
