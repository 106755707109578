import React from 'react';
import { Card } from '../Card/Card';

export function Cards({
  subCategories,
  currentOuterCategory,
  category,
  hideSmallDropDownContainer,
  makeMainContentScrollable,
}) {
  return subCategories.map((subCategory) => (
    <Card
      key={subCategory.title}
      currentOuterCategory={currentOuterCategory}
      subCategory={subCategory}
      makeMainContentScrollable={makeMainContentScrollable}
      hideSmallDropDownContainer={hideSmallDropDownContainer}
      category={category}
    />
  ));
}
