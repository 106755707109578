import React from 'react';
import { v4 as uuidv4 } from 'uuid';

export function IconSmallArrowRight({ color, size }) {
  const uniqueId = uuidv4();

  return (
    <svg id={uniqueId} viewBox='0 0 24 24' style={{ color, width: `${size}px`, height: `${size}px` }}>
      <title>SmallArrowRight</title>

      <path
        d='M16.1359 18.2928C16.5264 18.6833 17.1596 18.6833 17.5501 18.2928L22.4375 13.4006C23.2179 12.6194 23.2176 11.3536 22.4369 10.5728L17.5465 5.68247C17.156 5.29195 16.5228 5.29195 16.1323 5.68247C15.7418 6.073 15.7418 6.70616 16.1323 7.09669L20.3179 11.2823C20.7085 11.6729 20.7085 12.306 20.3179 12.6965L16.1359 16.8786C15.7454 17.2691 15.7454 17.9023 16.1359 18.2928Z'
        fill={color}
      />
    </svg>
  );
}
