import { SIGN_UP_STATE, SET_SIGNED } from './SignUpAT';

const initialState = {
  isSigned: false,
  isEmailConfirmationSent: false,
};

export const SignUpReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_UP_STATE:
      return {
        ...state,
        isEmailConfirmationSent: true,
      };
    case SET_SIGNED:
      return {
        ...state,
        isSigned: true,
      };
    default:
      return state;
  }
};
