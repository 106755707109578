import React from 'react';

export function IconChevronDown({ size, color }) {
  return (
    <svg
      id='iconChevronDown'
      viewBox='0 0 24 24'
      stroke={color}
      strokeWidth='1.5'
      strokeLinejoin='round'
      style={{ width: `${size}px`, height: `${size}px`, fill: 'none' }}
    >
      <title>ChevronDown</title>
      <path d='M11.29,17.41,3,9.12,5.83,6.29,12,12.46l6.17-6.17L21,9.11l-8.29,8.3A1,1,0,0,1,11.29,17.41Z' />
    </svg>
  );
}
