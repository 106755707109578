import { SIGN_UP_STATE, SET_SIGNED } from './SignUpAT';

export const isSignedUpStateChange = (userInfo) => ({
  type: SIGN_UP_STATE,
  payload: userInfo,
});

export const setSignedAC = (payload) => ({
  type: SET_SIGNED,
  payload,
});
