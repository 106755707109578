import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconSmallArrowRight } from '../../../../Icons/IconSmallArrowRight';

import classes from './NoOuterCategorySmallContainer.module.scss';

export function NoOuterCategorySmallContainer({
  showSubSmallDropDown,
  categoriesInsideOuter,
  setCategoryForSubCategory,
  setOuterCategorySmallContainer,
  hideSmallDropDownContainer,
  makeMainContentScrollable,
}) {
  const { t } = useTranslation();

  const { catalogs } = useSelector((state) => state.CatalogReducer);

  const [currentCatalogs, setCurrentCatalogs] = useState([]);

  useEffect(() => {
    const filteredCatalogs = catalogs.filter((catalog) => categoriesInsideOuter.includes(catalog.category));
    setCurrentCatalogs(filteredCatalogs);
  }, [catalogs, categoriesInsideOuter]);

  return (
    <div className={classes.NoOuterCategorySmallContainer}>
      {currentCatalogs.map((catalog) =>
        catalog.subCategories.length ? (
          <div className={classes.catalogWithSubcategories} key={catalog.category}>
            <Link
              to={`catalog/${catalog.category}`}
              onClick={() => {
                hideSmallDropDownContainer();
                makeMainContentScrollable();
              }}
            >
              {t(`category:${catalog.category}`)}
            </Link>
            <button
              type='button'
              className={classes.right_arrow_button}
              onClick={() => {
                showSubSmallDropDown();
                setOuterCategorySmallContainer(false);
                setCategoryForSubCategory(catalog.category);
              }}
            >
              <IconSmallArrowRight size={30} />
            </button>
          </div>
        ) : (
          <div className={classes.noSubcategories} key={catalog.category}>
            <Link
              to={`goods/${catalog.category}`}
              onClick={() => {
                hideSmallDropDownContainer();
                makeMainContentScrollable();
              }}
            >
              {t(`category:${catalog.category}`)}
            </Link>
          </div>
        ),
      )}
    </div>
  );
}
