import React from 'react';
import { Link } from 'react-router-dom';

import { IconRefresh } from '../../Icons/IconRefresh';
import { IconPlusSign } from '../../Icons/IconPlusSign';
import { IconCloseButton } from '../../Icons/IconCloseButton';

import classes from './AdminPanelButtons.module.scss';

export function AdminPanelButtons({
  showChangeCategorySectionForm,
  setCategoryTarget,
  showAddSubCategoryForm,
  showAskFullDeletionForm,
  category,
  setCurrentOuterCategory,
  currentOuterCategory,
  hasSubCategories,
}) {
  return (
    <div className={classes.admin_buttons}>
      <Link
        className={classes.change_category_button}
        onClick={async (e) => {
          showChangeCategorySectionForm();
          setCategoryTarget(category);
          setCurrentOuterCategory(currentOuterCategory);
        }}
      >
        <IconRefresh size={20} />
      </Link>
      {!hasSubCategories && (
        <Link
          className={classes.add_catalog_button}
          onClick={async () => {
            setCategoryTarget(category);
            showAddSubCategoryForm();
          }}
        >
          <IconPlusSign size={20} />
        </Link>
      )}

      <button
        className={classes.closeButton}
        id={category}
        onClick={(e) => {
          showAskFullDeletionForm();
          setCategoryTarget(category);
          setCurrentOuterCategory(currentOuterCategory);
        }}
      >
        <IconCloseButton size={15} />
      </button>
    </div>
  );
}
