import React, { useEffect, useState } from 'react';

import classes from './OverlayTrigger.module.scss';

export function OverlayTrigger({ children, overlay, show, hide }) {
  const [overlayComp, setOverlayComp] = useState(null);
  const [isShown, setIsShow] = useState(false);
  let timerId;

  return (
    <div className={classes.outerContainer}>
      {isShown && overlayComp}

      <div
        className={classes.container}
        onMouseOver={() => {
          timerId = setTimeout(() => {
            setIsShow(true);
            setOverlayComp(overlay());
          }, show);
        }}
        onMouseOut={() => {
          if (isShown) {
            setTimeout(() => {
              setIsShow(false);
            }, hide);
          }
          clearTimeout(timerId);
        }}
      >
        {children}
      </div>
    </div>
  );
}
