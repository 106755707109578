import React, { useState } from 'react';

import { SmallDropDown } from '../SmallDropDown/SmallDropDown';
import { SmallCatalogsDropDown } from '../SmallCatalogsDropDown/SmallCatalogsDropDown';
import { SmallSubCategoriesDropDown } from '../SmallSubCategoriesDropDown/SmallSubCategoriesDropDown';
import { SmallOuterCategoriesDropDown } from '../DropDownPanel/SmallOuterCategoriesDropDown/SmallOuterCategoriesDropDown';

import classes from './SmallDropDownContainer.module.scss';

export function SmallDropDownContainer({
  makeMainContentUnscrollable,
  makeMainContentScrollable,
  showCallBackForm,
  setAuthTableState,
  langButtonState,
  setLangButtonState,
  changeLanguage,
  hideSmallDropDownContainer,
}) {
  const [isMainSmallDropDownShown, setMainSmallDropDown] = useState(true);
  const [isCatalogSmallDropDownShown, setSmallCatalogDropDown] = useState(false);
  const [isSubSmallDropDownShown, setSubsSmallDropDown] = useState(false);
  const [isOuterCategorySmallContainerShown, setOuterCategorySmallContainer] = useState(false);
  const [categoryForSubCategory, setCategoryForSubCategory] = useState('');
  const [outerCatalogCategories, setOuterCatalogCategories] = useState([]);
  const [currentOuterCategory, setCurrentOuterCategory] = useState('');

  function showMainSmallDropDown() {
    setMainSmallDropDown(true);
  }

  function hideMainSmallDropDown() {
    setMainSmallDropDown(false);
  }

  function showSmallCatalogDropDown() {
    setSmallCatalogDropDown(true);
  }

  function hideSmallCatalogDropDown() {
    setSmallCatalogDropDown(false);
  }

  function showSubSmallDropDown() {
    setSubsSmallDropDown(true);
  }

  function hideSubSmallDropDown() {
    setSubsSmallDropDown(false);
  }

  return (
    <div
      className={classes.small_dropdown_container}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {isMainSmallDropDownShown && (
        <SmallDropDown
          makeMainContentUnscrollable={makeMainContentUnscrollable}
          makeMainContentScrollable={makeMainContentScrollable}
          showCallBackForm={showCallBackForm}
          langButtonState={langButtonState}
          setLangButtonState={setLangButtonState}
          changeLanguage={changeLanguage}
          hideSmallDropDownContainer={hideSmallDropDownContainer}
          showSmallCatalogDropDown={showSmallCatalogDropDown}
          hideMainSmallDropDown={hideMainSmallDropDown}
          setAuthTableState={setAuthTableState}
          setOuterCategorySmallContainer={setOuterCategorySmallContainer}
        />
      )}

      {isOuterCategorySmallContainerShown && (
        <SmallOuterCategoriesDropDown
          showSmallCatalogDropDown={showSmallCatalogDropDown}
          hideSmallDropDownContainer={hideSmallDropDownContainer}
          setOuterCategorySmallContainer={setOuterCategorySmallContainer}
          setOuterCatalogCategories={setOuterCatalogCategories}
          showMainSmallDropDown={showMainSmallDropDown}
          showSubSmallDropDown={showSubSmallDropDown}
          setCategoryForSubCategory={setCategoryForSubCategory}
          setCurrentOuterCategory={setCurrentOuterCategory}
          makeMainContentScrollable={makeMainContentScrollable}
        />
      )}

      {isCatalogSmallDropDownShown && (
        <SmallCatalogsDropDown
          outerCatalogCategories={outerCatalogCategories}
          makeMainContentScrollable={makeMainContentScrollable}
          hideSmallDropDownContainer={hideSmallDropDownContainer}
          hideSmallCatalogDropDown={hideSmallCatalogDropDown}
          showMainSmallDropDown={showMainSmallDropDown}
          setCategoryForSubCategory={setCategoryForSubCategory}
          showSubSmallDropDown={showSubSmallDropDown}
          setOuterCategorySmallContainer={setOuterCategorySmallContainer}
          currentOuterCategory={currentOuterCategory}
        />
      )}

      {isSubSmallDropDownShown && (
        <SmallSubCategoriesDropDown
          makeMainContentScrollable={makeMainContentScrollable}
          categoryForSubCategory={categoryForSubCategory}
          showSmallCatalogDropDown={showSmallCatalogDropDown}
          setOuterCategorySmallContainer={setOuterCategorySmallContainer}
          hideSubSmallDropDown={hideSubSmallDropDown}
          hideSmallDropDownContainer={hideSmallDropDownContainer}
        />
      )}
    </div>
  );
}
