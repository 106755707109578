export function getReorderedList(destination, source, arr) {
  let newOrderArr = [];

  if (destination < source) {
    for (let i = 0; i < arr.length; i++) {
      if (destination === i) {
        newOrderArr.push(arr[source]);
      }
      if (source === i) {
        continue;
      }
      newOrderArr.push(arr[i]);
    }
  }

  if (destination > source) {
    for (let i = 0; i < arr.length; i++) {
      if (source === i) {
        continue;
      }
      if (destination === i) {
        newOrderArr.push(arr[i]);
        newOrderArr.push(arr[source]);
        continue;
      }
      newOrderArr.push(arr[i]);
    }
  }

  if (destination === source) newOrderArr = arr;
  return newOrderArr;
}
