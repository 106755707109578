// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._WHo8zaYS0XYbv8iqK6w{width:300px;height:450px;border-radius:5px;background-color:#fff}._WHo8zaYS0XYbv8iqK6w .fUDUsej8Yl_V7uWOx52r{position:relative;width:260px;height:369px;background-color:#c5c5c5;margin-left:auto;margin-right:auto;margin-top:20px}`, "",{"version":3,"sources":["webpack://./src/components/CatalogCardsContainer/CatalogCardPlaceholder/CatalogCardPlaceholder.module.scss"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,YAAA,CACA,iBAAA,CACA,qBAAA,CAEA,4CACI,iBAAA,CACA,WAAA,CACA,YAAA,CACA,wBAAA,CACA,gBAAA,CACA,iBAAA,CACA,eAAA","sourcesContent":[".catalogCardPlaceholder{\n    width: 300px;\n    height: 450px;\n    border-radius: 5px;\n    background-color: white;\n\n    .imagePlaceholder{\n        position: relative;\n        width: 260px;\n        height: 369px;\n        background-color: #C5C5C5;\n        margin-left: auto;\n        margin-right: auto;\n        margin-top: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"catalogCardPlaceholder": `_WHo8zaYS0XYbv8iqK6w`,
	"imagePlaceholder": `fUDUsej8Yl_V7uWOx52r`
};
export default ___CSS_LOADER_EXPORT___;
