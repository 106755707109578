import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Alert } from '../../Alert/Alert';
import { useWindowDimensions } from '../../../helpers/useWindowDimension';
import { setIsErrorAC, setErrorObjAC } from '../../../store/Error/ErrorAC';
import { signUpRequest } from '../../../services/signUpRequest';
import { IconCloseButton } from '../../Icons/IconCloseButton';

import classes from './SignUp.module.scss';

export function SignUp({ setAuthTableState, makeMainContentScrollable }) {
  const { t } = useTranslation();

  const { width } = useWindowDimensions();

  const dispatch = useDispatch();
  const { isError, errorObj } = useSelector((state) => state.ErrorReducer);
  const { errorMessage } = errorObj;

  const [passwordShow, setPasswordShown] = useState('password');

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState();
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(
    () => () => {
      dispatch(setIsErrorAC(false));
      dispatch(setErrorObjAC({ errorMessage: '' }));
    },
    [],
  );

  async function handleSubmit(e) {
    e.preventDefault();

    if (password !== passwordRepeat) {
      dispatch(setIsErrorAC(true));
      dispatch(setErrorObjAC({ errorMessage: 'PasswordAndRepeatPasswordShouldBeTheSame' }));
      return;
    }

    const user = {
      email,
      phone: Number(phone),
      username,
      password,
    };

    try {
      dispatch(setIsErrorAC(false));
      dispatch(setErrorObjAC({ errorMessage: '' }));
      setLoading(true);
      await signUpRequest(user);
      navigate('/checkemailmessage');
      setAuthTableState((prev) => !prev);
    } catch (error) {
      console.log('error', error);
      const errorMessage = error.response?.data?.message
        ? error.response.data.message
        : 'uknownErrorPleaseReloadThePage';
      dispatch(setIsErrorAC(true));
      dispatch(setErrorObjAC({ errorMessage }));
    }
    setLoading(false);
  }

  return (
    <div className={classes.cardbody}>
      <button
        className={classes.closeButton}
        onClick={() => {
          setAuthTableState((prev) => !prev);
          width > 799 && makeMainContentScrollable();
        }}
      >
        <IconCloseButton size={20} />
      </button>
      <h2 className='text-center mb-4'> {t('static:Signup')}</h2>
      {isError && <Alert variant='danger_small'>{t(`static:${errorMessage}`)}</Alert>}
      <form onSubmit={handleSubmit}>
        <div className={classes.inputContainer}>
          <label> {t('static:UserName')} </label>
          <br />
          <input
            className={classes.customInput}
            type='text'
            onChange={(e) => {
              setUserName(e.target.value);
            }}
            required
          />
        </div>
        <div className={classes.inputContainer}>
          <label> {t('static:Email')} </label>
          <br />
          <input
            className={classes.customInput}
            type='email'
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className={classes.inputContainer}>
          <label> {t('static:PhoneNumber')}</label>
          <br />
          <input
            className={classes.customInput}
            type='text'
            value={phone}
            onChange={(e) => {
              const newValue = e.target.value.replace(/[^\d]/g, '');
              setPhone(newValue);
            }}
            required
          />
        </div>
        <div className={classes.inputContainer}>
          <label> {t('static:Password')} </label>
          <br />
          <input
            className={classes.customInput}
            type={passwordShow}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            required
          />
          <br />
          <input
            type='checkbox'
            name='showpassword'
            className={classes.showPasswordCheckBox}
            onChange={() => {
              setPasswordShown((prev) => (prev === 'password' ? 'text' : 'password'));
            }}
          />
          <label for='showpassword' style={{ fontSize: '14px', marginLeft: '5px' }}>
            {t('static:ShowPassword')}
          </label>
        </div>
        <div className={classes.inputContainer}>
          <label> {t('static:RepeatPassword')} </label>
          <br />
          <input
            className={classes.customInput}
            type={passwordShow}
            onChange={(e) => {
              setPasswordRepeat(e.target.value);
            }}
            required
          />
        </div>
        <button disabled={loading} className={classes.signUpButton} type='submit'>
          {t('static:SignUp')}
        </button>
      </form>
    </div>
  );
}
