import React from 'react';

export function IconBasketPlus({ size }) {
  return (
    <svg
      version='1.0'
      id='iconBasketPlus'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 64 64'
      xmlSpace='preserve'
      style={{ width: `${size}px`, height: `${size}px` }}
    >
      <title>IconBasketPlus</title>
      <polygon
        fill='none'
        stroke='green'
        strokeWidth='2'
        strokeMiterlimit='10'
        points='1,25 12,59 52,59 63,25 '
      />
      <line fill='none' stroke='green' strokeWidth='2' strokeMiterlimit='10' x1='14' y1='25' x2='22' y2='5' />
      <line fill='none' stroke='green' strokeWidth='2' strokeMiterlimit='10' x1='50' y1='25' x2='42' y2='5' />
      <line
        fill='none'
        stroke='green'
        strokeWidth='2'
        strokeMiterlimit='10'
        x1='32'
        y1='34'
        x2='32'
        y2='52'
      />
      <line
        fill='none'
        stroke='green'
        strokeWidth='2'
        strokeMiterlimit='10'
        x1='41'
        y1='43'
        x2='23'
        y2='43'
      />
    </svg>
  );
}
