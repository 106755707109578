import { axiosPrivate } from '../../api/axiosPrivate';

export async function removeFullCategory({
  currentOuterCategory,
  category,
  newCategoryList,
  token,
  catalogId,
}) {
  return axiosPrivate.post(
    '/api/removefullcategorysection',
    {
      currentOuterCategory,
      category,
      newCategoryList,
      catalogId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}
