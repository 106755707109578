import { axiosPrivate } from '../../api/axiosPrivate';

export async function changeCategoryList({ outerCategory, newCategoryList, token }) {
  return axiosPrivate.post(
    '/api/changecategorylist',
    {
      outerCategory,
      newCategoryList,
    },
    { headers: { Authorization: `Bearer ${token}` } },
  );
}
