import React, { useState, useRef } from 'react';
import debounce from 'lodash/debounce';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector, useDispatch } from 'react-redux';

import { getCategoriesListAC } from '../../../../store/Goods/GoodsAC';
import { NoOuterCategoriesContainer } from '../NoOuterCategoryContainer/NoOuterCategoryContainer';
import { OuterCategoryContainer } from './OuterCategoryContainer/OuterCategoryContainer';
import { AdminPanelButtonsOuterCategory } from '../../AdminButtonsOuterCategory/AdminButtonsOuterCategory';
import { DropDownPanel } from '../DropDownPanel';
import { SubCategoriesDropDown } from '../../SubCategoriesDropDown/SubCategoriesDropDown';
import { getReorderedList } from '../../../../helpers/getReorderedList';
import { changeOuterCategoriesList } from '../../../../services/Categories/changeOuterCategories';
import { IconSmallArrowRight } from '../../../Icons/IconSmallArrowRight';
import { createSitemapRequest } from '../../../../services/SEO/createSitemap';

import classes from './DropDownOuterPanel.module.scss';

export function DropDownOuterPanel({
  setCatalogButtonStyle,
  setDeletingOuterCategoryStatus,
  setIsHoverCatalog,
  hideDropDownMenu,
  showFormAddOuterCategory,
  showAddSubCategoryForm,
  showFormAddCategory,
  synhronizeGoods,
  setCurrentOuterCategory,
  currentOuterCategory,
  showChangeCategorySectionForm,
  setCategoryTarget,
  showAskFullDeletionForm,
  setFormChangeOuterCategory,
  setSiteMap,
}) {
  const { categoriesList } = useSelector((state) => state.GoodsReducer);
  const { Role, acessToken } = useSelector((state) => state.UserReducer);

  const [subCategoriesDropDown, showSubCategoriesDropDown] = useState(false);
  const [categoryForSubCategory, setCategoryForSubCategory] = useState('');
  const [isDropDownPanelShown, showDropDownPanel] = useState(false);
  const [categoriesListDropDownPanel, setCategoriesListDropDownPanel] = useState([]);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  async function handleOnDragEnd(e) {
    const destination = e.destination.index;
    const source = e.source.index;
    const newOuterCategoriesList = getReorderedList(destination, source, categoriesList);
    await changeOuterCategoriesList({ newOuterCategoriesList, token: acessToken });
    dispatch(getCategoriesListAC());
  }

  const timeOutId = useRef();
  const debounceShowDropDown = debounce(() => {
    showSubCategoriesDropDown(true);
  }, 500);

  const debounceHideDropDown = debounce(() => {
    showSubCategoriesDropDown(false);
  }, 500);

  function debounceDropDownShowCustom(category) {
    timeOutId.current = setTimeout(() => {
      setCategoryForSubCategory(category);
      showSubCategoriesDropDown(true);
    }, 300);
  }

  return isDropDownPanelShown ? ( // isDropDownPanelShown
    <DropDownPanel
      setCatalogButtonStyle={setCatalogButtonStyle}
      categoriesList={categoriesListDropDownPanel}
      setIsHoverCatalog={setIsHoverCatalog}
      showFormAddCategory={showFormAddCategory}
      showAddSubCategoryForm={showAddSubCategoryForm}
      showDropDownPanel={showDropDownPanel}
      setCurrentOuterCategory={setCurrentOuterCategory}
      setCategoryTarget={setCategoryTarget}
      showChangeCategorySectionForm={showChangeCategorySectionForm}
      showAskFullDeletionForm={showAskFullDeletionForm}
      hideDropDownMenu={hideDropDownMenu}
      currentOuterCategory={currentOuterCategory}
    />
  ) : (
    <div
      className={classes.dropDownOuterPanel}
      onMouseEnter={() => {
        setIsHoverCatalog(true);
        setCatalogButtonStyle('catalog_button_partly_hovered');
      }}
      onMouseLeave={() => {
        setIsHoverCatalog(false);
        setCatalogButtonStyle('catalog_button');
      }}
    >
      {subCategoriesDropDown && ( // subCategoriesDropDown
        <SubCategoriesDropDown
          setCatalogButtonStyle={setCatalogButtonStyle}
          debounceShowDropDown={debounceShowDropDown}
          categoryForSubCategory={categoryForSubCategory}
          debounceHideDropDown={debounceHideDropDown}
          hideDropDownMenu={hideDropDownMenu}
        />
      )}

      {Role === 'ADMIN' ? (
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId='DropDownOuterDroppable' style={{ padding: '0' }}>
            {(provided) => (
              <div
                className={classes.linkSectionWrapper}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {categoriesList.map((outerCategoryObj, index) => (
                  <Draggable
                    key={outerCategoryObj.outerCategory}
                    draggableId={outerCategoryObj.outerCategory}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        className={classes.link_wrapper_admin_panel}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        id={outerCategoryObj.outerCategory}
                        ref={provided.innerRef}
                      >
                        {outerCategoryObj.outerCategory === 'noOuterCategory' ? (
                          <NoOuterCategoriesContainer
                            setIsHoverCatalog={setIsHoverCatalog}
                            hideDropDownMenu={hideDropDownMenu}
                            showAddSubCategoryForm={showAddSubCategoryForm}
                            showAskFullDeletionForm={showAskFullDeletionForm}
                            showChangeCategorySectionForm={showChangeCategorySectionForm}
                            setCategoryTarget={setCategoryTarget}
                            setCurrentOuterCategory={setCurrentOuterCategory}
                          />
                        ) : (
                          <div className={classes.outerCategoryAdmin}>
                            <div className={classes.outerCategory}>
                              <Link
                                key={outerCategoryObj.outerCategory}
                                to={`outercategory/${outerCategoryObj.outerCategory}`}
                              >
                                {t(`outerCategory:${outerCategoryObj.outerCategory}:title`)}
                              </Link>
                              <button
                                type='button'
                                className={classes.arrowRightButton}
                                onClick={() => {
                                  showDropDownPanel(true);
                                  setCategoriesListDropDownPanel(outerCategoryObj.categories);
                                  setCurrentOuterCategory(outerCategoryObj.outerCategory);
                                }}
                              >
                                <IconSmallArrowRight size={30} />
                              </button>
                            </div>
                            <AdminPanelButtonsOuterCategory
                              setDeletingOuterCategoryStatus={setDeletingOuterCategoryStatus}
                              showAskFullDeletionForm={showAskFullDeletionForm}
                              setFormChangeOuterCategory={setFormChangeOuterCategory}
                              setCurrentOuterCategory={setCurrentOuterCategory}
                              outerCategory={outerCategoryObj.outerCategory}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <nav>
          {categoriesList.map((outerCategoryObj) =>
            outerCategoryObj.outerCategory === 'noOuterCategory' ? (
              <NoOuterCategoriesContainer
                setIsHoverCatalog={setIsHoverCatalog}
                setCatalogButtonStyle={setCatalogButtonStyle}
                hideDropDownMenu={hideDropDownMenu}
                showAddSubCategoryForm={showAddSubCategoryForm}
                showAskFullDeletionForm={showAskFullDeletionForm}
                showChangeCategorySectionForm={showChangeCategorySectionForm}
                setCategoryTarget={setCategoryTarget}
                setCurrentOuterCategory={setCurrentOuterCategory}
                key={outerCategoryObj.outerCategory}
              />
            ) : (
              <OuterCategoryContainer
                setCatalogButtonStyle={setCatalogButtonStyle}
                setIsHoverCatalog={setIsHoverCatalog}
                outerCategoryObj={outerCategoryObj}
                showDropDownPanel={showDropDownPanel}
                setCategoriesListDropDownPanel={setCategoriesListDropDownPanel}
                setCurrentOuterCategory={setCurrentOuterCategory}
                key={outerCategoryObj.outerCategory}
              />
            ),
          )}
        </nav>
      )}

      {Role === 'ADMIN' && (
        <>
          <button className={classes.addCategoryButton} onClick={showFormAddOuterCategory}>
            Add Outer Category
          </button>
          <button className={classes.addCategoryButton} onClick={showFormAddCategory}>
            Add Category
          </button>
          <button
            className={classes.createSiteMapButton}
            onClick={() => {
              createSitemapRequest()
                .then((res) => {
                  if (res.status === 200) setSiteMap(true);
                })
                .catch((error) => console.log('Error catched createSitemapRequest', error));
            }}
          >
            Create Sitemap
          </button>

          <button
            className={classes.synhronizeGoodsButton}
            onClick={() => {
              synhronizeGoods(acessToken);
            }}
          >
            Synhronize goods
          </button>
        </>
      )}
    </div>
  );
}
