import React from 'react';

import classes from './Alert.module.scss';

export function Alert(props) {
  let { variant } = props;
  return (
    <div className={classes[variant]}>
      <span>{props.children}</span>
    </div>
  );
}
