import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { NoOuterCategorySmallContainer } from './NoOuterCategorySmallContainer/NoOuterCategorySmallContainer';
import { IconSmallArrowRight } from '../../../Icons/IconSmallArrowRight';
import { IconGrid } from '../../../Icons/IconGrid';
import { IconLeftArrow } from '../../../Icons/IconLeftArrow';

import classes from './SmallOuterCategoriesDropDown.module.scss';

export function SmallOuterCategoriesDropDown({
  showSmallCatalogDropDown,
  hideSmallDropDownContainer,
  setOuterCatalogCategories,
  setOuterCategorySmallContainer,
  showMainSmallDropDown,
  showSubSmallDropDown,
  setCategoryForSubCategory,
  setCurrentOuterCategory,
  makeMainContentScrollable,
}) {
  const { categoriesList } = useSelector((state) => state.GoodsReducer);
  const { t } = useTranslation();

  const [noOuterCategory, setNoOuterCategory] = useState();

  useEffect(() => {
    const tempNoOuterCategory = categoriesList.find(
      (outerCategory) => outerCategory.outerCategory === 'noOuterCategory',
    );
    tempNoOuterCategory && setNoOuterCategory(tempNoOuterCategory);
  }, [categoriesList]);

  return (
    <nav className={classes.SmallOuterCategoriesDropDownContainer}>
      <div className={classes.back_to_catalog_container}>
        <button
          type='button'
          onClick={() => {
            setOuterCategorySmallContainer(false);
            showMainSmallDropDown();
          }}
          className={classes.back_to_catalog_button}
        >
          <IconLeftArrow size={30} color='black' />
        </button>
        <div className={classes.catalog_text_container}>
          <h1 className={classes.catalog_word}>{t('static:catalog')}</h1>
          <IconGrid size={30} />
        </div>
      </div>
      {noOuterCategory && (
        <NoOuterCategorySmallContainer
          categoriesInsideOuter={noOuterCategory.categories}
          showSubSmallDropDown={showSubSmallDropDown}
          setCategoryForSubCategory={setCategoryForSubCategory}
          setOuterCategorySmallContainer={setOuterCategorySmallContainer}
          hideSmallDropDownContainer={hideSmallDropDownContainer}
          makeMainContentScrollable={makeMainContentScrollable}
        />
      )}
      {categoriesList.map(
        (outerCategoryObj) =>
          outerCategoryObj.outerCategory !== 'noOuterCategory' && (
            <div
              itemtype='https://schema.org/Product'
              className={classes.outerCategoryContainer}
              key={outerCategoryObj.outerCategory}
            >
              <Link
                to={`outercategory/${outerCategoryObj.outerCategory}`}
                className={classes.outerCategory_link}
                itemProp='url'
                onClick={() => {
                  hideSmallDropDownContainer();
                  makeMainContentScrollable();
                }}
              >
                <span itemProp='name'>{t(`outerCategory:${outerCategoryObj.outerCategory}:title`)}</span>
              </Link>

              <button
                type='button'
                onClick={() => {
                  showSmallCatalogDropDown();
                  setOuterCategorySmallContainer(false);
                  setOuterCatalogCategories(outerCategoryObj.categories);
                  setCurrentOuterCategory(outerCategoryObj.outerCategory);
                }}
                className={classes.right_arrow_button}
              >
                <IconSmallArrowRight color='black' size={30} />
              </button>
            </div>
          ),
      )}
    </nav>
  );
}
