// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.B7P0wTlTHV2ZPJ8eH8MZ{margin:auto}`, "",{"version":3,"sources":["webpack://./src/components/OverlayTrigger/OverlayTrigger.module.scss"],"names":[],"mappings":"AAAA,sBACI,WAAA","sourcesContent":[".outerContainer {\n    margin: auto;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerContainer": `B7P0wTlTHV2ZPJ8eH8MZ`
};
export default ___CSS_LOADER_EXPORT___;
