import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useWindowDimensions } from '../../helpers/useWindowDimension';
import { ShoppingCardsContainer } from './ShoppingCardsContainer/ShoppingCardsContainer';
import { IconBackArrow } from '../Icons/IconBackArrow';
import { IconCloseButton } from '../Icons/IconCloseButton';

import classes from './ShoppingCardPreview.module.scss';

export function ShoppingCardPreview({
  setShoppingCardPreview,
  setStateToRerenderBuyButton,
  makeMainContentScrollable,
}) {
  const { t } = useTranslation();

  const [totalPrice, setTotalPrice] = useState(0);
  const [isPossibleToMakeOrder, setIsPossibleToMakeOrder] = useState();
  const { width } = useWindowDimensions();

  return (
    <div
      className={classes.shoppingCardGeneral}
      onClick={(e) => {
        if (e.target !== e.currentTarget) return;
        setShoppingCardPreview(() => false);
        makeMainContentScrollable();
      }}
    >
      <div className={classes.shoppingCardPreview}>
        <button
          className={classes.closeButton}
          onClick={() => {
            setShoppingCardPreview(false);
            makeMainContentScrollable();
          }}
        >
          <IconCloseButton size={20} />
        </button>

        <div className={classes.shoppingCardPreviewTitles}>
          <p className={classes.name}> {t('static:shoppingCard')}</p>
        </div>

        <ShoppingCardsContainer
          setTotalPrice={setTotalPrice}
          setIsPossibleToMakeOrder={setIsPossibleToMakeOrder}
          orderStatus='UNCONFIRMED'
          customStyleShoppingCard='shoppingCardGeneral'
          setStateToRerenderBuyButton={setStateToRerenderBuyButton}
          setShoppingCardPreview={setShoppingCardPreview}
          makeMainContentScrollable={makeMainContentScrollable}
        />

        <div className={classes.totalSum}>
          <span>
            {t('static:totalSum')} {totalPrice} {t('static:uah')}
          </span>
        </div>

        <div className={classes.ShoppingCardPreviewBottom}>
          <button
            type='button'
            className={classes.goBackButton}
            onClick={() => {
              setShoppingCardPreview(false);
              makeMainContentScrollable();
            }}
          >
            <IconBackArrow size={20} />
            {width > 480 && t('static:backToShopping')}
          </button>
          {isPossibleToMakeOrder ? (
            <Link to='/makeorderpage'>
              <button
                type='button'
                className={classes.makeOrderButton}
                onClick={() => {
                  setShoppingCardPreview(false);
                  makeMainContentScrollable();
                }}
              >
                {t('static:order')}
              </button>
            </Link>
          ) : (
            <button className={classes.makeOrderButtonDisabled} disabled>
              {t('static:order')}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
