export function removeGoodShoppingCardLocal(goodId) {
  let shoppingCard = JSON.parse(localStorage.getItem('shoppingCard'));

  if (!shoppingCard) return;

  let newShoppingCard = shoppingCard.filter((good) => {
    if (good.goodId === goodId) return false;
    return true;
  });

  if (!newShoppingCard.length) {
    localStorage.removeItem('shoppingCard');
    return;
  }

  localStorage.setItem('shoppingCard', JSON.stringify(newShoppingCard));
}
