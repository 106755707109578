import {
  SET_CATEGORIES_LIST,
  GET_CATEGORIES_LIST,
  GET_GOODS_ARRAYS,
  SET_GOODS_ARRAYS_STORE,
} from './GoodsAT';

export const setCategoriesListAC = (payload) => ({
  type: SET_CATEGORIES_LIST,
  payload,
});

export const getGoodsArraysAC = (payload) => ({
  type: GET_GOODS_ARRAYS,
  payload,
});

export const setGoodsArraysStoreAC = (payload) => ({
  type: SET_GOODS_ARRAYS_STORE,
  payload,
});

export const getCategoriesListAC = (payload) => ({
  type: GET_CATEGORIES_LIST,
  payload,
});
