import { axiosPrivate } from '../../api/axiosPrivate';

export async function getAllActiveShoppingCards(token) {
  return axiosPrivate.post(
    '/api/getallactiveshoppingcards',
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}
