import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getShoppingCardById } from '../../../services/ShoppingCard/getShoppingCardById';
import classes from './AddingToShoppingCardMod.module.scss';

export function AddingToShoppingCardMod() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { idToAddGoods, allActiveShoppingCards } = useSelector((state) => state.ShoppingCardsReducer);

  const { Role, shoppingCards, acessToken } = useSelector((state) => state.UserReducer);
  const [shoppingCard, setShoppingCard] = useState();

  useEffect(() => {
    let shoppingCard;
    if (Role === 'ADMIN') {
      shoppingCard =
        allActiveShoppingCards &&
        [...allActiveShoppingCards, ...shoppingCards].find(
          (shoppingCard) => shoppingCard._id === idToAddGoods,
        );
    } else if (acessToken) {
      shoppingCard = shoppingCards && shoppingCards.find((shoppingCard) => shoppingCard._id === idToAddGoods);
    } else {
      const orders = JSON.parse(localStorage.getItem('orders'));
      const shoppingCardId = orders && orders.find((order) => order === idToAddGoods);
      (async function () {
        const shoppingCard = await getShoppingCardById({ shoppingCardId });
        setShoppingCard(shoppingCard);
      })();
      return;
    }
    setShoppingCard(shoppingCard);
  }, [Role, allActiveShoppingCards, idToAddGoods, shoppingCards, acessToken]);

  return (
    <div
      className={classes.addToShoppingCardContainer}
      onClick={() => {
        acessToken ? navigate('/userpage') : navigate('/unregistereduserpage');
      }}
    >
      <span>{t('static:addGoodToOrder')} </span>
      <span className={classes.orderId}> {shoppingCard && shoppingCard.shoppingCardId} </span>
    </div>
  );
}
