import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Alert } from '../../Alert/Alert';
import { setIsErrorAC, setErrorObjAC } from '../../../store/Error/ErrorAC';
import { sendPasswordRecoveryLink } from '../../../services/Auth/passwordRecovery/sendPasswordRecoveryLink';
import classes from './ForgotPassword.module.scss';

export function ForgotPassword({ setAuthTableState, setForgetPasswordPage }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isError, errorObj } = useSelector((state) => state.ErrorReducer);

  const [success, setSuccess] = useState('');
  const { errorMessage } = errorObj;

  const emailRef = useRef();

  useEffect(
    () => () => {
      dispatch(setIsErrorAC(false));
      dispatch(setErrorObjAC({ errorMessage: '' }));
    },
    [],
  );

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      const email = emailRef && emailRef.current ? emailRef.current.value : null;
      if (!email) {
        dispatch(setIsErrorAC(true));
        dispatch(setErrorObjAC({ errorMessage: 'No email was provided' }));
      }
      const passwordRecoveryResponse = await sendPasswordRecoveryLink(email).catch((err) => {
        const responceErrorMessage = err.response?.data?.message
          ? err.response.data.message
          : 'uknownErrorPleaseReloadThePage';
        dispatch(setIsErrorAC(true));
        dispatch(setErrorObjAC({ errorMessage: responceErrorMessage }));
      });
      if (!passwordRecoveryResponse) return;
      passwordRecoveryResponse && setSuccess(passwordRecoveryResponse.data);
    } catch (error) {
      console.log(error, 'Catched error in handleSubmit ForgotPassword');
    }
  }

  return (
    <div
      className={classes.container}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {isError && <Alert variant='danger_small'> {t(`static:${errorMessage}`)} </Alert>}
      {success && <Alert variant='success_small'>{t(`static:${success}`)}</Alert>}
      <div className={classes.cardbody}>
        <form
          className={classes.form}
          onSubmit={async (event) => {
            handleSubmit(event);
          }}
        >
          <label>{t('static:EnterYourEmail')}</label>
          <input
            type='email'
            onChange={() => {
              dispatch(setIsErrorAC(false));
              dispatch(setErrorObjAC({ errorMessage: '' }));
            }}
            ref={emailRef}
            required
          />
          <div className={classes.buttons_container}>
            <button className={classes.submit_button} type='submit'>
              {t('static:confirm')}
            </button>
            <button
              onClick={() => {
                setForgetPasswordPage(false);
              }}
              className={classes.cancel_button}
            >
              {t('static:cancell')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
