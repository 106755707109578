import React from 'react';
import { SearchGoodCard } from '../SearchGoodCard/SearchGoodCard';

export function SearchGoodCards({ goods, hideSmallSearchInput }) {
  return (
    <div>
      {goods &&
        goods.map((good) => <SearchGoodCard good={good} hideSmallSearchInput={hideSmallSearchInput} />)}
    </div>
  );
}
