import { put, call, takeEvery } from 'redux-saga/effects';
import { GET_USER_DETAILS_REQUEST, LOGIN } from './UserAT';
import {
  addAccessToken,
  GetUserDetailsRequest,
  SetNameAC,
  SetEmailAC,
  SetPhoneAC,
  SetUserIdAC,
  setRoleStateAC,
  SetUserShoppingCardsAC,
  setIsLogged,
} from './UserAC';
import { setIsErrorAC, setErrorObjAC } from '../Error/ErrorAC';
import { loginRequest } from '../../services/Auth/login/loginRequest';
import { fetchUserDetails } from '../../services/fetchUserDetails';

export function* loginWorker({ payload }) {
  try {
    const token = yield call(loginRequest, [payload.username, payload.email, payload.password]);
    if (token) {
      yield put(addAccessToken(token.data.accessToken));
      yield put(GetUserDetailsRequest(token.data.accessToken));
      localStorage.setItem('isLogged', true);
    }
  } catch (error) {
    const errorMessage = error?.response?.data?.message
      ? error.response?.data?.message
      : 'uknownErrorPleaseReloadThePage';
    yield put(setIsErrorAC(true));
    yield put(
      setErrorObjAC({
        errorMessage,
        errorCode: error?.response?.status || 'Uknown Error',
        errorDescription: error?.message || 'Uknown Error',
      }),
    );
    console.log('error in loginWorker', error);
  }
}

export function* getUserDetailsWorker({ payload }) {
  try {
    const userDetails = yield call(fetchUserDetails, [payload]);

    if (userDetails.name === 'AxiosError') {
      throw new Error('AxiosError thrown, no name found');
    }

    yield put(SetNameAC(userDetails.username));
    yield put(SetUserShoppingCardsAC(userDetails.shoppingCards));
    userDetails._id ? yield put(SetUserIdAC(userDetails._id)) : yield put(SetUserIdAC(''));
    userDetails.email ? yield put(SetEmailAC(userDetails.email)) : yield put(SetEmailAC(''));
    userDetails.phone ? yield put(SetPhoneAC(userDetails.phone)) : yield put(SetPhoneAC(null));
    yield userDetails && put(setIsLogged(true));
    userDetails.roles ? yield put(setRoleStateAC(userDetails.roles[0])) : yield put(setRoleStateAC(''));
  } catch (error) {
    console.log(error, 'error inside getUserDetailsWorker');
  }
}

export function* loginWatcher() {
  yield takeEvery(LOGIN, loginWorker);
  yield takeEvery(GET_USER_DETAILS_REQUEST, getUserDetailsWorker);
}
