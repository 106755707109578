export function changeGoodQuantityInShoppingCard(goodId, newQuantity, functionForRerender) {
  let shoppingCards = localStorage.getItem('shoppingCard');
  let shoppingCardsParsed = JSON.parse(shoppingCards);
  let newShoppingCards =
    shoppingCardsParsed &&
    shoppingCardsParsed.map((shoppingCardObj) => {
      if (shoppingCardObj.goodId === goodId) {
        shoppingCardObj.quantity = newQuantity;
        return shoppingCardObj;
      }
      return shoppingCardObj;
    });
  localStorage.setItem('shoppingCard', JSON.stringify(newShoppingCards));
  functionForRerender((prev) => !prev);
}
