import axios from 'axios';
import { fetchAccessToken } from '../services/fetchAccessToken';
import { addAccessToken } from '../store/User/UserAC';
import { store } from '../store/store';

export const axiosPrivate = axios.create({
  headers: { 'Content-Type': 'application/json' },
});

axiosPrivate.interceptors.response.use(
  (response) => response,

  async (error) => {
    const prevRequest = error?.config;
    if ((error?.response?.status === 401 || error?.response?.status === 403) && !prevRequest?.sent) {
      prevRequest.sent = true;
      const newAcessTokenResponse = await fetchAccessToken();
      const newAccessToken = newAcessTokenResponse.data;
      store.dispatch(addAccessToken(newAccessToken));
      prevRequest.headers.Authorization = `Bearer ${newAccessToken}`;
      // and repeat request;
      return axiosPrivate(prevRequest);
    }
    return Promise.reject(error);
  },
);
