import { axiosPrivate } from '../../api/axiosPrivate';

export async function changeGoodSubcategory({ goodId, subcategory, token }) {
  return axiosPrivate.post(
    '/api/changegoodsubcategory',
    {
      goodId,
      subcategory,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}
