// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gy4YRFy1HXi3x6gLvoKJ{height:100%;width:300px;overflow-y:auto;z-index:1000;background-color:#814242}`, "",{"version":3,"sources":["webpack://./src/components/NavBarPanel/SmallDropDownContainer/SmallDropDownContainer.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,WAAA,CACA,eAAA,CACA,YAAA,CACA,wBAAA","sourcesContent":[".small_dropdown_container {\n  height: 100%;\n  width: 300px;\n  overflow-y: auto;\n  z-index: 1000;\n  background-color: rgb(129, 66, 66);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"small_dropdown_container": `gy4YRFy1HXi3x6gLvoKJ`
};
export default ___CSS_LOADER_EXPORT___;
