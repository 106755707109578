import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { getTranslations } from './services/Translations/getTranslations';
import STATIC_EN from './translations/en/static.json';
import STATIC_UA from './translations/ua/static.json';

const currentLanguage = localStorage.getItem('language') || 'ua';

export async function getTranslationsByKey(language, translationKeys) {
  // if tranlationKeys in arguments only these translation keys will be returned;
  try {
    const translations = await getTranslations(language, translationKeys).catch((error) => {
      console.log('Error, translations were not loaded', error);
    });
    if (!translations) {
      throw Error('No translations loaded');
    }

    if (translationKeys && translationKeys.length) {
      const translationObjByKeys = {};
      translationKeys.forEach((translationKey) => {
        translationObjByKeys[translationKey] = translations.data[translationKey]
          ? JSON.parse(translations.data[translationKey])
          : '';
      });
      return translationObjByKeys;
    }

    if (language && language === 'ua') {
      const { outerCategoriesUkr, categoriesUkr, catalogsUkr, subCategoriesUkr, goodsUkr } =
        translations.data;
      return {
        outerCategoriesUkr: outerCategoriesUkr && JSON.parse(outerCategoriesUkr),
        categoriesUkr: categoriesUkr && JSON.parse(categoriesUkr),
        catalogsUkr: catalogsUkr && JSON.parse(catalogsUkr),
        subCategoriesUkr: subCategoriesUkr && JSON.parse(subCategoriesUkr),
        goodsUkr: goodsUkr && JSON.parse(goodsUkr),
      };
    }

    if (language && language === 'en') {
      const { outerCategoriesEng, categoriesEng, catalogsEng, subCategoriesEng, goodsEng } =
        translations.data;
      return {
        outerCategoriesEng: outerCategoriesEng && JSON.parse(outerCategoriesEng),
        categoriesEng: categoriesEng && JSON.parse(categoriesEng),
        catalogsEng: catalogsEng && JSON.parse(catalogsEng),
        subCategoriesEng: subCategoriesEng && JSON.parse(subCategoriesEng),
        goodsEng: goodsEng && JSON.parse(goodsEng),
      };
    }
  } catch (error) {
    console.log('Error catched by getAllTranslations', error);
  }
}

const isObjectEmpty = (objectName) =>
  objectName && Object.keys(objectName).length === 0 && objectName.constructor === Object;

const { outerCategoriesUkr, categoriesUkr, catalogsUkr, subCategoriesUkr, goodsUkr } =
  currentLanguage === 'ua'
    ? await getTranslationsByKey('ua')
    : {
        outerCategoriesUkr: '',
        categoriesUkr: '',
        catalogsUkr: '',
        subCategoriesUkr: '',
        goodsUkr: await getTranslationsByKey('', ['goodsUkr']),
      };

const { outerCategoriesEng, categoriesEng, catalogsEng, subCategoriesEng, goodsEng } =
  currentLanguage === 'en'
    ? await getTranslationsByKey('en')
    : {
        outerCategoriesEng: '',
        categoriesEng: '',
        catalogsEng: '',
        subCategoriesEng: '',
        goodsEng: '',
      };

i18n.use(initReactI18next).init({
  fallbackLng: currentLanguage,
  returnEmptyString: false,
  resources: {
    en: {
      static: STATIC_EN,
      outerCategory: outerCategoriesEng,
      category: categoriesEng,
      good_translation: isObjectEmpty(goodsEng) ? goodsUkr.goodsUkr : goodsEng,
      catalogs: catalogsEng,
      subcategories: subCategoriesEng,
    },

    ua: {
      static: STATIC_UA,
      outerCategory: outerCategoriesUkr,
      category: categoriesUkr,
      good_translation: goodsUkr,
      catalogs: catalogsUkr,
      subcategories: subCategoriesUkr,
    },
  },
});

export default i18n;
