import { axiosPrivate } from '../api/axiosPrivate';

export async function fetchUserDetails(token) {
  return axiosPrivate
    .post(
      '/auth/currentuser',
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((user) => user.data)
    .catch((error) => {
      localStorage.removeItem('isLogged');
      return error;
    });
}
