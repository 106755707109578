import { axiosPrivate } from '../../api/axiosPrivate';

export async function getAllInactiveShoppingCards({ skipCount, acessToken }) {
  return axiosPrivate.post(
    '/api/getallinactiveshoppingcards',
    { skipCount },
    {
      headers: {
        Authorization: `Bearer ${acessToken}`,
      },
    },
  );
}
