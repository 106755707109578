import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getShoppingCardById } from '../services/ShoppingCard/getShoppingCardById';

export function useBuyButtonState(goodId, buttonRerender) {
  const { allActiveShoppingCards, idToAddGoods } = useSelector((state) => state.ShoppingCardsReducer);
  const { shoppingCards } = useSelector((state) => state.UserReducer);

  const [shoppingCardGoods, setShoppingCardGoods] = useState([]);
  const [currentOrderGoods, setCurrentOrderGoods] = useState([]);
  const [isGoodInShoppingCard, setIsGoodInShoppingCard] = useState(false);
  const [isGoodInOrder, setIsGoodInOrder] = useState(false);
  const [isButtonActive, setButtonActive] = useState(true);
  const [buyButtonName, setBuyButtonName] = useState('Buy');

  useEffect(() => {
    const shoppingCard = localStorage.getItem('shoppingCard');
    setShoppingCardGoods(JSON.parse(shoppingCard));
  }, [goodId, buttonRerender]);

  useEffect(() => {
    const isGoodInShoppingCard =
      shoppingCardGoods && shoppingCardGoods.find((good) => good.goodId === goodId);
    setIsGoodInShoppingCard(!!isGoodInShoppingCard);
  }, [shoppingCardGoods, goodId, buttonRerender]);

  useEffect(() => {
    allActiveShoppingCards &&
      [...shoppingCards, ...allActiveShoppingCards].forEach((shoppingCard) => {
        if (shoppingCard._id === idToAddGoods) {
          setCurrentOrderGoods(shoppingCard.goods);
        }
      });
  }, [allActiveShoppingCards, idToAddGoods, shoppingCards]);

  useEffect(() => {
    currentOrderGoods &&
      currentOrderGoods.forEach((good) => {
        if (good._id === goodId) setIsGoodInOrder(true);
      });
  }, [currentOrderGoods, goodId]);

  useEffect(() => {
    // FUNCTIONALITY FOR UNREGISTERED USER GOODS ADDING TO ORDER
    const orders = JSON.parse(localStorage.getItem('orders'));
    if (!orders) return;
    const shoppingCard = orders.find((order) => order.orderId === idToAddGoods);
    const shoppingCardId = shoppingCard ? shoppingCard.orderId : null;

    shoppingCardId &&
      (async function () {
        const shoppingCard = await getShoppingCardById({ shoppingCardId });
        shoppingCard && setCurrentOrderGoods(shoppingCard.goods);
      })();
  }, [idToAddGoods, goodId, buttonRerender]);

  useEffect(() => {
    currentOrderGoods.includes(goodId) && setIsGoodInOrder(true);
  }, [currentOrderGoods, buttonRerender, goodId]);

  useEffect(() => {
    setButtonActive(true);
    if (idToAddGoods) {
      if (isGoodInOrder) setButtonActive(false);
      isGoodInOrder ? setBuyButtonName('goodInOrder') : setBuyButtonName('addToOrder');
    }

    if (!idToAddGoods) {
      if (isGoodInShoppingCard) setButtonActive(false);
      isGoodInShoppingCard ? setBuyButtonName('goodInShoppingCard') : setBuyButtonName('Buy');
    }
  }, [isGoodInShoppingCard, idToAddGoods, isGoodInOrder, buttonRerender]);

  return [buyButtonName, isButtonActive];
}
