import React from 'react';
import { v4 as uuidv4 } from 'uuid';

export function IconFacebook() {
  const uniqueId = uuidv4();

  return (
    <svg
      id={uniqueId}
      viewBox='0 0 512.001 512.001'
      xmlSpace='preserve'
      style={{ width: '30px', height: '30px' }}
    >
      <title>Facebook</title>
      <circle style={{ fill: '#1D81CE' }} cx='256.001' cy='256' r='247.916' />
      <path
        style={{ fill: '#126DB1' }}
        d='M256.001,8.084c-10.96,0-21.752,0.72-32.337,2.099C345.304,26.029,439.243,130.04,439.243,256
s-93.939,229.971-215.579,245.817c10.585,1.379,21.377,2.099,32.337,2.099c136.921,0,247.916-110.996,247.916-247.916
S392.921,8.084,256.001,8.084z'
      />
      <path
        style={{ fill: '#FCFCFC' }}
        d='M169.769,256.089v10.785H94.316v75.501h75.453v146.358c23.658,8.784,49.019,14.05,75.453,15.182
v-161.54H363.79v-75.501H245.222v-10.785c0-41.698,33.781-64.715,75.453-64.715h43.116v-75.501h-43.116
C243.285,115.874,169.769,178.65,169.769,256.089z'
      />
      <path
        d='M437.02,74.981C388.668,26.628,324.38,0,256.001,0C188.021,0,123.05,26.912,74.981,74.981
C26.629,123.333,0.001,187.62,0.001,256c0,53.306,16.227,104.399,46.926,147.757c30.02,42.398,71.526,74.321,120.03,92.318
c3.258,1.209,7.025,0.223,9.267-2.703c1.111-1.452,1.63-3.274,1.63-5.102V342.232c0-4.465-3.62-8.084-8.084-8.084h-67.368v-59.284
h67.368c4.465,0,8.084-3.62,8.084-8.084V256c0-70.34,66.739-132.042,142.821-132.042h35.032v59.284h-35.032
c-22.814,0-43.559,6.567-58.413,18.489c-16.436,13.192-25.124,31.958-25.124,54.269v10.779c0,4.465,3.62,8.084,8.084,8.084h31.872
c4.465,0,8.084-3.62,8.084-8.084l0,0c0-4.465-3.62-8.084-8.084-8.084h-23.788V256c0-41.768,36.291-56.589,67.368-56.589h43.116
c4.465,0,8.084-3.62,8.084-8.084v-75.453c0-4.465-3.62-8.084-8.084-8.084h-43.116c-39.518,0-79.573,15.72-109.895,43.129
c-31.659,28.619-49.095,65.937-49.095,105.082v2.695H94.316c-4.465,0-8.084,3.62-8.084,8.084v75.453c0,4.465,3.62,8.084,8.084,8.084
h67.368v126.248C74.182,439.076,16.169,351.887,16.169,256c0-132.244,107.588-239.832,239.83-239.832
c132.098,0,239.833,107.734,239.833,239.833c0,132.243-107.588,239.83-239.832,239.83c-0.885,0-1.782-0.005-2.695-0.017V350.316
H363.79c4.465,0,8.084-3.62,8.084-8.084v-75.453c0-4.465-3.62-8.084-8.084-8.084h-47.317c-4.465,0-8.084,3.62-8.084,8.084l0,0
c0,4.465,3.62,8.084,8.084,8.084h39.233v59.284H245.222c-4.465,0-8.084,3.62-8.084,8.084v161.437c0,4.325,3.408,7.891,7.729,8.077
c4.005,0.171,7.648,0.255,11.135,0.255c68.379,0,132.667-26.628,181.019-74.98c48.069-48.07,74.981-113.04,74.981-181.021
C512.001,187.62,485.372,123.333,437.02,74.981z'
      />
    </svg>
  );
}
