import { axiosPrivate } from '../../api/axiosPrivate';

export function removeGood(category, goodId, token) {
  return axiosPrivate.post(
    '/api/removegood',
    { category, goodId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}
