import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { IconLeftArrow } from '../../Icons/IconLeftArrow';
import { IconGrid } from '../../Icons/IconGrid';
import { IconSmallArrowRight } from '../../Icons/IconSmallArrowRight';

import classes from './SmallCatalogsDropDown.module.scss';

export function SmallCatalogsDropDown({
  outerCatalogCategories,
  hideSmallDropDownContainer,
  makeMainContentScrollable,
  setOuterCategorySmallContainer,
  hideSmallCatalogDropDown,
  setCategoryForSubCategory,
  showSubSmallDropDown,
  currentOuterCategory,
}) {
  const { t } = useTranslation();
  const { catalogs } = useSelector((state) => state.CatalogReducer);
  const { categoriesList } = useSelector((state) => state.GoodsReducer);
  const [categoriesForDropdown, setCategoriesForDropDown] = useState([]);

  useEffect(() => {
    const categoriesForDropdown = catalogs
      .map((catalog) =>
        outerCatalogCategories.includes(catalog.category)
          ? catalog.subCategories.length
            ? { category: catalog.category, subCategories: true }
            : { category: catalog.category, subCategories: false }
          : null,
      )
      .filter((item) => item !== null);

    setCategoriesForDropDown(categoriesForDropdown);
  }, [catalogs, categoriesList, outerCatalogCategories]);

  return (
    <nav className={classes.small_catalogs_dropdown}>
      <div className={classes.back_to_catalog_container}>
        <button
          type='button'
          onClick={() => {
            setOuterCategorySmallContainer(true);
            hideSmallCatalogDropDown();
          }}
          className={classes.back_to_catalog_button}
        >
          <IconLeftArrow size={30} color='black' />
        </button>
        <div className={classes.catalog_text_container}>
          <h1 itemProp='title' className={classes.catalog_text}>
            {t(`outerCategory:${currentOuterCategory}:title`)}
          </h1>
          <IconGrid size={30} />
        </div>
      </div>
      {categoriesForDropdown.map((categoryObj) => (
        <div
          itemtype='https://schema.org/Product'
          className={classes.category_container}
          key={categoryObj.category}
        >
          <Link
            to={
              categoryObj.subCategories === false
                ? `outercategory/${currentOuterCategory}/${categoryObj.category}/allgoods`
                : `outercategory/${currentOuterCategory}/${categoryObj.category}`
            }
            className={classes.subcategory_link}
            itemProp='url'
            onClick={() => {
              hideSmallDropDownContainer((prev) => !prev);
              makeMainContentScrollable();
            }}
          >
            <span itemProp='name'>{t(`category:${categoryObj.category}`)}</span>
          </Link>
          {categoryObj.subCategories && (
            <button
              type='button'
              onClick={() => {
                setCategoryForSubCategory(categoryObj.category);
                hideSmallCatalogDropDown(false);
                showSubSmallDropDown(true);
              }}
              className={classes.right_arrow_button}
            >
              <IconSmallArrowRight size={30} />
            </button>
          )}
        </div>
      ))}
    </nav>
  );
}
