import {
  GET_USERS_SHOPPINGCARDS,
  FETCH_USERS_SHOPPINGCARDS,
  SET_USER_SHOPPINGCARD_STORE,
  GET_ALL_ACTIVE_SHOPPINGCARDS,
  GET_ALL_INACTIVE_SHOPPINGCARDS,
  SET_ALL_ACTIVE_SHOPPINGCARDS_STORE,
  SET_ALL_INACTIVE_SHOPPINGCARDS_STORE,
  GET_SHOPPINGCARD_BY_ID,
  SET_SHOPPINGCARD_BY_ID,
  SET_SHOPPINGCARD_STATUS,
  SET_SHOPPINGCARD_QUANTITY,
  SET_ID_TO_ADD_GOODS,
} from './ShoppingCardsAT';

export const setIdToAddGoods = (payload) => ({
  type: SET_ID_TO_ADD_GOODS,
  payload,
});

export const getUserShoppingCards = (payload) => ({
  type: GET_USERS_SHOPPINGCARDS,
  payload,
});

export const getAllActiveShoppingCardsAC = (payload) => {
  return {
    type: GET_ALL_ACTIVE_SHOPPINGCARDS,
    payload,
  };
};

export const getAllInactiveShoppingCardsAC = (payload) => {
  return {
    type: GET_ALL_INACTIVE_SHOPPINGCARDS,
    payload,
  };
};

export const getShoppingCardByIdAC = (payload) => {
  return {
    type: GET_SHOPPINGCARD_BY_ID,
    payload,
  };
};

export const setShoppingCardByIdAC = (payload) => {
  return {
    type: SET_SHOPPINGCARD_BY_ID,
    payload,
  };
};

export const setShoppingCardStatusAC = (payload) => {
  return {
    type: SET_SHOPPINGCARD_STATUS,
    payload,
  };
};

export const setShoppingCardQuantityAC = (payload) => {
  return {
    type: SET_SHOPPINGCARD_QUANTITY,
    payload,
  };
};

export const setAllActiveShoppingCardsStoreAC = (payload) => ({
  type: SET_ALL_ACTIVE_SHOPPINGCARDS_STORE,
  payload,
});

export const setAllInactiveShoppingCardsStoreAC = (payload) => ({
  type: SET_ALL_INACTIVE_SHOPPINGCARDS_STORE,
  payload,
});

export const fetchUsersShoppingCardsAC = (payload) => ({
  type: FETCH_USERS_SHOPPINGCARDS,
  payload,
});

export const setUserShoppingCardToStoreAC = (payload) => ({
  type: SET_USER_SHOPPINGCARD_STORE,
  payload,
});
