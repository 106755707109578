import React, { useState, useEffect } from 'react';
import { Blurhash } from 'react-blurhash';

import classes from './ImageComponent.module.scss';

export function ImageComponent({ src, alt, width, height, blurHash, customStyleHash, customStyleImg }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageLoaded(true);
    };
    img.src = src;
  }, [src]);

  return (
    <>
      <div className={classes[customStyleHash]} style={{ display: imageLoaded ? 'none' : 'inline' }}>
        <Blurhash hash={blurHash} width={width} height={height} resolutionX={32} resolutionY={32} punch={1} />
      </div>
      <img
        className={classes[customStyleImg]}
        itemProp={customStyleImg === 'logoImage' ? 'logo' : 'image'}
        src={src}
        style={{ display: !imageLoaded ? 'none' : 'inline' }}
        height={height}
        width={width}
        alt={alt || ''}
        fetchpriority='low'
      />
    </>
  );
}
