import { axiosPrivate } from '../../api/axiosPrivate';

export async function removeOuterCategory({ outerCategory, token }) {
  return axiosPrivate.post(
    '/api/removeoutercategory',
    {
      outerCategory,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}
