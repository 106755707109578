import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Carusel } from '../Carusel/Carusel';
import FormToAddMainImage from './FormToAddMainImage';
import { ImageComponent } from '../ImageComponent/ImageComponent';
import { blurMainImages } from '../../services/MainPageImg/blurMainImages';
import { useWindowDimensions } from '../../helpers/useWindowDimension';

import classes from './ImageMainPage.module.scss';

export function MainPageImage() {
  const { width } = useWindowDimensions();

  const { mainPageImage } = useSelector((state) => state.AssetsReducer);
  const { Role, acessToken } = useSelector((state) => state.UserReducer);
  const [formToAddImageShown, setFormToAddImage] = useState(false);

  return (
    <div className={classes.mainPageImage}>
      {Role === 'ADMIN' && (
        <div className={classes.mainImageButtonsContainer}>
          <button
            variant='success'
            className={classes.addImageButton}
            onClick={() => {
              setFormToAddImage((prev) => !prev);
            }}
          >
            Add image
          </button>

          <button
            variant='info'
            className={classes.blurButton}
            onClick={async () => {
              await blurMainImages(acessToken);
            }}
          >
            Blur
          </button>
        </div>
      )}
      {formToAddImageShown && <FormToAddMainImage setFormToAddImage={setFormToAddImage} />}

      {!mainPageImage || !mainPageImage.length ? (
        <ImageComponent
          src=''
          alt='wooolama poster'
          width={`${width}px`}
          height={`${width / 2}px`}
          blurHash='U7HA-P?KKf.9^jR;9hVXKN?FL2pen3ngrVK+'
        />
      ) : (
        <Carusel images={mainPageImage} width={width} />
      )}
    </div>
  );
}
