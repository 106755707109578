import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export function useGetGoodLink(good, page) {
  const { categoriesList: outerCategoriesList } = useSelector((state) => state.GoodsReducer);

  const [outerCategory, setOuterCategory] = useState();
  const [link, setLink] = useState('');

  useEffect(() => {
    if (page !== 'search') return;

    outerCategoriesList.forEach((outerCategoryObj) => {
      if (outerCategoryObj.categories.includes(good.category)) {
        setOuterCategory(outerCategoryObj.outerCategory);
      }
    });
  }, [outerCategoriesList, good]);

  useEffect(() => {
    if (page !== 'search') return;

    if (outerCategory === 'noOuterCategory') {
      if (good.subcategory === 'allgoods') {
        setLink(`/goods/${good.category}/${good._id}`);
        return;
      }
      if (good.subcategory) {
        setLink(`/catalog/${good.category}/${good.subcategory}/${good._id}`);
      }
    }

    if (outerCategory) {
      if (good.subcategory === 'allgoods') {
        setLink(`/outercategory/${outerCategory}/${good.category}/allgoods/${good._id}`);
      }
      if (good.subcategory) {
        setLink(`/outercategory/${outerCategory}/${good.category}/${good.subcategory}/${good._id}`);
      }
    }
  }, [outerCategory, good]);

  return link;
}
