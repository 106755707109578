import { put, call, takeLatest } from 'redux-saga/effects';
import { getAllInactiveShoppingCards } from '../../services/ShoppingCard/getAllShoppingCards';
import { getAllActiveShoppingCards } from '../../services/ShoppingCard/getAllActiveShoppingCards';
import { GET_ALL_INACTIVE_SHOPPINGCARDS, GET_ALL_ACTIVE_SHOPPINGCARDS } from './ShoppingCardsAT';
import {
  setAllInactiveShoppingCardsStoreAC,
  setAllActiveShoppingCardsStoreAC,
} from './ShoppingCardsAC';

export function* getAllInactiveShoppingCardsWorker({ payload }) {
  try {
    const allInactiveShoppingCards = yield call(getAllInactiveShoppingCards, payload);
    yield put(setAllInactiveShoppingCardsStoreAC(allInactiveShoppingCards.data));
  } catch (error) {
    console.error(error, 'Error in getAllShoppingCardsWorker');
  }
}

export function* getAllActiveShoppingCardsWorker({ payload }) {
  try {
    const allActiveShoppingCards = yield call(getAllActiveShoppingCards, [payload]);
    yield put(setAllActiveShoppingCardsStoreAC(allActiveShoppingCards.data));
  } catch (error) {
    console.log('Error in getAllActiveShoppingCardsWorker', error);
  }
}

export function* shoppingCardsWatcher() {
  yield takeLatest(GET_ALL_INACTIVE_SHOPPINGCARDS, getAllInactiveShoppingCardsWorker);
  yield takeLatest(GET_ALL_ACTIVE_SHOPPINGCARDS, getAllActiveShoppingCardsWorker);
  // yield takeLatest(GET_SHOPPINGCARD_BY_ID, getShoppingCardById);
}
