// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Ebw87aGnOXFROmkPtThG{display:flex;justify-content:center;align-items:center;position:relative;border:2px solid #fff;border-radius:6px;margin-top:2px;padding:5px;cursor:pointer;background-color:#82bd7d;width:fit-content;height:50px;color:#f0f0f0;font-size:12px;font-weight:bold;text-align:center}.Ebw87aGnOXFROmkPtThG .gEZIRzTn89rNgBfWw3TH{margin-left:4px;text-decoration:underline}.Ebw87aGnOXFROmkPtThG:hover{background-color:#90cc8a}`, "",{"version":3,"sources":["webpack://./src/components/NavBarPanel/AddingToShoppingCardMod/AddingToShoppingCardMod.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,iBAAA,CACA,qBAAA,CACA,iBAAA,CACA,cAAA,CACA,WAAA,CACA,cAAA,CACA,wBAAA,CACA,iBAAA,CACA,WAAA,CACA,aAAA,CACA,cAAA,CACA,gBAAA,CACA,iBAAA,CAEA,4CACE,eAAA,CACA,yBAAA,CAIJ,4BACE,wBAAA","sourcesContent":[".addToShoppingCardContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n  border: 2px solid white;\n  border-radius: 6px;\n  margin-top: 2px;\n  padding: 5px;\n  cursor: pointer;\n  background-color: rgb(130, 189, 125);\n  width: fit-content;\n  height: 50px;\n  color: rgb(240, 240, 240);\n  font-size: 12px;\n  font-weight: bold;\n  text-align: center;\n\n  .orderId {\n    margin-left: 4px;\n    text-decoration: underline;\n  }\n}\n\n.addToShoppingCardContainer:hover {\n  background-color: rgb(144, 204, 138);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addToShoppingCardContainer": `Ebw87aGnOXFROmkPtThG`,
	"orderId": `gEZIRzTn89rNgBfWw3TH`
};
export default ___CSS_LOADER_EXPORT___;
