import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { OverlayTrigger } from '../../OverlayTrigger/OverlayTrigger';
import { ShoppingCardGood } from '../ShoppingCardGood/ShoppingCardGood';
import { removeGoodShoppingCardLocal } from '../../../helpers/LocalStorage/removeGoodShoppingCard';
import { removeGoodShoppingCard } from '../../../services/ShoppingCard/removeGoodShoppingCard';
import { GetUserDetailsRequest } from '../../../store/User/UserAC';
import { getAllActiveShoppingCardsAC, setIdToAddGoods } from '../../../store/ShoppingCards/ShoppingCardsAC';
import { removeOrderLocal } from '../../../helpers/LocalStorage/removeOrderLocal';
import { IconBasketPlus } from '../../Icons/IconBasketPlus';
import { IconBasketCancel } from '../../Icons/IconBasketCancel';

import classes from './ShoppingCardGoods.module.scss';

export function ShoppingCardGoods({
  rerenderComponent,
  setShoppingCardPreview,
  makeMainContentScrollable,
  goodsForShoppingCards,
  setPageRerender,
  handleChangeQuantity,
  shoppingCardId,
  orderStatus,
  isOrdered,
  customStyleShoppingCard,
  setIsPossibleToMakeOrder,
  stateToRerenderBuyButton,
  setStateToRerenderBuyButton,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { acessToken, Role } = useSelector((state) => state.UserReducer);
  const { idToAddGoods } = useSelector((state) => state.ShoppingCardsReducer);
  const [isButtonToAddGoodOn, setButtonToAddGoodOn] = useState(true);

  useEffect(() => {
    idToAddGoods && idToAddGoods === shoppingCardId
      ? setButtonToAddGoodOn(false)
      : setButtonToAddGoodOn(true);
  }, [idToAddGoods, shoppingCardId]);

  const renderTooltip = (props) => (
    <div id='button-tooltip' className={classes.changeShoppingCardToolTip} {...props}>
      {t('static:addGoodToOrder')}
    </div>
  );

  if (goodsForShoppingCards.length === 0) return null;

  async function handleDeleteGood(goodId, shoppingCardId) {
    const goodDeletingMessage =
      goodsForShoppingCards.length === 1
        ? t('static:lastGoodInOrderDoYouConfirmDeletion')
        : t('static:areYouShureToDeleteThisGood');

    // eslint-disable-next-line no-restricted-globals
    const isDeletionConfirmed = confirm(goodDeletingMessage);
    if (!isDeletionConfirmed) return;
    if (!isOrdered) {
      removeGoodShoppingCardLocal(goodId);
      setPageRerender((prev) => !prev);
      return;
    }

    if (isOrdered) {
      if (orderStatus === 'UNCONFIRMED') {
        await removeGoodShoppingCard(goodId, shoppingCardId)
          .then((shoppingCard) => {
            // remove dispatch and update state with new shoppingCard
            if (acessToken && Role === 'ADMIN') {
              dispatch(getAllActiveShoppingCardsAC(acessToken));
              dispatch(GetUserDetailsRequest(acessToken));
            } else if (acessToken) {
              dispatch(GetUserDetailsRequest(acessToken));
            } else {
              // localStorage
              if (!shoppingCard.data) {
                removeOrderLocal(shoppingCardId);
                rerenderComponent((prev) => !prev);
              }
              setPageRerender((prev) => !prev);
            }
          })
          .catch((err) => console.log(err, 'err in removeGoodShoppingCard'));
      }
    }
  }

  return (
    <div
      className={classes.shoppingCards}
      onClick={(e) => {
        if (e.target !== e.currentTarget) return;
        setShoppingCardPreview(() => false);
      }}
    >
      {goodsForShoppingCards &&
        goodsForShoppingCards.map((card) => (
          <ShoppingCardGood
            card={card}
            shoppingCardId={shoppingCardId}
            setShoppingCardPreview={setShoppingCardPreview}
            makeMainContentScrollable={makeMainContentScrollable}
            handleChangeQuantity={handleChangeQuantity}
            handleDeleteGood={handleDeleteGood}
            orderStatus={orderStatus}
            customStyleShoppingCard={customStyleShoppingCard}
            setIsPossibleToMakeOrder={setIsPossibleToMakeOrder}
            stateToRerenderBuyButton={stateToRerenderBuyButton}
            setStateToRerenderBuyButton={setStateToRerenderBuyButton}
          />
        ))}
      {orderStatus === 'UNCONFIRMED' &&
        customStyleShoppingCard !== 'shoppingCardMakeOrderPage' &&
        customStyleShoppingCard !== 'shoppingCardGeneral' &&
        (isButtonToAddGoodOn ? (
          <OverlayTrigger show={300} hide={400} overlay={renderTooltip}>
            <button
              type='button'
              className={classes.addGoodToShoppingCardButton}
              onClick={() => {
                dispatch(setIdToAddGoods(shoppingCardId));
              }}
            >
              <div className={classes.plusSign}>
                <IconBasketPlus size={50} />
              </div>
            </button>
          </OverlayTrigger>
        ) : (
          <button
            type='button'
            className={classes.stopAddingGoodsToOrderButton}
            onClick={() => {
              dispatch(setIdToAddGoods(''));
            }}
          >
            <div className={classes.stopSign}>
              <IconBasketCancel size={50} />
            </div>
          </button>
        ))}
    </div>
  );
}
