import React, { useState, useEffect, useRef } from 'react';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconTrashBucket } from '../../Icons/IconTrashBucket';

import placeholder from '../../../assets/placeholder.webp';

import classes from './ShoppingCardGood.module.scss';

export function ShoppingCardGood({
  card,
  shoppingCardId,
  orderStatus,
  setShoppingCardPreview,
  makeMainContentScrollable,
  handleChangeQuantity,
  handleDeleteGood,
  setIsPossibleToMakeOrder,
  customStyleShoppingCard,
  setStateToRerenderBuyButton,
}) {
  const navigate = useNavigate();
  const inputRef = useRef();
  const { t } = useTranslation();
  const [goodsPrice, setGoodsPrice] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [enoughGoods, setEnoughGoods] = useState();
  const [isChangeQuantityShown, showChangeQuantity] = useState(false);

  const {
    title,
    measurement,
    category,
    mainImage,
    price,
    discount,
    quantity,
    quantityToSell,
    _id: goodId,
  } = card;

  function isValueInRange(number) {
    return /^$|^0|^([0-9]+)$/.test(number);
  }

  useEffect(() => {
    if (discount && discount.hasDiscount && discount.amount) {
      price && setDiscountPrice(() => Math.floor(price - (price * discount.amount) / 100));
    }
  }, [discount, price]);

  useEffect(() => {
    const enoughGoods = !(quantity < quantityToSell);
    setIsPossibleToMakeOrder(enoughGoods);
    setEnoughGoods(enoughGoods);
  }, [quantity, quantityToSell]);

  useEffect(() => {
    if (orderStatus === 'UNCONFIRMED') {
      showChangeQuantity(true);
      return;
    }
    showChangeQuantity(false);
  }, [orderStatus]);

  useEffect(() => {
    if (discount.hasDiscount && discount.amount) {
      price && setGoodsPrice(() => Math.floor(price - (price * discount.amount) / 100) * quantityToSell);
      return;
    }
    setGoodsPrice(quantityToSell * price);
  }, [price, quantityToSell]);

  return (
    <div className={classes[customStyleShoppingCard]}>
      {isChangeQuantityShown && (
        <button
          type='button'
          className={classes.deleteSectionButton}
          onClick={async () => {
            await handleDeleteGood(goodId, shoppingCardId);
            setStateToRerenderBuyButton && setStateToRerenderBuyButton((prev) => !prev);
          }}
        >
          <IconTrashBucket size={20} />
        </button>
      )}
      <div className={classes.cardContainer}>
        {!enoughGoods && (
          <div className={classes.noGoodsMessage}>
            <span>{t('static:noGoods')}</span>
          </div>
        )}
        <div className={classes.imageAndDescription}>
          <div className={classes.imageContainer}>
            <img
              className={classes.cardImg}
              src={mainImage || placeholder}
              onClick={(e) => {
                setShoppingCardPreview(false);
                makeMainContentScrollable && makeMainContentScrollable();
                navigate(`/goods/${category}/${goodId}`);
                e.stopPropagation();
              }}
            />
          </div>

          <div className={classes.titleAndQuantityContainer}>
            <div className={classes.cardBody}>
              <div
                className={classes.title}
                onClick={(e) => {
                  setShoppingCardPreview(false);
                  makeMainContentScrollable && makeMainContentScrollable();
                  navigate(`/goods/${category}/${goodId}`);
                  e.stopPropagation();
                }}
              >
                {title}
              </div>

              <div className={classes.price}>
                {discountPrice || price} {t('static:uah')}
              </div>
            </div>

            {isChangeQuantityShown && (
              <div className={classes.changeQuantitiySection}>
                <div className={classes.inputChangeQuantity}>
                  <div className={classes.inputAndButtonsWrapper}>
                    <div className={classes.minusButtonWrapper}>
                      <button
                        className={classes.minusButton}
                        onClick={() => {
                          if (inputRef.current.value <= 1) return;
                          handleChangeQuantity({
                            goodId,
                            shoppingCardId,
                            inputValue: +inputRef.current.value,
                            sign: 'minus',
                          });
                        }}
                      >
                        −
                      </button>
                    </div>
                    <div className={classes.inputWrapper}>
                      <input
                        onChange={(e) => {
                          let { value } = e.target;
                          if (!isValueInRange(value)) return;
                          if (value) value = Number(value);

                          handleChangeQuantity({
                            goodId,
                            shoppingCardId,
                            inputValue: value,
                            sign: null,
                          });
                        }}
                        onBlur={(e) => {
                          if (e.target.value === '' || e.target.value === '0') {
                            handleChangeQuantity({
                              goodId,
                              shoppingCardId,
                              inputValue: 1,
                              sign: null,
                            });
                          }
                        }}
                        className={classes.input}
                        aria-label='add goods'
                        type='text'
                        value={quantityToSell}
                        ref={inputRef}
                      />
                    </div>
                    <div className={classes.plusButtonWrapper}>
                      <button
                        variant='outline-secondary'
                        className={classes.plusButton}
                        onClick={() => {
                          handleChangeQuantity({
                            goodId,
                            shoppingCardId,
                            inputValue: +inputRef.current.value,
                            sign: 'plus',
                          });
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>

                <div className={classes.measurement}>{measurement}</div>

                <div className={classes.priceSection}>
                  <div className={classes.priceWrapper}>
                    <span>{goodsPrice}</span>
                    <span className={classes.priceCurrency}>{t('static:uah')}</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {!isChangeQuantityShown && (
          <div className={classes.quantitySection}>
            <div className={classes.goodQuantity}>
              <span>{quantityToSell}</span>
            </div>
            <div className={classes.measurement}>{measurement}</div>
            <div className={classes.priceSection}>
              <span>{goodsPrice}</span>
              <span>{t('static:uah')}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
