// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vWXQgfHiH0bSp8VW8GB1 canvas{border-radius:50%}.oueuHwmWi52ZZcfAhUe3{display:flex;width:100%;margin-left:auto;margin-right:auto;object-fit:fill}.qx0zLEqEAmeGhmpHmblB{width:180px;height:140px}.XHdA9g3zSNo51eBPVMtM{width:400px;height:200px}`, "",{"version":3,"sources":["webpack://./src/components/ImageComponent/ImageComponent.module.scss"],"names":[],"mappings":"AACE,6BACE,iBAAA,CAIJ,sBACE,YAAA,CACA,UAAA,CACA,gBAAA,CACA,iBAAA,CACA,eAAA,CAGF,sBACE,WAAA,CACA,YAAA,CAGF,sBACE,WAAA,CACA,YAAA","sourcesContent":[".round {\n  canvas {\n    border-radius: 50%;\n  }\n}\n\n.mainPageCaruselImage {\n  display: flex;\n  width: 100%;\n  margin-left: auto;\n  margin-right: auto;\n  object-fit: fill;\n}\n\n.logoImage {\n  width: 180px;\n  height: 140px;\n}\n\n.bottomLogoImage {\n  width: 400px;\n  height: 200px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"round": `vWXQgfHiH0bSp8VW8GB1`,
	"mainPageCaruselImage": `oueuHwmWi52ZZcfAhUe3`,
	"logoImage": `qx0zLEqEAmeGhmpHmblB`,
	"bottomLogoImage": `XHdA9g3zSNo51eBPVMtM`
};
export default ___CSS_LOADER_EXPORT___;
